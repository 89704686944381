import React, { useState, useMemo } from 'react';
import 'boxicons/css/boxicons.min.css';

const Modal = ({ title, keywords = [], onClose }) => {
  // State for managing pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of keywords per page

  // Calculate total pages
  const totalPages = Math.ceil(keywords.length / itemsPerPage);

  // Get current keywords based on pagination
  const indexOfLastKeyword = currentPage * itemsPerPage;
  const indexOfFirstKeyword = indexOfLastKeyword - itemsPerPage;
  const currentKeywords = useMemo(
    () => keywords.slice(indexOfFirstKeyword, indexOfLastKeyword),
    [keywords, indexOfFirstKeyword, indexOfLastKeyword]
  );

  // Function to get domain from URL
  const getDomain = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname.replace('www.', '').toLowerCase();
    } catch (error) {
      return '';
    }
  };

  /**
   * Handle pagination
   */
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /**
   * Render Pagination Controls
   */
  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 7; // Maximum number of page buttons to display
    const halfPageToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfPageToShow);
    let endPage = Math.min(totalPages, currentPage + halfPageToShow);

    if (currentPage <= halfPageToShow) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfPageToShow >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex flex-wrap space-x-2 mt-4">
        {currentPage > 1 && (
          <button
            onClick={() => paginate(currentPage - 1)}
            className="px-4 py-2 text-sm text-slate-600 dark:bg-slate-600 dark:text-slate-400 bg-slate-100 rounded-lg"
          >
            <i className="bx bx-chevron-left"></i>
          </button>
        )}

        {startPage > 1 && (
          <>
            <button
              onClick={() => paginate(1)}
              className={`px-4 py-2 rounded-lg text-sm ${
                currentPage === 1
                  ? 'bg-yellow-500 text-white'
                  : 'bg-slate-100 dark:bg-slate-600 dark:text-slate-400 text-slate-600'
              }`}
            >
              1
            </button>
            {startPage > 2 && <span className="px-4 py-2 text-sm text-slate-600 dark:bg-slate-600 dark:text-slate-400 bg-slate-100 rounded-lg">...</span>}
          </>
        )}

        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-4 py-2 rounded-lg text-sm ${
              currentPage === number
                 ? 'bg-yellow-500 text-white'
                  : 'bg-slate-100 dark:bg-slate-600 dark:text-slate-400 text-slate-600'
            }`}
          >
            {number}
          </button>
        ))}

        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="px-4 py-2 text-sm text-slate-600 dark:bg-slate-600 dark:text-slate-400 bg-slate-100 rounded-lg">...</span>}
            <button
              onClick={() => paginate(totalPages)}
              className={`px-4 py-2 rounded-lg text-sm ${
                currentPage === totalPages
                    ? 'bg-yellow-500 text-white'
                  : 'bg-slate-100 dark:bg-slate-600 dark:text-slate-400 text-slate-600'
              }`}
            >
              {totalPages}
            </button>
          </>
        )}

        {currentPage < totalPages && (
          <button
            onClick={() => paginate(currentPage + 1)}
            className="px-4 py-2 text-sm text-slate-600 dark:bg-slate-600 dark:text-slate-400 bg-slate-100 rounded-lg"
          >
            <i className="bx bx-chevron-right"></i>
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-50">
      {/* Overlay */}
      <div className="fixed inset-0 bg-black dark:opacity-80 opacity-70"  onClick={onClose}></div>

      {/* Modal Content */}
      <div className="fixed inset-0 flex items-center justify-center overflow-y-auto">
        <div className="relative bg-white  dark:bg-slate-700 w-3/4 max-w-6xl max-h-[90vh] rounded-lg border border-slate-200 dark:border-slate-600 shadow-xl overflow-y-auto z-10">
          {/* Close Button */}
          <button
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            <i className="bx bx-x text-2xl"></i>
          </button>

          {/* Header */}
          <div className="px-10 py-8">
            <h2 className="text-2xl">
              <i className="bx bx-search-alt-2 bx-fw"></i> {title}
            </h2>
          </div>

          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg dark:bg-slate-700">
              <thead>
                <tr className="bg-slate-50 dark:bg-slate-600 text-sm border-b border-slate-100 dark:border-slate-600 text-slate-500 dark:text-slate-300">
                  <th className="py-5 px-10 text-left text-xs font-medium  uppercase">
                    Keyword
                  </th>
                  <th className="py-5 px-10 text-left text-xs font-medium uppercase w-full">
                    Info
                  </th>
                  <th className="py-5 text-center text-xs font-medium uppercase">
                    Rank
                  </th>
                  <th className="py-5 text-center text-xs font-medium uppercase">
                    Avg
                  </th>
                  <th className="py-5 text-center text-xs font-medium uppercase">
                    Diff
                  </th>
                  <th className="py-5 text-center text-xs font-medium uppercase"></th>
                </tr>
              </thead>
              <tbody>
                {currentKeywords.length > 0 ? (
                  currentKeywords.map((keyword, index) => (
                    <tr
                      key={index}
                      className="border-t border-slate-100 dark:border-slate-600 dark:hover:bg-slate-600 hover:bg-yellow-50"
                    >
                      <td className="py-5 px-10 text-sm text-slate-700 dark:text-slate-300">
                        {keyword.keyword || 'N/A'}
                      </td>
                      <td className="py-5 px-10 text-sm text-slate-700 dark:text-slate-300">
                        <h3 className="text-lg">{keyword.title || 'N/A'}</h3>
                        {keyword.url ? (
                          <a
                            href={keyword.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                          >
                            {keyword.url}
                          </a>
                        ) : (
                          'N/A'
                        )}
                        <p className="mt-2">{keyword.description || 'N/A'}</p>
                      </td>
                      <td className="py-5 px-5 text-sm text-center text-slate-700 dark:text-slate-300">
                        {keyword.rank !== undefined ? keyword.rank : 'N/A'}
                      </td>
                      <td className="py-5 px-5 text-sm text-center text-slate-700 dark:text-slate-300">
                        {keyword.avg_search_volume !== undefined
                          ? keyword.avg_search_volume.toLocaleString()
                          : 'N/A'}
                      </td>
                      <td className="py-5 px-5 text-sm text-center text-slate-700 dark:text-slate-300">
                        {keyword.keyword_difficulty !== undefined
                          ? keyword.keyword_difficulty
                          : 'N/A'}
                      </td>
                      <td className="py-5 px-5 pr-10 text-center text-slate-700 dark:text-slate-300">
                        {keyword.check_url ? (
                          <a
                            href={keyword.check_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                          >
                            <i className="bx bx-search"></i>
                          </a>
                        ) : (
                          'N/A'
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center py-4 text-slate-700 dark:text-slate-300">
                      No keywords available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div className="px-10 py-5 flex justify-between items-center border-t border-slate-100 dark:border-slate-600">
              <div>
                <p className="text-sm text-gray-700">
                  Showing {indexOfFirstKeyword + 1} to{' '}
                  {Math.min(indexOfLastKeyword, keywords.length)} of {keywords.length} keywords
                </p>
              </div>
              {renderPagination()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
