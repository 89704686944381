import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'boxicons';

const RobotsTxtGenerator = () => {
  const [userAgent, setUserAgent] = useState('*');
  const [disallowPaths, setDisallowPaths] = useState([]);
  const [allowPaths, setAllowPaths] = useState([]);
  const [crawlDelay, setCrawlDelay] = useState('');
  const [sitemap, setSitemap] = useState('');
  const [robotsTxt, setRobotsTxt] = useState('');
  const [copied, setCopied] = useState(false);

  const handleAddPath = (type) => {
    if (type === 'disallow') {
      setDisallowPaths([...disallowPaths, '']);
    } else {
      setAllowPaths([...allowPaths, '']);
    }
  };

  const handleRemovePath = (type, index) => {
    if (type === 'disallow') {
      const newPaths = disallowPaths.filter((_, i) => i !== index);
      setDisallowPaths(newPaths);
    } else {
      const newPaths = allowPaths.filter((_, i) => i !== index);
      setAllowPaths(newPaths);
    }
  };

  const handlePathChange = (type, index, value) => {
    if (type === 'disallow') {
      const newPaths = [...disallowPaths];
      newPaths[index] = value;
      setDisallowPaths(newPaths);
    } else {
      const newPaths = [...allowPaths];
      newPaths[index] = value;
      setAllowPaths(newPaths);
    }
  };

  const handleGenerateRobotsTxt = (e) => {
    e.preventDefault();
    setCopied(false);

    let txt = `User-agent: ${userAgent}\n`;

    allowPaths.forEach(path => {
      if (path) txt += `Allow: ${path}\n`;
    });

    disallowPaths.forEach(path => {
      if (path) txt += `Disallow: ${path}\n`;
    });

    if (crawlDelay) {
      txt += `Crawl-delay: ${crawlDelay}\n`;
    }

    if (sitemap) {
      txt += `Sitemap: ${sitemap}\n`;
    }

    setRobotsTxt(txt.trim());
  };

  return (
    <div className="min-h-screen mx-auto container p-10 bg-slate-100 dark:bg-slate-800 dark:text-white">
      <div className="w-full py-10 flex items-center relative">
        <div className="w-full text-4xl text-left text-slate-800 dark:text-white uppercase">
          Robots.txt Generator
          <div className="text-sm text-slate-500">Generate custom robots.txt file</div>
        </div>
        <p className="absolute right-5 top-10">How to use?</p>
      </div>

      <div className="bg-white rounded-lg border border-slate-200 dark:bg-slate-700 dark:border-slate-600 p-10 relative">
        <form onSubmit={handleGenerateRobotsTxt}>
          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">User-agent</label>
            <input
              type="text"
              value={userAgent}
              onChange={(e) => setUserAgent(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter user-agent (e.g., *)"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Disallow Paths</label>
            {disallowPaths.map((path, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={path}
                  onChange={(e) => handlePathChange('disallow', index, e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter path to disallow (e.g., /private)"
                />
                <button
                  type="button"
                  onClick={() => handleRemovePath('disallow', index)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M16 12L8 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
</svg>
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleAddPath('disallow')}
              className="text-blue-500 hover:text-blue-700"
            >
              + Add Disallow Path
            </button>
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Allow Paths</label>
            {allowPaths.map((path, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={path}
                  onChange={(e) => handlePathChange('allow', index, e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter path to allow (e.g., /public)"
                />
                <button
                  type="button"
                  onClick={() => handleRemovePath('allow', index)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M16 12L8 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
</svg>

                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleAddPath('allow')}
              className="text-blue-500 hover:text-blue-700"
            >
              + Add Allow Path
            </button>
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Crawl Delay (Optional)</label>
            <input
              type="number"
              value={crawlDelay}
              onChange={(e) => setCrawlDelay(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter crawl delay in seconds"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Sitemap (Optional)</label>
            <input
              type="url"
              value={sitemap}
              onChange={(e) => setSitemap(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter sitemap URL"
            />
          </div>
          
          <div className="flex items-center">

          <button
            type="submit"
            className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600"
          >
            Generate Robots.txt
          </button> 
          </div>
        </form>

        {robotsTxt && (
          <div className="mt-6 bg-slate-50 dark:bg-slate-600 p-10 rounded-lg border-slate-100 dark:border-slate-500 border relative">
            <h3 className="text-sm text-slate-500 uppercase mb-4">Generated robots.txt</h3>
            <pre className="whitespace-pre-wrap text-sm">{robotsTxt}</pre>
            <CopyToClipboard text={robotsTxt} onCopy={() => setCopied(true)}>
              <button className="absolute top-5 right-5 text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
                  <path d="M16.9637 8.98209C16.9613 6.03194 16.9167 4.50384 16.0578 3.45753C15.892 3.25546 15.7067 3.07019 15.5047 2.90436C14.4008 1.99854 12.7609 1.99854 9.48087 1.99854C6.20089 1.99854 4.5609 1.99854 3.45708 2.90436C3.255 3.07018 3.06971 3.25546 2.90387 3.45753C1.99799 4.56128 1.99799 6.20116 1.99799 9.48091C1.99799 12.7607 1.99799 14.4005 2.90387 15.5043C3.0697 15.7063 3.255 15.8916 3.45708 16.0574C4.50346 16.9162 6.03167 16.9608 8.98201 16.9632" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.0283 9.02455L16.994 8.98193M14.0143 22.0013L16.9799 21.9586M21.9716 14.0221L21.9436 16.9818M9.01033 14.0357L8.98236 16.9953M11.4873 9.02455C10.6545 9.17371 9.31781 9.32713 9.01033 11.0488M19.4946 21.9586C20.3296 21.8223 21.6685 21.6894 22.0025 19.9726M19.4946 9.02455C20.3274 9.17371 21.6641 9.32713 21.9716 11.0488M11.5 21.9573C10.6672 21.8086 9.33039 21.6559 9.02197 19.9344" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
            </CopyToClipboard>
            {copied && <p className="text-green-500 mt-2">Copied to clipboard!</p>}
          </div>
        )}
        <p className="mt-10 text-center text-sm text-slate-500 dark:text-slate-400">I am aware that I’m using the robots.txt generator at my own risk. No liability shall be accepted by Seotci for any errors or non-indexing of a website. </p>
      </div>
      
    </div>
  );
};

export default RobotsTxtGenerator;
