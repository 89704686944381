import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from './components/InputField';
import { Link } from 'react-router-dom';
import Logo from './img/seotic-logo.png';
import LogoWhite from './img/seotic-logo-w.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
const timestamp = new Date().getTime();

  const handleLogin = async (e) => {
    e.preventDefault();
    const loginData = { email, password };

    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/user/login.php?_=${timestamp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

     if (result.jwt) {
  localStorage.setItem('token', result.jwt);
  toast.success('Login successful!');
  navigate('/projects', { state: { loggedIn: true } }); // Pass login status as state
} else {
  toast.error(result.message || 'Login failed. Please try again.');
}
    } catch (error) {
      console.error('Error:', error);
      toast.error('Login failed. Please check your credentials and try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100 dark:bg-slate-800 dark:text-white">
      <div className="w-32 mb-10 dark:hidden"><img src={Logo} alt="Logo" /></div>
      <div className="w-32 mb-10 hidden dark:block"><img src={LogoWhite} alt="Logo White" /></div>
      <div className="md:min-w-96 md:bg-white rounded-lg border border-slate-100 md:border-slate-200 dark:bg-slate-800 md:dark:bg-slate-700 dark:border-slate-800 md:dark:border-slate-600">
        <div>
          <div className="p-0 md:p-10 ">
            <h2 className="text-2xl">Welcome back</h2>
            <p className="text-slate-400 text-sm mb-10">Sign in to your account to continue</p>
            <form onSubmit={handleLogin}>
              <InputField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                icon="envelope"
              />
              <InputField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                icon="lock-alt"
              />
              <Link
                className="text-slate-500 dark:text-slate-400 text-sm w-full text-right"
                to="/forgot">Forgot Password?
              </Link>
              <button
                type="submit"
                className="w-full py-2 mt-4 text-white bg-yellow-500 rounded hover:bg-yellow-400"
              >
                Login
              </button>
            </form>
          </div>
          <div className="px-10 py-5 text-center bg-slate-100 md:bg-slate-50 md:dark:bg-slate-600 dark:bg-slate-800 rounded-b-lg ">
            Don't have an account?
            <Link className="text-green-500 ml-5" to="/register">Signup</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
