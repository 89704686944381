// src/Settings.js
import React, { useState, useEffect } from 'react';
import 'boxicons/css/boxicons.min.css';
import LoadingScreen from './components/LoadingScreen';
import Modal from './components/SettingModal'; // Assuming Modal is your generic modal component
import { toast } from 'react-toastify'; // Import toast for notifications
import Logo from './img/seotic-logo.png';
import LogoWhite from './img/seotic-logo-w.png';

import { Link, useNavigate, useLocation } from 'react-router-dom';
const Settings = () => {
  // State variables
  const [userInfo, setUserInfo] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const [userSubscriptions, setUserSubscriptions] = useState([]); // Updated for multiple subscriptions
  const [userUsage, setUserUsage] = useState(null); // From previous integration

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // Modal States
  const [isEditInfoModalOpen, setIsEditInfoModalOpen] = useState(false);
  const [isEditPasswordModalOpen, setIsEditPasswordModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
  const timestamp = new Date().getTime();
  // Retrieve token from localStorage
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        if (!token) {
          throw new Error('Authentication token is missing. Please log in again.');
        }

        // Fetch User Info
        console.log('Fetching user info with token:', token);
        const userInfoResponse = await fetch(`https://www.pixelliongroup.com/seotic/get_user_info.php?_=${timestamp}`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json'
          },
          credentials: 'include', // Include cookies if needed
          body: JSON.stringify({ token }),
        });
        
        const userInfoData = await userInfoResponse.json();
        
        console.log('User Info Response:', userInfoData);
        
        if (userInfoResponse.ok && userInfoData.status === 'success') {
          setUserInfo(userInfoData.user);
        } else {
          throw new Error(userInfoData.message || 'Failed to fetch user info.');
        }
        
        // Fetch User Plan
        console.log('Fetching user plan with token:', token);
        const userPlanResponse = await fetch(`https://www.pixelliongroup.com/seotic/get_user_plan.php?_=${timestamp}`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ token }),
        });
        
        const userPlanData = await userPlanResponse.json();
        
        console.log('User Plan Response:', userPlanData);
        
        if (userPlanResponse.ok && userPlanData.status === 'success') {
          setUserPlan(userPlanData.plan);
        } else {
          throw new Error(userPlanData.message || 'Failed to fetch user plan.');
        }
        
        // Fetch User Subscriptions
        console.log('Fetching user subscriptions with token:', token);
        const userSubscriptionsResponse = await fetch(`https://www.pixelliongroup.com/seotic/get_user_subscription.php?_=${timestamp}`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ token }),
        });
        
        const userSubscriptionsData = await userSubscriptionsResponse.json();
        
        console.log('User Subscriptions Response:', userSubscriptionsData);
        
        if (userSubscriptionsResponse.ok && Array.isArray(userSubscriptionsData.subscription)) {
          setUserSubscriptions(userSubscriptionsData.subscription);
        } else {
          // It's possible the user doesn't have any subscriptions yet
          setUserSubscriptions([]);
        }
        
        // Fetch User Usage
        console.log('Fetching user usage with token:', token);
        const userUsageResponse = await fetch(`https://www.pixelliongroup.com/seotic/get_user_usage.php?_=${timestamp}`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ token }),
        });
        
        const userUsageData = await userUsageResponse.json();
        
        console.log('User Usage Response:', userUsageData);
        
        if (userUsageResponse.ok && userUsageData.plan_type) {
          setUserUsage(userUsageData);
        } else {
          throw new Error(userUsageData.message || 'Failed to fetch user usage.');
        }
        
      } catch (err) {
        console.error('Error fetching settings data:', err);
        setError(err.message || 'Failed to fetch settings data.');
        toast.error(err.message || 'Failed to fetch settings data.');
      } finally {
        setLoading(false);
      }
    };

    fetchSettingsData();
  }, [token]);


  // Handlers for Modals
  const openEditInfoModal = () => setIsEditInfoModalOpen(true);
  const closeEditInfoModal = () => setIsEditInfoModalOpen(false);
  
  const openEditPasswordModal = () => setIsEditPasswordModalOpen(true);
  const closeEditPasswordModal = () => setIsEditPasswordModalOpen(false);
  
  const openDeleteAccountModal = () => setIsDeleteAccountModalOpen(true);
  const closeDeleteAccountModal = () => setIsDeleteAccountModalOpen(false);

  // Handler for Updating User Info
  const handleUpdateUserInfo = async (updatedInfo) => {
    try {
      console.log('Updating user info with:', updatedInfo);
      const response = await fetch(`https://www.pixelliongroup.com/seotic/update_user_info.php?_=${timestamp}`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ token, ...updatedInfo }),
      });
      
      const data = await response.json();
      
      console.log('Update User Info Response:', data);
      
      if (response.ok && data.status === 'success') {
        setUserInfo(data.user);
        closeEditInfoModal();
        toast.success('User information updated successfully.');
      } else {
        throw new Error(data.message || 'Failed to update user info.');
      }
      
    } catch (err) {
      console.error('Error updating user info:', err);
      toast.error(err.message || 'Failed to update user info.');
    }
  };

  // Handler for Updating Password
  const handleUpdatePassword = async (passwordData) => {
    try {
      console.log('Updating password with:', passwordData);
      const response = await fetch(`https://www.pixelliongroup.com/seotic/update_password.php?_=${timestamp}`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ token, ...passwordData }),
      });
      
      const data = await response.json();
      
      console.log('Update Password Response:', data);
      
      if (response.ok && data.status === 'success') {
        toast.success('Password updated successfully.');
        closeEditPasswordModal();
      } else {
        throw new Error(data.message || 'Failed to update password.');
      }
      
    } catch (err) {
      console.error('Error updating password:', err);
      toast.error(err.message || 'Failed to update password.');
    }
  };

  // Handler for Deleting Account
  const handleDeleteAccount = async () => {
    try {
      console.log('Deleting account.');
      const response = await fetch(`https://www.pixelliongroup.com/seotic/delete_account.php?_=${timestamp}`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ token }),
      });
      
      const data = await response.json();
      
      console.log('Delete Account Response:', data);
      
      if (response.ok && data.status === 'success') {
        toast.success('Account deleted successfully.');
        // Perform logout or redirect
        localStorage.removeItem('token');
        localStorage.removeItem('project');
        window.location.href = '/login'; // Adjust the redirect as needed
      } else {
        throw new Error(data.message || 'Failed to delete account.');
      }
      
    } catch (err) {
      console.error('Error deleting account:', err);
      toast.error(err.message || 'Failed to delete account.');
    }
  };

  // Handlers for Count Animation (optional)
  const useCountAnimation = (endValue, duration = 2000) => {
    const [value, setValue] = useState(0);
    useEffect(() => {
      if (isNaN(endValue)) {
        setValue(0);
        return;
      }

      let start = 0;
      const increment = endValue / (duration / 20);
      const timer = setInterval(() => {
        start += increment;
        if (start >= endValue) {
          setValue(Math.round(endValue));
          clearInterval(timer);
        } else {
          setValue(Math.round(start));
        }
      }, 20);

      return () => clearInterval(timer);
    }, [endValue, duration]);

    return value;
  };

  // Metrics Calculation (optional, based on your current data)
  const totalCompetitors = userPlan ? userPlan.competitor_limit : 0;
  const animatedTotalCompetitors = useCountAnimation(totalCompetitors);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen p-10 mx-auto container dark:text-white">
      <h1 className="text-3xl mb-5">Settings</h1>
      
      {error && (
        <div className="bg-red-100 text-red-700 p-4 rounded mb-5">
          {error}
        </div>
      )}
      
      
           
      <div className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5 mb-5">
      {/* Current Plan Section */}
      <section className="mb-10">
        
        {userPlan ? (
          <div className="bg-white dark:bg-slate-700 rounded-lg border border-slate-200 dark:border-slate-600">
          <div className="px-10 pt-10">
          <div className="flex justify-center">
          <div className="w-32 mb-10 dark:hidden"><img src={Logo} alt="Logo" /></div>
      <div className="w-32 mb-10 hidden dark:block"><img src={LogoWhite} alt="Logo White" /></div>
      </div>
          <h2 className="text-sm w-full text-center text-yellow-500 dark:text-yellow-400">Current Plan</h2>
            <p className="w-full text-center text-2xl  uppercase">{userPlan.plan_type} Plan</p>
            </div>
            
            {userPlan.plan_type === 'seed' ? (
              <div className="px-10 py-10 text-slate-500 dark:text-slate-400 text-sm">
                Welcome to Seotic! Here are your available credits for your current plan.
              </div>
            ) : (
              <div className="mt-4 px-10 py-5 text-sm text-slate-500 dark:text-slate-400">
               Thank you for choosing a paid plan! Below are your account credits to help you get started.
              </div>
            )}

            <div className="text-sm">
              <div className="border-y border-slate-100 dark:border-slate-600 px-10 py-3 flex items-center w-full"><p className="w-full">Projects Limit</p><p className="w-full text-right  font-medium">{userPlan.projects_limit}</p></div>
              <div className="border-b border-slate-100 dark:border-slate-600 px-10 py-3 flex items-center w-full"><p className="w-full ">Keywords Limit</p><p className="w-full text-right  font-medium">{userPlan.keywords_limit}</p></div>
              <div className="border-b border-slate-100 dark:border-slate-600 px-10 py-3 flex items-center w-full"><p className="w-full">Keyword Research / Days</p><p className="text-right  font-medium">{userPlan.rekeyword_limit}</p></div>
              <div className="border-b border-slate-100 dark:border-slate-600 px-10 py-3 flex items-center w-full"><p className="w-full">Competitor Limit</p><p className="text-right  font-medium">{userPlan.competitor_limit}</p></div>
              <div className="border-b border-slate-100 dark:border-slate-600 px-10 py-3 flex items-center w-full"><p className="w-full">AI Credits / Day</p><p className="w-full text-right  font-medium">{userPlan.ai_limit}</p></div>
              <div className="border-slate-100 dark:border-slate-600 px-10 py-3 flex items-center w-full"><p className="w-full">Blog Post / Day</p><p className="w-full text-right  font-medium">{userPlan.blog_limit}</p></div>

            </div>
            {userPlan.plan_type === 'seed' ? (
              <div className="mx-10 mt-5 mb-10 ">
                <button className="w-full bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-400">
                  <Link to="/subscription">Upgrade Plan</Link>
                </button>
                <p className="text-center mt-5 text-sm text-slate-500 dark:text-slate-400 hidden">Why should I?</p>
              </div>
            ):(<p className="text-xs text-slate-500 dark:text-slate-400 px-10 py-5">
You can cancel your subscription at any time on your <a target="_blank" className="text-yellow-500 dark:text-yellow-100" href="https://billing.stripe.com/p/login/6oE7um0Yr0Fgb4s8ww">stripe dashboard</a>. If you cancel, your subscription will remain active until the end of the current billing period.</p>)}
          </div>
        ) : (
          <p>Plan information not available.</p>
        )}
        <div className="mt-2 text-center text-sm text-sm dark:text-slate-400 text-slate-500">
        <a href="https://status.seotic.co" className="text-sm dark:text-slate-400 dark:hover:text-slate-200 text-slate-500 hover:text-slate-900 " target="_blank">Status</a> • <a href="https://seotic.co/changelog" className="text-sm dark:text-slate-400 dark:hover:text-slate-200 text-slate-500 hover:text-slate-900 " target="_blank">Change Log</a> • v1.0.0.1
        </div>
      </section>
     
      {/* Billing Section */}
      <section className="mb-10 col-span-3 ">
      
       {/* User Info Section */}
      <section className="mb-5 relative">
         
        {userInfo ? (
          <div className="bg-white dark:bg-slate-700 p-10 rounded-lg border border-slate-200 dark:border-slate-600">
           <h2 className="text-2xl mb-3 flex items-center"><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30"  fill="none">
    <path d="M18.9154 11.6997L17.373 15.8314C16.6547 17.7556 16.2955 18.7177 15.709 19.377C14.812 20.3851 13.5203 20.9748 12.1549 20.9995C11.262 21.0156 10.2783 20.6665 8.31091 19.9683C7.27913 19.6022 6.76324 19.4191 6.32165 19.1455C5.64795 18.7281 5.09127 18.1534 4.70166 17.4731C4.44628 17.0272 4.28743 16.5137 3.96974 15.4867L2.56985 10.9613C2.35476 10.266 2.64855 9.51553 3.28412 9.13687C4.11475 8.64198 5.19966 8.96175 5.60953 9.82225L6.5443 11.7848L9.1763 4.73429C9.4501 4.00083 10.2819 3.62265 11.0342 3.88961C11.7865 4.15657 12.1743 4.96757 11.9005 5.70103M11.9005 5.70103L12.5616 3.93029C12.8354 3.19683 13.6672 2.81866 14.4194 3.08562C15.1717 3.35257 15.5596 4.16357 15.2858 4.89704L14.6248 6.66777M11.9005 5.70103L10.4132 9.68518M14.6248 6.66777C14.8986 5.93431 15.7304 5.55613 16.4826 5.82309C17.2349 6.09005 17.6228 6.90105 17.349 7.63451L16.688 9.40524M14.6248 6.66777L13.1374 10.6519M18.5859 12.5854L19.4122 10.372C19.686 9.63852 19.2981 8.82752 18.5458 8.56056C17.7936 8.2936 16.9618 8.67178 16.688 9.40524M16.688 9.40524L15.8617 11.6187" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.3307 14C21.877 15.6354 21.0574 17.4263 19.5 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>Hello! {userInfo.username}</h2>
           <p className="text-slate-500 dark:text-slate-400">Here you can edit your information, manage plan and billing.</p>
            <p className="hidden"><strong>Email:</strong> {userInfo.email}</p>
            <div className="mt-4">
              <button onClick={openEditInfoModal} className="bg-blue-500 hover:bg-blue-400 text-sm text-white px-4 py-2 rounded mr-2">
                Update Info
              </button>
              <button onClick={openEditPasswordModal} className="bg-yellow-500 hover:bg-yellow-400 text-sm text-white px-4 py-2 rounded mr-2">
                Change Password
              </button>
              
                <button onClick={openDeleteAccountModal} className="flex items-center absolute right-2 bottom-2 text-red-500 hover:text-red-400 text-sm px-4 py-2">
            <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"  fill="none">
    <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M11.9998 16H12.0088" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 13L12 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg> Delete Account
          </button>
            </div>
          </div>
        ) : (
          <p>User information not available.</p>
        )}
        
      
        
      </section>
	
      
        
        {userSubscriptions.length > 0 ? (
          <div className="bg-white dark:bg-slate-700 rounded-lg border border-slate-200 dark:border-slate-600 overflow-x-auto">
          <div className="flex items-center  px-10 pb-5 pt-10">
          <h2 className="text-xl w-full flex items-center "><svg  className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30" fill="none">
    <path d="M2 12C2 8.46252 2 6.69377 3.0528 5.5129C3.22119 5.32403 3.40678 5.14935 3.60746 4.99087C4.86213 4 6.74142 4 10.5 4H13.5C17.2586 4 19.1379 4 20.3925 4.99087C20.5932 5.14935 20.7788 5.32403 20.9472 5.5129C22 6.69377 22 8.46252 22 12C22 15.5375 22 17.3062 20.9472 18.4871C20.7788 18.676 20.5932 18.8506 20.3925 19.0091C19.1379 20 17.2586 20 13.5 20H10.5C6.74142 20 4.86213 20 3.60746 19.0091C3.40678 18.8506 3.22119 18.676 3.0528 18.4871C2 17.3062 2 15.5375 2 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 16H11.5" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.5 16L18 16" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2 9H22" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
</svg> Billing History</h2> <p className="text-sm w-full text-right text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-300"><a target="_blank" href="https://billing.stripe.com/p/login/6oE7um0Yr0Fgb4s8ww">Manage on stripe</a></p>
          </div>
            <table className="min-w-full ">
              <thead className="bg-slate-50 dark:bg-slate-600">
                <tr>
                  <th
                    scope="col"
                    className="px-6 pl-10 py-5 text-left text-xs font-medium text-gray-500 dark:text-slate-300 uppercase tracking-wider"
                  >
                    Invoice ID
                  </th>
                  <th
                    scope="col"
                    className="pl-10px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-slate-300 uppercase tracking-wider"
                  >
                    Amount Paid
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-slate-300 uppercase tracking-wider"
                  >
                    Payment Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-slate-300 uppercase tracking-wider"
                  >
                    Plan
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-slate-300 uppercase tracking-wider"
                  >
                    Expired Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-slate-300 uppercase tracking-wider"
                  >
                    Paid Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-slate-700 divide-y divide-slate-200 dark:divide-slate-600">
                {userSubscriptions.map((subscription) => (
                  <tr key={subscription.id}>
                    <td className="pl-10 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white ">
                      ​<a href={subscription.invoice_link} target="_blank" className="bg-yellow-500 px-2 py-1 rounded text-white">SP-{subscription.id || 'N/A'}</a>
                    </td>
                    <td className="flex items-center py-4 whitespace-nowrap text-sm text-gray-500 dark:text-slate-300">
                      <p className="bg-blue-500 text-xs rounded px-2 py-1 border-blue-400 border text-white mr-2">{subscription.payment_method || 'N/A'}</p>${parseFloat(subscription.amount_paid).toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-slate-300">
                      {subscription.paid_type.charAt(0).toUpperCase() + subscription.paid_type.slice(1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-slate-300">
                      {subscription.plan_type.charAt(0).toUpperCase() + subscription.plan_type.slice(1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-slate-300">
                      {new Date(subscription.expired_date).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-slate-300">
                      {new Date(subscription.paid_date).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center text-slate-500 dark:text-slate-300">Seed plan, No billing information available.</p>
        )}
      </section>
     
       </div>
      
      
      {/* Delete Account Section */}
      <section className="hidden">
        <h2 className="text-2xl mb-3">Delete Account</h2>
        <div className="bg-white dark:bg-slate-700 p-5 rounded shadow">
          <button onClick={openDeleteAccountModal} className="bg-red-500 text-white px-4 py-2 rounded">
            Delete Account
          </button>
        </div>
      </section>
      
      {/* Edit Info Modal */}
      {isEditInfoModalOpen && (
        <Modal onClose={closeEditInfoModal} title="Edit User Info">
          <EditInfoForm userInfo={userInfo} onSubmit={handleUpdateUserInfo} onClose={closeEditInfoModal} />
        </Modal>
      )}
      
      {/* Edit Password Modal */}
      {isEditPasswordModalOpen && (
        <Modal onClose={closeEditPasswordModal} title="Edit Password">
          <EditPasswordForm onSubmit={handleUpdatePassword} onClose={closeEditPasswordModal} />
        </Modal>
      )}
      
      {/* Delete Account Modal */}
      {isDeleteAccountModalOpen && (
        <Modal onClose={closeDeleteAccountModal} title="Confirm Delete Account">
          <div>
            <p className="mb-5">Are you sure you want to delete your account? This action cannot be undone.</p>
            <p className="mb-5 text-red-500 dark:text-red-300">By deleting this will also be deleted all of your projects.</p>
            <div className="flex justify-end mt-10">
              <button onClick={closeDeleteAccountModal} className="px-4 py-2 text-slate-600 rounded-lg dark:hover:text-slate-300 hover:text-slate-800 dark:text-slate-500 focus:outline-none focus:ring-2 focus:ring-gray-300">
                No, Keep it
              </button>
              <button onClick={handleDeleteAccount} className="bg-red-500 text-white px-4 py-2 rounded flex items-center">
               <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"  fill="none">
    <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M9 11.7349H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M10.5 15.6543H13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M3 5.5H21M16.0555 5.5L15.3729 4.09173C14.9194 3.15626 14.6926 2.68852 14.3015 2.39681C14.2148 2.3321 14.1229 2.27454 14.0268 2.2247C13.5937 2 13.0739 2 12.0343 2C10.9686 2 10.4358 2 9.99549 2.23412C9.89791 2.28601 9.80479 2.3459 9.7171 2.41317C9.32145 2.7167 9.10044 3.20155 8.65842 4.17126L8.05273 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /> 
</svg> Yes, Delete 
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

// Edit Info Form Component
const EditInfoForm = ({ userInfo, onSubmit, onClose }) => {
  const [username, setUsername] = useState(userInfo.username);
  const [email, setEmail] = useState(userInfo.email);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ username, email });
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-slate-700 dark:text-slate-500 mb-2 text-sm font-medium">Name <span className="text-red-500">*</span></label>
        <input 
          type="text" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          className="w-full px-3 py-3 border border-slate-100 dark:border-slate-600 bg-slate-50 dark:bg-slate-800 rounded-md focus:outline-none"
          required 
        />
      </div>
      <div className="mb-4">
        <label className="block text-slate-700 dark:text-slate-500 mb-2 text-sm font-medium">Email <span className="text-red-500">*</span></label>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          className="w-full px-3 py-3 border border-slate-100 dark:border-slate-600 bg-slate-50 dark:bg-slate-800 rounded-md focus:outline-none"
          required 
        />
      </div>
      <div className="flex justify-end mt-10">
        <button type="button" onClick={onClose} className="px-4 py-2 text-slate-600 rounded-lg dark:hover:text-slate-300 hover:text-slate-800 dark:text-slate-500 focus:outline-none focus:ring-2 focus:ring-gray-300">
          Cancel
        </button>
        <button
            onClick={handleSubmit}
            className="flex items-center px-5 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
             Save <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
    <path d="M10.5 8C10.5 8 13.5 10.946 13.5 12C13.5 13.0541 10.5 16 10.5 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>          </button>
      </div>
    </form>
  );
};

// Edit Password Form Component
const EditPasswordForm = ({ onSubmit, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if(newPassword !== confirmPassword){
      toast.error("New password and confirmation do not match.");
      return;
    }
    onSubmit({ current_password: currentPassword, new_password: newPassword });
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-slate-700 dark:text-slate-500 mb-2 text-sm font-medium">Current Password <span className="text-red-500">*</span></label>
        <input 
          type="password" 
          value={currentPassword} 
          onChange={(e) => setCurrentPassword(e.target.value)} 
            className="w-full px-3 py-3 border border-slate-100 dark:border-slate-600 bg-slate-50 dark:bg-slate-800 rounded-md focus:outline-none"
          required 
        />
      </div>
      <div className="mb-4">
        <label className="block text-slate-700 dark:text-slate-500 mb-2 text-sm font-medium">New Password <span className="text-red-500">*</span></label>
        <input 
          type="password" 
          value={newPassword} 
          onChange={(e) => setNewPassword(e.target.value)} 
            className="w-full px-3 py-3 border border-slate-100 dark:border-slate-600 bg-slate-50 dark:bg-slate-800 rounded-md focus:outline-none"
          required 
        />
      </div>
      <div className="mb-4">
        <label className="block text-slate-700 dark:text-slate-500 mb-2 text-sm font-medium">Retype Password <span className="text-red-500">*</span></label>
        <input 
          type="password" 
          value={confirmPassword} 
          onChange={(e) => setConfirmPassword(e.target.value)} 
           className="w-full px-3 py-3 border border-slate-100 dark:border-slate-600 bg-slate-50 dark:bg-slate-800 rounded-md focus:outline-none"
          required 
        />
      </div>
     <div className="flex justify-end mt-10">
        <button type="button" onClick={onClose} className="px-4 py-2 text-slate-600 rounded-lg dark:hover:text-slate-300 hover:text-slate-800 dark:text-slate-500 focus:outline-none focus:ring-2 focus:ring-gray-300">
          Cancel
        </button>
        <button
            onClick={handleSubmit}
            className="flex items-center px-5 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
             Save <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
    <path d="M10.5 8C10.5 8 13.5 10.946 13.5 12C13.5 13.0541 10.5 16 10.5 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>          </button>
      </div>
    </form>
  );
};

export default Settings;
