import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import InputField from './components/InputField';
import Logo from './img/seotic-logo.png';
import LogoWhite from './img/seotic-logo-w.png';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState('');
  const timestamp = new Date().getTime();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');

  // Password strength checker function
  const checkPasswordStrength = (password) => {
    if (!password) {
      setPasswordStrength('');
      return;
    }

    const strengthRegex = {
      strong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/, // At least 8 chars, 1 upper, 1 lower, 1 number, 1 special
      medium: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/, // At least 6 chars, 1 upper, 1 lower, 1 number
    };

    if (strengthRegex.strong.test(password)) {
      setPasswordStrength('strong');
    } else if (strengthRegex.medium.test(password)) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  };

  // Handle password change and check strength
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
  };

  // Check if passwords match
  React.useEffect(() => {
    if (confirmPassword) {
      setPasswordsMatch(password === confirmPassword);
    }
  }, [password, confirmPassword]);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!passwordsMatch) {
      toast.error('Passwords do not match!');
      return;
    }

    if (!token) {
      toast.error('Invalid or expired token. Please try resetting your password again.');
      return;
    }

    const requestData = { token, password };

    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/reset_password.php?_=${timestamp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      const result = await response.json();

      if (response.ok && result.status === 'success') {
        toast.success('Password reset successfully! Redirecting to login...');
        navigate('/login'); // Redirect to login page on success
      } else {
        toast.error(result.message || 'Failed to reset password. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
  <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100 dark:bg-slate-800 dark:text-white">
      <div className="w-32 mb-10 dark:hidden">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="w-32 mb-10 hidden dark:block">
        <img src={LogoWhite} alt="Logo" />
      </div>
      <div className="md:min-w-96 md:bg-white rounded-lg border border-slate-100 md:border-slate-200 dark:bg-slate-800 md:dark:bg-slate-700 dark:border-slate-800 md:dark:border-slate-600">
         <div className="p-0 md:p-10 ">
          <h2 className="text-2xl">Reset Password</h2>
          <p className="text-slate-400 text-sm mb-10">Enter a new password for your account.</p>

          <form onSubmit={handleResetPassword}>
            <InputField
              label="New Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              icon="lock-alt"
              placeholder="New password"
            />
            <div className={`mb-5 -mt-2 text-xs ${passwordStrength === 'strong' ? 'text-green-500' : passwordStrength === 'medium' ? 'text-yellow-500' : 'text-red-500'}`}>
              {passwordStrength === 'strong' && '* Strong password'}
              {passwordStrength === 'medium' && '* Medium strength password'}
              {passwordStrength === 'weak' && '* Weak password'}
            </div>
            <InputField
              label="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              icon="lock-alt"
              placeholder="Confirm new password"
            />
            {!passwordsMatch && confirmPassword && (
              <div className="mb-5 -mt-2 text-xs text-red-500 mt-2">* Passwords do not match!</div>
            )}

            <button
              type="submit"
              className="w-full py-2 mt-4 text-white bg-yellow-500 rounded"
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
