// src/components/Subscription.js
import React, { useState } from 'react';
import Accordion from './Accordion';

const Subscription = () => {
  const [loading, setLoading] = useState(false);
  const [togglePricing, setTogglePricing] = useState('monthly');
  const [error, setError] = useState(null);

  // Define your plans with corresponding Stripe Price IDs
  const plans = [
    {
      name: 'Sprout',
      prices: {
        monthly: 'price_1QIEXuIwr5n3LbiJKyG1SmoZ', // Replace with your Stripe Price ID for $9/month
        yearly: 'price_1QIEYwIwr5n3LbiJFFAWiJVH', // Replace with your Stripe Price ID for $95/year
      },
    },
    {
      name: 'Tree',
      prices: {
        monthly: 'price_1QIEatIwr5n3LbiJf3cFFrl0', // Replace with your Stripe Price ID for $75/month
        yearly: 'price_1QIEbRIwr5n3LbiJmQI23mRU', // Replace with your Stripe Price ID for $800/year
      },
    },
  ];

  const handleSubscribe = async (priceId) => {
    setLoading(true);
    setError(null);

    try {
      // Retrieve the JWT token from storage (adjust based on your implementation)
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('User is not authenticated.');
      }

      // Make API call to create a checkout session
      const response = await fetch('https://www.pixelliongroup.com/seotic/create_checkout_session.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, price_id: priceId }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        // Redirect to Stripe Checkout
        window.location.href = data.session_url;
      } else {
        throw new Error(data.message || 'Failed to create checkout session.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  const faqItems = [
    {
      question: 'Are there any hidden fees or additional costs?',
      answer: 'No, there are no hidden fees or additional costs.',
    },
    {
      question: 'How is billing handled and what payment methods are accepted?',
      answer: 'We accept major credit cards through Stripe for secure and seamless billing.',
    },
    {
      question: 'How does the tree-planting initiative work?',
      answer: 'For each paid user, Seotic plants a tree with a trusted tree planting organization, one tree planted.',
    },
    {
      question: 'Is the Seed plan really free?',
      answer: 'Yes, the Seed plan is forever free and does not require a credit card.',
    },
    {
      question: 'What if I change my mind?',
      answer: 'You can cancel your plan anytime through your Stripe dashboard. There are no cancellation fees.',
    },
  ];

  return (
    <div className="p-10 mx-auto flex h-full items-center container dark:text-white">
      <div className="w-full py-10 flex flex-col">
        <div className="flex flex-col justify-center items-center mb-8">
        <p>Your account was expired, please renew again</p>
          <h2 className="text-4xl w-full dark:text-white text-center text-slate-800 mb-4 uppercase font-medium">
            Select the Best Plan for Your Needs
          </h2>
          <p className="text-slate-500 text-center w-2/3 mb-8">
            Unlock powerful SEO tools, competitor insights, and more to help your website succeed. Choose the perfect plan and grow your online presence.
          </p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded mb-5">
            {error}
          </div>
        )}

        {/* Pricing Toggle */}
        <div className="flex justify-center mb-8 text-sm relative">
          <button
            onClick={() => setTogglePricing('monthly')}
            className={`px-4 py-2 rounded-l-lg ${
              togglePricing === 'monthly'
                ? 'bg-yellow-500 text-white'
                : 'bg-slate-200 dark:bg-slate-600 text-slate-700 dark:text-gray-200'
            }`}
            aria-pressed={togglePricing === 'monthly'}
            aria-label="Toggle to monthly pricing"
          >
            Monthly
          </button>
          <button
            onClick={() => setTogglePricing('yearly')}
            className={`px-4 py-2 rounded-r-lg ${
              togglePricing === 'yearly'
                ? 'bg-yellow-500 text-white'
                : 'bg-slate-200 dark:bg-slate-600 text-slate-700 dark:text-gray-200'
            }`}
            aria-pressed={togglePricing === 'yearly'}
            aria-label="Toggle to yearly pricing"
          >
            Yearly 
          </button>
        </div>

        {/* Pricing Grid */}
        <div className="grid gap-5 md:grid-cols-3">
          {/* Seed Plan */}
          <div className="border rounded-lg my-5 p-10 py-16 bg-white dark:bg-slate-700 border-slate-200 dark:border-slate-600">
            <h3 className="font-medium text-3xl uppercase text-center mb-5 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" className="mr-2" fill="none">
                {/* SVG Paths */}
              </svg>
              Seed
            </h3>
            <div className="mx-auto w-fit">
              <p className="mb-4 bg-slate-500 text-white rounded px-3 py-1">1 Project</p>
            </div>
            <div className="text-center my-12 border-y border-slate-100 dark:border-slate-600 py-5">
              <p className="text-slate-900 dark:text-slate-100 text-4xl">Free</p>
            </div>
            <ul className="space-y-4">
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>2 Keywords Tracking / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Website Audit / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Backlinks Monitor / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Email Support</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 AI Credit / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>AI Blog Writing <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">soon</span></li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>5 Internal Pages Audit</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Mini Tools</li>
              <li><i className="text-red-500 bx bx-x-circle mr-2 bx-fw"></i>No Competitor Analysis</li>
              <li><i className="text-red-500 bx bx-x-circle mr-2 bx-fw"></i>No Keyword Research</li>
            </ul>
          </div>

          {/* Sprout Plan */}
          <div className="border rounded-lg shadow-lg p-10 py-16 bg-white dark:bg-slate-700 border-yellow-200 dark:border-yellow-500 relative">
            <div className="absolute top-0 left-0 rounded-t-lg w-full bg-yellow-200 dark:bg-yellow-500 text-yellow-900 dark:text-white px-4 py-1 font-semibold text-center">
              Recommended
            </div>
            <h3 className="font-medium text-3xl uppercase text-center mt-5 mb-5 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" className="mr-2" fill="none">
                {/* SVG Paths */}
              </svg>
              Sprout
            </h3>
            <div className="mx-auto w-fit">
              <p className="mb-4 bg-yellow-500 text-white rounded px-3 py-1">1 Project</p>
            </div>
            <div className="text-center my-12 border-y flex justify-center items-center border-slate-100 dark:border-slate-600 py-5">
              <p className="text-slate-900 dark:text-slate-100 text-5xl">${togglePricing === 'monthly' ? '9' : '90'}</p>
              {togglePricing === 'yearly' && <p className="text-sm w-fit bg-green-100 dark:bg-green-500 rounded px-3 py-1 ml-5">Save $18</p>}
            </div>
            <ul className="space-y-4">
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>10 Keywords Tracking / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Website Audit / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Backlinks Monitor / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Priority Email Support</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 AI Credit / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>AI Blog Writing <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">soon</span></li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Unlimited Internal Pages Audit</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Mini Tools</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>3 Competitors</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>5 Keyword Research / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Professional Profile <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">soon</span></li>
            </ul>
            <button
              onClick={() => handleSubscribe(plans[0].prices[togglePricing])} // Sprout Plan
              className="w-full mt-10 px-5 py-3 rounded bg-yellow-500 text-white"
              disabled={loading}
              aria-label="Subscribe to Sprout Plan"
            >
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
          </div>

          {/* Tree Plan */}
          <div className="border rounded-lg p-10 py-16 my-5 bg-white dark:bg-slate-700 border-slate-200 dark:border-slate-600">
            <h3 className="font-medium text-3xl uppercase text-center mb-5 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" className="mr-2" fill="none">
                {/* SVG Paths */}
              </svg>
              Tree
            </h3>
            <div className="mx-auto w-fit">
              <p className="mb-4 bg-green-500 text-white rounded px-3 py-1">5 Projects</p>
            </div>
            <div className="text-center my-12 border-y flex justify-center items-center border-slate-100 dark:border-slate-600 py-5">
              <p className="text-slate-900 dark:text-slate-100 text-5xl">${togglePricing === 'monthly' ? '59' : '590'}</p>
              {togglePricing === 'yearly' && <p className="text-sm w-fit bg-green-100 dark:bg-green-500 rounded px-3 py-1 ml-5">Save $188</p>}
            </div>
            <ul className="space-y-4">
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>10 Keywords Tracking / project</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>5 Website Audits / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Backlinks Monitor / project</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Priority Email Support</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>10 AI Credits / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>AI Blog Writing <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">soon</span></li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Unlimited Internal Pages Audit</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Mini Tools</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>3 Competitors / project</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>10 Keyword Researches / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Agency Profile <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">soon</span></li>
            </ul>
            <button
              onClick={() => handleSubscribe(plans[1].prices[togglePricing])} // Tree Plan
              className="w-full mt-10 px-5 py-3 rounded bg-green-500 text-white"
              disabled={loading}
              aria-label="Subscribe to Tree Plan"
            >
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
          </div>
        </div>
      
        {/* Additional Content */}
        <div className="flex items-center justify-center mt-10">
          <div className="w-fit flex items-center dark:bg-slate-700 rounded-full bg-slate-200 px-10 py-2">
            <p className="font-medium text-green-500 mr-5 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="mr-2" fill="none">
                {/* SVG Paths */}
              </svg>
              Grow Your Rank, Green the Earth
            </p> 
            Planting a tree with every paid user.
          </div>
        </div>
        
        {/* Custom Plan Prompt */}
        <div className="border rounded-lg p-10 py-16 mt-10 bg-white dark:bg-slate-700 border-slate-200 dark:border-slate-600">
          <div className="flex items-center justify-center text-green-500 mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="none">
              {/* SVG Paths */}
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="none">
              {/* SVG Paths */}
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="none">
              {/* SVG Paths */}
            </svg>
          </div>
          <h3 className="font-medium text-2xl uppercase text-center mb-4">Need a Forest?</h3>
          <p className="text-center text-slate-500 dark:text-gray-300">
            <a href="#">Contact us</a> for a customized plan that fits your agency or enterprise needs.
          </p>
        </div>
        
        {/* FAQ Section */}
        <div className="w-full flex flex-col mt-10">
          <div className="flex flex-col justify-center items-center">
            <h2 className="font-medium text-2xl w-full dark:text-white text-center text-slate-800 mb-4 uppercase">
              Frequently asked questions
            </h2>
          </div>
        </div>
        
        <Accordion items={faqItems} />
      </div>
    </div>
  );
};

export default Subscription;
