import React, { useState, useEffect, useCallback } from 'react';

const GuestPostSitesPage = () => {
  const [guestPostData, setGuestPostData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // Sort configuration
  const resultsPerPage = 20;

  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://www.pixelliongroup.com/seotic/guest_post_categories.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) throw new Error(`API error! status: ${response.status}`);
      
      const data = await response.json();
      setAllCategories(data.categories || []);
    } catch (err) {
      console.error('Failed to fetch categories:', err);
    }
  };

  const fetchGuestPostData = async (page, reset = false) => {
    if (loading || !hasMore) return;

    setLoading(true);
    setError('');
    
    const categories = selectedTags.length > 0 ? selectedTags : null;

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://www.pixelliongroup.com/seotic/guest_post_sites.php?_=${timestamp}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token,
          page,
          pageSize: resultsPerPage,
          categories,
        }),
      });

      if (!response.ok) throw new Error(`API error! status: ${response.status}`);

      const data = await response.json();
      const sortedData = data.data ? sortData(data.data, sortConfig) : [];
	
      if (reset) {
        setGuestPostData(sortedData);
      } else {
        setGuestPostData((prevData) => [...prevData, ...sortedData]);
      }

      setHasMore(data.data && data.data.length === resultsPerPage);
    } catch (err) {
      setError('Failed to load guest post sites. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const sortData = (data, { key, direction }) => {
    if (!key) return data;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const toggleTag = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
    setGuestPostData((data) => sortData(data, { key, direction }));
  };
  const timestamp = new Date().getTime();
  const handleScroll = useCallback(() => {
    if (loading || !hasMore) return;

    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchGuestPostData(nextPage);
    }
  }, [loading, hasMore, currentPage]);

  useEffect(() => {
    fetchCategories();
    fetchGuestPostData(1, true);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    setCurrentPage(1);
    fetchGuestPostData(1, true);
  }, [selectedTags]);
  
  // Function to format traffic numbers
  const formatTraffic = (value) => {
    if (value >= 1_000_000) return (value / 1_000_000).toFixed(1) + 'M';
    if (value >= 100_000) return Math.round(value / 1_000) + 'K';
    return value.toLocaleString(); // Display full number with commas for under 100,000
  };

  return (
    <div className="min-h-screen p-10 dark:text-white mx-auto container">
      <div className="w-full py-10 flex items-center relative">
        <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
          Guest Post Sites
           <div className="text-sm text-slate-500">Top sites for guest posting</div>
        </div>
      </div>

      {/* Category Tags */}
      <div className="bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 pb-5 mt-5">
        <div className="flex flex-wrap gap-1 px-10 pt-10 pb-5 w-full">
      
          
          {allCategories.map((category, index) => (
            <button
              key={index}
              onClick={() => toggleTag(category)}
              className={`px-4 py-2 rounded-full text-sm font-medium mr-2 mb-2 ${
                selectedTags.includes(category)
                  ? 'bg-yellow-500 text-white'
                  : 'bg-gray-200 dark:bg-slate-600 text-slate-800 dark:text-slate-400'
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        {error && <p className="text-red-500 px-10">{error}</p>}

        {/* Data Table */}
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-slate-50 dark:bg-slate-600 text-sm">
              <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">Website Name</th>
              <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">Category</th>
              <th
                className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left cursor-pointer"
                onClick={() => handleSort('domain_authority')}
              >
                DA {sortConfig.key === 'domain_authority' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
              </th>
              <th
                className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left cursor-pointer"
                onClick={() => handleSort('estimated_monthly_traffic')}
              >
                Traffic {sortConfig.key === 'estimated_monthly_traffic' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
              </th>
              <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">Submission Guidelines</th>
              <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">Contact Email</th>
            </tr>
          </thead>
          <tbody>
            {guestPostData.map((site, index) => (
              <tr key={index} className="border-y border-slate-100 dark:border-slate-600 hover:bg-yellow-50 dark:hover:bg-slate-500 text-sm">
                <td className="py-5 px-10"><p className="text-md">{site.website_name}</p><p className="text-sm text-slate-500 dark:text-slate-400">{site.url}</p></td>
                <td className="py-5 px-10">{site.category}</td>
                <td className="py-5 px-10">{site.domain_authority || "-"} </td>
                <td className="py-5 px-10">{formatTraffic(site.estimated_monthly_traffic || "-")}</td>
                <td className="py-5 px-10">
                  <a href={site.submission_guidelines} target="_blank" rel="noopener noreferrer" className="text-yellow-500 underline hover:text-yellow-600">
                    Guidelines
                  </a>
                </td>
                <td className="py-5 px-10">{site.contact_email || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {loading && <p className="text-center p-5">Loading...</p>}
      </div>
    </div>
  );
};

export default GuestPostSitesPage;
