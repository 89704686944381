// src/components/Subscription.js
import React, { useState } from 'react';
import Accordion from './Accordion';

const Subscription = () => {
  const [loading, setLoading] = useState(false);
  const [togglePricing, setTogglePricing] = useState('monthly');
  const [error, setError] = useState(null);

  // Define your plans with corresponding Stripe Price IDs
  const plans = [
    {
      name: 'Sprout',
      prices: {
        monthly: 'price_1QIEXuIwr5n3LbiJKyG1SmoZ', // Replace with your Stripe Price ID for $9/month
        yearly: 'price_1QIEYwIwr5n3LbiJFFAWiJVH', // Replace with your Stripe Price ID for $95/year
      },
    },
    {
      name: 'Tree',
      prices: {
        monthly: 'price_1QIEatIwr5n3LbiJf3cFFrl0', // Replace with your Stripe Price ID for $75/month
        yearly: 'price_1QIEbRIwr5n3LbiJmQI23mRU', // Replace with your Stripe Price ID for $800/year
      },
    },
  ];

  const handleSubscribe = async (priceId) => {
    setLoading(true);
    setError(null);

    try {
      // Retrieve the JWT token from storage (adjust based on your implementation)
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('User is not authenticated.');
      }

      // Make API call to create a checkout session
      const response = await fetch('https://www.pixelliongroup.com/seotic/create_checkout_session.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, price_id: priceId }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        // Redirect to Stripe Checkout
        window.location.href = data.session_url;
      } else {
        throw new Error(data.message || 'Failed to create checkout session.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  const faqItems = [
    {
      question: 'Are there any hidden fees or additional costs?',
      answer: 'No, there are no hidden fees or additional costs.',
    },
    {
      question: 'How is billing handled and what payment methods are accepted?',
      answer: 'We accept major credit cards through Stripe for secure and seamless billing.',
    },
    {
      question: 'How does the tree-planting initiative work?',
      answer: 'For each paid user, Seotic plants a tree with a trusted tree planting organization, one tree planted.',
    },
    {
      question: 'Is the Seed plan really free?',
      answer: 'Yes, the Seed plan is forever free and does not require a credit card.',
    },
    {
      question: 'What if I change my mind?',
      answer: 'You can cancel your plan anytime through your Stripe dashboard. There are no cancellation fees.',
    },
  ];

  return (
    <div className="p-10 mx-auto flex h-full items-center container dark:text-white">
      <div className="w-full py-10 flex flex-col">
        <div className="flex flex-col justify-center items-center mb-8">
          <h2 className="text-4xl w-full dark:text-white text-center text-slate-800 mb-4 uppercase font-medium">
            Select the Best Plan for Your Needs
          </h2>
          <p className="text-slate-500 text-center w-2/3 mb-8">
            Unlock powerful SEO tools, competitor insights, and more to help your website succeed. Choose the perfect plan and grow your online presence.
          </p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded mb-5">
            {error}
          </div>
        )}

        {/* Pricing Toggle */}
        <div className="flex justify-center mb-8 text-sm relative">
          <button
            onClick={() => setTogglePricing('monthly')}
            className={`px-4 py-2 rounded-l-lg ${
              togglePricing === 'monthly'
                ? 'bg-yellow-500 text-white'
                : 'bg-slate-200 dark:bg-slate-600 text-slate-700 dark:text-gray-200'
            }`}
            aria-pressed={togglePricing === 'monthly'}
            aria-label="Toggle to monthly pricing"
          >
            Monthly
          </button>
          <button
            onClick={() => setTogglePricing('yearly')}
            className={`px-4 py-2 rounded-r-lg ${
              togglePricing === 'yearly'
                ? 'bg-yellow-500 text-white'
                : 'bg-slate-200 dark:bg-slate-600 text-slate-700 dark:text-gray-200'
            }`}
            aria-pressed={togglePricing === 'yearly'}
            aria-label="Toggle to yearly pricing"
          >
            Yearly 
          </button>
        </div>

        {/* Pricing Grid */}
        <div className="grid gap-5 md:grid-cols-3">
          {/* Seed Plan */}
          <div className="border rounded-lg my-5 p-10 py-16 bg-white dark:bg-slate-700 border-slate-200 dark:border-slate-600">
            <h3 className="font-medium text-3xl uppercase text-center mb-5 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" className="mr-2" fill="none">
               <path d="M17 19.5034C17 18.2482 18.0532 17.0077 18.7924 16.2917C19.1939 15.9028 19.8061 15.9028 20.2076 16.2917C20.9468 17.0077 22 18.2482 22 19.5034C22 20.7341 21.0533 22 19.5 22C17.9467 22 17 20.7341 17 19.5034Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M21.9999 12C17.8993 12 14.3751 14.4682 12.832 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M21.9984 8C15.6563 8 10.2992 12.217 8.57812 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M21.9993 4C13.4329 4 6.26423 9.98405 4.44531 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M2 10C2.87815 10 3.72986 10.1132 4.54134 10.3258" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M2 6C3.86605 6 5.64683 6.36509 7.27481 7.02772" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M2 2C5.17533 2 8.15863 2.82221 10.7486 4.26534" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              </svg>
              Seed
            </h3>
            <div className="mx-auto w-fit">
              <p className="mb-4 bg-slate-500 text-white rounded px-3 py-1">1 Project</p>
            </div>
            <div className="text-center my-12 border-y border-slate-100 dark:border-slate-600 py-5">
              <p className="text-slate-900 dark:text-slate-100 text-4xl">Free</p>
            </div>
            <ul className="space-y-4">
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>2 Keywords Tracking / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Website Audit / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Backlinks Monitor / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Email Support</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 AI Credit / day</li>
               <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>AI Blog Writing <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">new</span></li>
               <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>AI Code Suggestion <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">new</span></li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>5 Internal Pages Audit</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Mini Tools</li>
              <li><i className="text-red-500 bx bx-x-circle mr-2 bx-fw"></i>No Competitor Analysis</li>
              <li><i className="text-red-500 bx bx-x-circle mr-2 bx-fw"></i>No Keyword Research</li>
                <li><i className="text-red-500 bx bx-x-circle mr-2 bx-fw"></i>Professional Profile</li>
            </ul>
          </div>

          {/* Sprout Plan */}
          <div className="border rounded-lg shadow-lg p-10 py-16 bg-white dark:bg-slate-700 border-yellow-200 dark:border-yellow-500 relative">
            <div className="absolute top-0 left-0 rounded-t-lg w-full bg-yellow-200 dark:bg-yellow-500 text-yellow-900 dark:text-white px-4 py-1 font-semibold text-center">
              Recommended
            </div>
            <h3 className="font-medium text-3xl uppercase text-center mt-5 mb-5 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" className="mr-2" fill="none">
               <path d="M18 10C18 10 12 14 12 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.34882 11.1825C7.73784 12.3891 5.44323 12.26 3.9785 10.7953C1.55484 8.37164 2.03957 3.03957 2.03957 3.03957C2.03957 3.03957 7.37164 2.55484 9.7953 4.9785C10.7548 5.93803 11.1412 7.25369 10.9543 8.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.9638 12.8175C13.644 11.3832 13.6797 9.14983 15.0708 7.75867C17.2252 5.6043 21.9648 6.03517 21.9648 6.03517C21.9648 6.03517 22.3957 10.7748 20.2413 12.9292C19.4877 13.6828 18.487 14.0386 17.5 13.9967" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6 7C6 7 12 12 12 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Sprout
            </h3>
            <div className="mx-auto w-fit">
              <p className="mb-4 bg-yellow-500 text-white rounded px-3 py-1">1 Project</p>
            </div>
            <div className="text-center my-12 border-y flex justify-center items-center border-slate-100 dark:border-slate-600 py-5">
              <p className="text-slate-900 dark:text-slate-100 text-5xl">${togglePricing === 'monthly' ? '9' : '90'}</p>
              {togglePricing === 'yearly' && <p className="text-sm w-fit bg-green-100 dark:bg-green-500 rounded px-3 py-1 ml-5">Save $18</p>}
            </div>
           <ul className="space-y-4">
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>10 Keywords Tracking / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Website Audit / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Backlinks Monitor / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Priority Email Support</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 AI Credit / day</li>
                <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>AI Blog Writing <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">new</span></li>
               <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>AI Code Suggestion <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">new</span></li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Unlimited Internal Pages Audit</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Mini Tools</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>3 Competitors</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>5 Keyword Research / day</li>
               <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Professional Profile <span className="text-sm w-fit bg-yellow-500 text-white rounded px-3 py-1 ml-5">soon</span></li>

            </ul>               <button
              onClick={() => handleSubscribe(plans[0].prices[togglePricing])} // Sprout Plan
              className="w-full mt-10 px-5 py-3 rounded bg-yellow-500 text-white"
              disabled={loading}
              aria-label="Subscribe to Sprout Plan"
            >
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
          </div>

          {/* Tree Plan */}
          <div className="border rounded-lg p-10 py-16 my-5 bg-white dark:bg-slate-700 border-slate-200 dark:border-slate-600">
            <h3 className="font-medium text-3xl uppercase text-center mb-5 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" className="mr-2" fill="none">
                <path d="M12 22V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 17.4185C6.06817 16.2317 4 13.3574 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10C20 13.3574 17.9318 16.2317 15 17.4185" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M12 15L14.5 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 13L9.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 22H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              Tree
            </h3>
            <div className="mx-auto w-fit">
              <p className="mb-4 bg-green-500 text-white rounded px-3 py-1">5 Projects</p>
            </div>
            <div className="text-center my-12 border-y flex justify-center items-center border-slate-100 dark:border-slate-600 py-5">
              <p className="text-slate-900 dark:text-slate-100 text-5xl">${togglePricing === 'monthly' ? '59' : '590'}</p>
              {togglePricing === 'yearly' && <p className="text-sm w-fit bg-green-100 dark:bg-green-500 rounded px-3 py-1 ml-5">Save $188</p>}
            </div>
          <ul className="space-y-4">
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>10 Keywords Tracking / project</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>5 Website Audits / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>1 Backlinks Monitor / project</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Priority Email Support</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>10 AI Credits / day</li>
               <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>AI Blog Writing <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">new</span></li>
               <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>AI Code Suggestion <span className="text-sm w-fit bg-green-500 text-white rounded px-3 py-1 ml-5">new</span></li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Unlimited Internal Pages Audit</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Mini Tools</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>3 Competitors / project</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>10 Keyword Researches / day</li>
              <li><i className="text-green-500 bx bxs-check-circle mr-2 bx-fw"></i>Agency Profile <span className="text-sm w-fit bg-yellow-500 text-white rounded px-3 py-1 ml-5">soon</span></li>
            </ul>
            <button
              onClick={() => handleSubscribe(plans[1].prices[togglePricing])} // Tree Plan
              className="w-full mt-10 px-5 py-3 rounded bg-green-500 text-white"
              disabled={loading}
              aria-label="Subscribe to Tree Plan"
            >
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
          </div>
        </div>
      
        {/* Additional Content */}
        <div className="flex items-center justify-center mt-10">
          <div className="w-fit flex items-center dark:bg-slate-700 rounded-full bg-slate-200 px-10 py-2">
            <p className="font-medium text-green-500 mr-5 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="mr-2" fill="none">
                 <path d="M2 3C3.86377 3 4.79565 3 5.53073 3.30448C6.51085 3.71046 7.28954 4.48915 7.69552 5.46927C8 6.20435 8 7.13623 8 9C6.13623 9 5.20435 9 4.46927 8.69552C3.48915 8.28954 2.71046 7.51085 2.30448 6.53073C2 5.79565 2 4.86377 2 3Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M12 5C10.7575 5 10.1362 5 9.64618 5.20299C8.99277 5.47364 8.47364 5.99277 8.20299 6.64618C8 7.13623 8 7.75749 8 9C9.24251 9 9.86377 9 10.3538 8.79701C11.0072 8.52636 11.5264 8.00723 11.797 7.35382C12 6.86377 12 6.24251 12 5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M8 9V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M12 14L2 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M12 17L2 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M12 20L2 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M16 18.5034C16 17.2482 17.0532 16.0077 17.7924 15.2917C18.1939 14.9028 18.8061 14.9028 19.2076 15.2917C19.9468 16.0077 21 17.2482 21 18.5034C21 19.7341 20.0533 21 18.5 21C16.9467 21 16 19.7341 16 18.5034Z" stroke="currentColor" stroke-width="1.5" />
              </svg>
              Grow Your Rank, Green the Earth
            </p> 
            Planting a tree with every paid user.
          </div>
        </div>
        
        {/* Custom Plan Prompt */}
        <div className="border rounded-lg p-10 py-16 mt-10 bg-white dark:bg-slate-700 border-slate-200 dark:border-slate-600">
          <div className="flex items-center justify-center text-green-500 mb-2">
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="none">
    <path d="M12 22V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.00195 18.002C7.3451 18.002 6.00195 16.6234 6.00195 14.9665C4.30581 14.7239 3.00195 13.2652 3.00195 11.502C3.00195 10.0052 3.94147 8.7279 5.26293 8.22759C5.09522 7.85339 5.00195 7.43856 5.00195 7.00195C5.00195 5.3451 6.3451 4.00195 8.00195 4.00195C8.3922 4.00195 8.76505 4.07647 9.10703 4.21204C9.45374 2.93842 10.6185 2.00195 12.002 2.00195C13.3854 2.00195 14.5502 2.93842 14.8969 4.21204C15.2389 4.07647 15.6117 4.00195 16.002 4.00195C17.6588 4.00195 19.002 5.3451 19.002 7.00195C19.002 7.43856 18.9087 7.85339 18.741 8.22759C20.0624 8.7279 21.002 10.0052 21.002 11.502C21.002 13.2653 19.698 14.724 18.0017 14.9665C18.0017 16.6234 16.6588 18.002 15.002 18.002" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 15L14.5 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 13L9.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 22H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="none">
    <path d="M12 22V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 17.4185C6.06817 16.2317 4 13.3574 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10C20 13.3574 17.9318 16.2317 15 17.4185" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M12 15L14.5 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 13L9.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 22H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="none">
    <path d="M12 22V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.00195 18.002C7.3451 18.002 6.00195 16.6234 6.00195 14.9665C4.30581 14.7239 3.00195 13.2652 3.00195 11.502C3.00195 10.0052 3.94147 8.7279 5.26293 8.22759C5.09522 7.85339 5.00195 7.43856 5.00195 7.00195C5.00195 5.3451 6.3451 4.00195 8.00195 4.00195C8.3922 4.00195 8.76505 4.07647 9.10703 4.21204C9.45374 2.93842 10.6185 2.00195 12.002 2.00195C13.3854 2.00195 14.5502 2.93842 14.8969 4.21204C15.2389 4.07647 15.6117 4.00195 16.002 4.00195C17.6588 4.00195 19.002 5.3451 19.002 7.00195C19.002 7.43856 18.9087 7.85339 18.741 8.22759C20.0624 8.7279 21.002 10.0052 21.002 11.502C21.002 13.2653 19.698 14.724 18.0017 14.9665C18.0017 16.6234 16.6588 18.002 15.002 18.002" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 15L14.5 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 13L9.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 22H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
          </div>
          <h3 className="font-medium text-2xl uppercase text-center mb-4">Need a Forest?</h3>
          <p className="text-center text-slate-500 dark:text-gray-300">
            <a href="#">Contact us</a> for a customized plan that fits your agency or enterprise needs.
          </p>
        </div>
        
        {/* FAQ Section */}
        <div className="w-full flex flex-col mt-10">
          <div className="flex flex-col justify-center items-center">
            <h2 className="font-medium text-2xl w-full dark:text-white text-center text-slate-800 mb-4 uppercase">
              Frequently asked questions
            </h2>
          </div>
        </div>
        
        <Accordion items={faqItems} />
      </div>
    </div>
  );
};

export default Subscription;
