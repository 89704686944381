import React, { useEffect, useState } from 'react';

const Tooltip = ({ forid, message, position = 'top' }) => {
  const [visible, setVisible] = useState(false);
  const [positionStyles, setPositionStyles] = useState({});

  useEffect(() => {
    // Get the element to which the tooltip is linked using the id
    const element = document.getElementById(forid);

    if (element) {
      // Define hover event listeners
      const showTooltip = () => setVisible(true);
      const hideTooltip = () => setVisible(false);

      // Attach event listeners to the target element
      element.addEventListener('mouseenter', showTooltip);
      element.addEventListener('mouseleave', hideTooltip);

      // Position the tooltip based on the element's position
      const rect = element.getBoundingClientRect();
      const tooltipStyles = getPositionStyles(rect, position);
      setPositionStyles(tooltipStyles);

      // Cleanup event listeners on component unmount
      return () => {
        element.removeEventListener('mouseenter', showTooltip);
        element.removeEventListener('mouseleave', hideTooltip);
      };
    }
  }, [forid, position]);

  // Function to get the position styles based on position prop and element's bounding box
  const getPositionStyles = (rect, position) => {
    switch (position) {
      case 'top':
        return { top: rect.top - 10, left: rect.left + rect.width / 2 };
      case 'bottom':
        return { top: rect.bottom + 10, left: rect.left + rect.width / 2 };
      case 'left':
        return { top: rect.top + rect.height / 2, left: rect.left - 10 };
      case 'right':
        return { top: rect.top + rect.height / 2, left: rect.right + 10 };
      default:
        return {};
    }
  };

  return visible ? (
	  <div className="relative">
    <div
      className={`relative text-sm flex justify-center items-end absolute z-50 p-2 px-5 text-left text-white font-normal bg-yellow-500 max-w-64 rounded-lg shadow-lg transform -translate-x-1/2 ${
        position === 'top' || position === 'bottom' ? '-translate-y-full' : ''
      }`}
      style={{ position: 'fixed', ...positionStyles }}
    >
      {message}
      <div className="absolute bg-yellow-500 h-3 w-3 rotate-45 rounded-sm -mb-3 z-49"></div>
    </div>
    
    </div>
  ) : null;
};

export default Tooltip;
