// src/components/SuggestionsSection.js

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';

const SuggestionsSection = ({ suggestionsObj }) => {
  if (!suggestionsObj || Object.keys(suggestionsObj).length === 0) {
    return (
      <div className="col-span-2 bg-white rounded-lg border border-slate-200 px-10 pt-10 pb-5">
        <div className="grid grid-cols-5 gap-5">
          <h4 className="col-span-1 text-xl mb-2">
            <i className="bx bx-band-aid bx-fw"></i>Suggestions
          </h4>
          <div className="col-span-4">
            <p className="text-slate-500 mb-5">No suggestions available.</p>
          </div>
        </div>
      </div>
    );
  }

  const { overall_suggestion, audit_statistics, suggestions } = suggestionsObj;

  return (
    <div className="col-span-2 bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 dark:text-white rounded-lg border px-10 pt-10 pb-5">
      <div className="flex items-center mb-5">
        <h4 className="w-full text-2xl flex items-center">
          <svg
            className="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            fill={"none"}
          >
            {/* SVG Paths */}
            <path
              d="M19 9.62069C19 12.1999 17.7302 14.1852 15.7983 15.4917C15.3483 15.796 15.1233 15.9482 15.0122 16.1212C14.9012 16.2942 14.8633 16.5214 14.7876 16.9757L14.7287 17.3288C14.5957 18.127 14.5292 18.526 14.2494 18.763C13.9697 19 13.5651 19 12.7559 19H10.1444C9.33528 19 8.93069 19 8.65095 18.763C8.3712 18.526 8.30469 18.127 8.17166 17.3288L8.11281 16.9757C8.03734 16.5229 7.99961 16.2965 7.88968 16.1243C7.77976 15.9521 7.55428 15.798 7.10332 15.4897C5.1919 14.1832 4 12.1986 4 9.62069C4 5.4119 7.35786 2 11.5 2C12.0137 2 12.5153 2.05248 13 2.15244"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 2L16.7579 2.69703C17.0961 3.61102 17.2652 4.06802 17.5986 4.40139C17.932 4.73477 18.389 4.90387 19.303 5.24208L20 5.5L19.303 5.75792C18.389 6.09613 17.932 6.26524 17.5986 6.59861C17.2652 6.93198 17.0961 7.38898 16.7579 8.30297L16.5 9V8.30297C15.9039 7.38898 15.7348 6.93198 15.4014 6.59861C15.068 6.26524 14.611 6.09613 13.697 5.75792L13 5.5L13.697 5.24208C14.611 4.90387 15.068 4.73477 15.4014 4.40139C15.7348 4.06802 15.9039 3.61102 16.2421 2.69703L16.5 2Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 19V20C13.5 20.9428 13.5 21.4142 13.2071 21.7071C12.9142 22 12.4428 22 11.5 22C10.5572 22 10.0858 22 9.79289 21.7071C9.5 21.4142 9.5 20.9428 9.5 20V19"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
          Suggestions
        </h4>

        <div className="flex items-center justify-end w-full text-slate-500 dark:text-slate-400">
          Fix these{' '}
          <span className="inline mx-1 px-3 py-1 text-sm rounded-full bg-yellow-500 text-white">
            {suggestions.length}
          </span>{' '}
          issues.
          <Link to="/audit" className="text-xs ml-5 hover:text-yellow-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={22}
              height={22}
              fill={"none"}
            >
              {/* SVG Paths */}
              <path
                d="M19.4 19.4L22 22M20.7 14.85C20.7 11.6191 18.0809 9 14.85 9C11.6191 9 9 11.6191 9 14.85C9 18.0809 11.6191 20.7 14.85 20.7C18.0809 20.7 20.7 18.0809 20.7 14.85Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.075 19.075L22 22M12.9 14.85H14.85M14.85 14.85H16.8M14.85 14.85V12.9M14.85 14.85V16.8M20.7 14.85C20.7 11.6191 18.0809 9 14.85 9C11.6191 9 9 11.6191 9 14.85C9 18.0809 11.6191 20.7 14.85 20.7C18.0809 20.7 20.7 18.0809 20.7 14.85Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 6C2.1305 4.6645 2.4262 3.7663 3.09625 3.09625C3.7663 2.4262 4.6645 2.1305 6 2M6 22C4.6645 21.8695 3.7663 21.5738 3.09625 20.9037C2.4262 20.2337 2.1305 19.3355 2 18M22 6C21.8695 4.6645 21.5738 3.7663 20.9037 3.09625C20.2337 2.4262 19.3355 2.1305 18 2M2 10L2 14M14 2L10 2"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </Link>
        </div>
      </div>

      {/* Suggestions Carousel */}
      <div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          style={{ '--swiper-pagination-color': '#64748b' }}
        >
          {suggestionsObj.suggestions.map((suggestion, index) => (
            <SwiperSlide key={index}>
              <div className="rounded-lg mb-12 px-5 m-2 py-5 dark:bg-slate-600 bg-slate-50">
                <div className="flex items-center mb-2">
                  <p className="text-2xl text-yellow-500 mr-2">{suggestion.category}</p>

                  <p
                    className={`text-slate-500 text-xs mt-2 px-3 py-1 rounded-lg w-fit uppercase ${
                      suggestion.difficulty.toLowerCase() === 'low'
                        ? 'bg-blue-50'
                        : suggestion.difficulty.toLowerCase() === 'medium'
                        ? 'bg-yellow-50'
                        : 'bg-red-50'
                    }`}
                  >
                    Priority : {suggestion.difficulty}
                  </p>
                </div>

                <p>{suggestion.suggestion}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

SuggestionsSection.propTypes = {
  suggestionsObj: PropTypes.shape({
    overall_suggestion: PropTypes.string,
    audit_statistics: PropTypes.object,
    suggestions: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        difficulty: PropTypes.string,
        suggestion: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default SuggestionsSection;
