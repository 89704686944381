// src/Onboard.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import LoadingScreen from './components/LoadingScreen';

// Import styles and icons
import 'boxicons/css/boxicons.min.css';

const Onboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [projectUrl, setProjectUrl] = useState('');
  const [websiteData, setWebsiteData] = useState(null);
  const [error, setError] = useState('');
const timestamp = new Date().getTime();

  // Fetch necessary data
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const projectData = JSON.parse(localStorage.getItem('project'));
      const website_id = projectData ? projectData.id : null;
      const projectUrl = projectData ? projectData.url : null;

      if (!token) {
        setError('Authorization token not found.');
        setIsLoading(false);
        return;
      }

      if (!projectData || !website_id) {
        setError('No project selected. Please choose a project.');
        setIsLoading(false);
        return;
      }

      setProjectUrl(projectUrl);

      try {
        const response = await fetch(`https://www.pixelliongroup.com/seotic/dashboard.php?_=${timestamp}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token, website_id }),
        });

        const data = await response.json();

        if (response.ok && data.success) {
          setWebsiteData(data.data.websites[0]); // Assuming you're interested in the first website
          setError('');
        } else {
          setError(data.message || 'Failed to fetch data.');
        }
      } catch (err) {
        console.error('Fetch Error:', err);
        setError('An error occurred while fetching data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to determine if a task is completed
  const isTaskCompleted = (task) => {
    if (!websiteData) return false;

    switch (task) {
      case 'websiteAudit':
        return websiteData.audit?.audit_date !== null;
      case 'rankTracking':
        return websiteData.keywords?.last_update_date !== null;
      case 'backlinksAudit':
        return websiteData.backlinks?.last_update_date !== null;
      default:
        return false;
    }
  };

  // Function to get the task completion percentage
  const getCompletionPercentage = () => {
    const totalTasks = 3;
    let completedTasks = 0;

    if (isTaskCompleted('websiteAudit')) completedTasks += 1;
    if (isTaskCompleted('rankTracking')) completedTasks += 1;
    if (isTaskCompleted('backlinksAudit')) completedTasks += 1;

    return (completedTasks / totalTasks) * 100;
  };

  return (
    <>
      {/* Loading Screen */}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="flex justify-center items-center dark:bg-slate-800 bg-slate-100 p-10 min-h-screen">
          {/* Error Message */}
          {error && (
            <div className="bg-red-100 text-red-700 p-4 mb-5 rounded w-full max-w-2xl">
              {error}
            </div>
          )}

          {/* Onboarding Content */}
          <div className="w-full max-w-xl bg-white dark:bg-slate-700 rounded-lg border-slate-200 dark:border-slate-600 border dark:text-white p-10">
            {/* Header Section */}
            <div className="w-full py-5 flex items-center">
              <div className="w-full text-3xl text-left uppercase">
                Before
                <div className="text-sm text-slate-500 dark:text-slate-400">
                  <i className="bx bx-folder bx-fw"></i> Project /{' '}
                  {projectUrl ? new URL(projectUrl).hostname.replace(/^(www\.)?/, '') : 'N/A'}
                </div>
              </div>
            </div>

            {/* Description */}
            <p className="text-sm text-slate-500 dark:text-slate-400 mb-5">
              You must run these 3 tasks for a complete dashboard experience.
            </p>

            {/* Progress Bar */}
            <div className="w-full bg-slate-200 dark:bg-slate-600 rounded-full h-4 mb-6">
              <div
                className="bg-yellow-500 h-4 rounded-full"
                style={{ width: `${getCompletionPercentage()}%` }}
              ></div>
            </div>

            {/* Checklist */}
            <div className="space-y-4">
              {/* Website Audit */}
              <div className="flex items-center">
                {isTaskCompleted('websiteAudit') ? (
                  <i className="bx bxs-check-circle text-green-500 text-2xl mr-3"></i>
                ) : (
                  <i className="bx bx-circle text-slate-300 text-2xl mr-3"></i>
                )}
                <span className="flex-1 text-slate-800 dark:text-slate-200 text-lg">Website Audit</span>
                {isTaskCompleted('websiteAudit') ? (
                  <span className="text-white bg-green-500 text-sm rounded-lg px-3 py-1">Completed</span>
                ) : (
                  <Link to="/audit" className="text-white bg-yellow-500 hover:bg-yellow-400 text-sm rounded-lg px-4 py-1">
                    <i className="bx bxs-right-arrow bx-fw"></i>Run
                  </Link>
                )}
              </div>

              {/* Rank Tracking */}
              <div className="flex items-center">
                {isTaskCompleted('rankTracking') ? (
                  <i className="bx bxs-check-circle text-green-500 text-2xl mr-3"></i>
                ) : (
                  <i className="bx bx-circle text-slate-300 text-2xl mr-3"></i>
                )}
                <span className="flex-1 text-slate-800 dark:text-slate-200 text-lg">Rank Tracking</span>
                {isTaskCompleted('rankTracking') ? (
                  <span className="text-white bg-green-500 text-sm rounded-lg px-3 py-1">Completed</span>
                ) : (
                  <Link to="/serp" className="text-white bg-yellow-500 hover:bg-yellow-400 text-sm rounded-lg px-4 py-1">
                    <i className="bx bxs-right-arrow bx-fw"></i>Run
                  </Link>
                )}
              </div>

              {/* Backlinks Audit */}
              <div className="flex items-center">
                {isTaskCompleted('backlinksAudit') ? (
                  <i className="bx bxs-check-circle text-green-500 text-2xl mr-3"></i>
                ) : (
                  <i className="bx bx-circle text-slate-300 text-2xl mr-3"></i>
                )}
                <span className="flex-1 text-slate-800 dark:text-slate-200 text-lg">Backlinks Audit (Optional)</span>
                {isTaskCompleted('backlinksAudit') ? (
                  <span className="text-white bg-green-500 text-sm rounded-lg px-3 py-1">Completed</span>
                ) : (
                  <Link to="/backlinks" className="text-white bg-yellow-500 hover:bg-yellow-400 text-sm rounded-lg px-4 py-1">
                      <i className="bx bxs-right-arrow bx-fw"></i>Run
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Onboard;
