import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSearchParams } from 'react-router-dom';

const WriterPage = () => {
  const [subscription, setSubscription] = useState(null);
  const [title, setTitle] = useState('');
  const [brief, setBrief] = useState('');
  const [keywords, setKeywords] = useState('');
  const [tone, setTone] = useState('Friendly');
  const [blogContent, setBlogContent] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [usageCount, setUsageCount] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [searchParams] = useSearchParams(); // Get URL parameters

  const maxTitlesPerDay = subscription === 'Premium' ? 5 : 1;

  useEffect(() => {
    const fetchUserSubscriptionAndUsage = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token is missing.');
        setIsFetching(false);
        return;
      }

      try {
        const response = await fetch('https://www.pixelliongroup.com/seotic/get_user_usage.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUsageCount(data.blog_titles_generated_per_day || 0);
        setSubscription(data.plan_type);
      } catch (error) {
        setError('Failed to fetch user usage and subscription.');
      } finally {
        setIsFetching(false);
      }
    };

    fetchUserSubscriptionAndUsage();
  }, []);

  // Set title from URL parameter on component mount
  useEffect(() => {
    const urlTitle = searchParams.get('title'); // Get 'title' parameter
    if (urlTitle) {
      setTitle(decodeURIComponent(urlTitle)); // Decode if special characters are used
    }
  }, [searchParams]);

  const canGenerateMoreTitles = () =>
    subscription === 'Premium' ? usageCount < 5 : usageCount < 1;

  const updateUsage = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await fetch('https://www.pixelliongroup.com/seotic/update_blog_usage.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) throw new Error('Failed to update usage');
      setUsageCount((prev) => prev + 1);
    } catch (error) {
      console.error('Error updating blog usage:', error);
    }
  };

  const handleGenerateBlog = async (e) => {
    e.preventDefault();
    if (!canGenerateMoreTitles()) {
      setError('You have reached your blog title generation limit for today.');
      return;
    }

    if (!title.trim()) {
      setError('Title cannot be empty.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await fetch(
        'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=AIzaSyCRjiPzPSu72KGnzMzvTBT95LKTjHVKn_I',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contents: [
              {
                parts: [
                  {
                    text: `Write a blog post about "${title}" with the following brief: "${brief}". Include keywords "${keywords}". Use a "${tone}" tone of voice. around 800 to 1000 words. Add keyphase, meta description.`,
                  },
                ],
              },
            ],
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.candidates && data.candidates[0] && data.candidates[0].content.parts) {
        const generatedContent = data.candidates[0].content.parts.map(part => part.text).join('\n\n');
        setBlogContent(generatedContent);

        // Update usage after successful generation
        await updateUsage();
      } else {
        setError('Invalid response structure from API.');
      }
    } catch (error) {
      setError('Failed to generate blog post.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen p-10 mx-auto container">
       <div className="py-10 w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
              Content Generator
              <div className="text-sm text-slate-500 mt-2">
                <i className="bx bx-folder bx-fw"></i> Blog writing AI 
              </div>
            </div>

      <div className="flex space-x-6">
        
        {/* Left Column */}
        <div className="w-1/2 bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 p-10 relative">
      
          <form onSubmit={handleGenerateBlog} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-slate-500 dark:text-slate-400 mb-2">Blog Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full p-3 border border-slate-100 text-black dark:text-white dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                placeholder="Enter your blog title"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-slate-500 dark:text-slate-400 mb-2">Brief</label>
              <textarea
                value={brief}
                onChange={(e) => setBrief(e.target.value)}
                className="w-full p-3 border border-slate-100 text-black dark:text-white dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                rows="3"
                placeholder="Provide a brief description for the blog"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-slate-500 dark:text-slate-400 mb-2">Keywords</label>
              <input
                type="text"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                className="w-full p-3 border border-slate-100 text-black dark:text-white dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                placeholder="Enter keywords (comma-separated)"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-slate-500 dark:text-slate-400 mb-2">Tone of Voice</label>
              <div className="p-3 border border-slate-100 text-black dark:text-white dark:bg-slate-600 dark:border-slate-500 rounded-lg ">
              <select
                value={tone}
                onChange={(e) => setTone(e.target.value)}
                className="w-full focus:outline-none dark:bg-slate-600"
              >
                <option value="Friendly">Friendly</option>
                <option value="Professional">Professional</option>
                <option value="Casual">Casual</option>
                <option value="Serious">Serious</option>
              </select>
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-yellow-500 text-white py-3 rounded-lg hover:bg-yellow-600"
              disabled={loading || isFetching || !canGenerateMoreTitles()}
            >
              {loading ? 'Generating...' : 'Generate Blog'}
            </button>
            <p className="text-sm text-slate-500 dark:text-slate-400 mt-2">
              Your blog writing AI usage: {usageCount} / {maxTitlesPerDay}
            </p>
          </form>
        </div>

        {/* Right Column */}
        <div className="w-1/2 bg-gray-50 rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 p-10 relative">
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {blogContent ? (
            <ReactMarkdown className="prose text-black dark:text-white">{blogContent}</ReactMarkdown>
          ) : (
            <p className="text-slate-500 dark:text-slate-400">Your generated blog will appear here...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default WriterPage;
