// src/layouts/PrivateLayout.js
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar'; // Assume you have a Navbar component
import NoMobile from '../pages/NoMobile'; // Import your NoMobile component

const PrivateLayout = () => {
  const navigate = useNavigate();

  // Function to detect if the device is mobile
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Regular expression to match mobile devices
    return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  };

  useEffect(() => {
    if (isMobileDevice()) {
      // Redirect to the NoMobile route
      navigate('/sorry', { replace: true });
    }
  }, [navigate]);

  return (
    <div className="flex dark:bg-slate-800 bg-slate-100 ">
      <div className="fixed">
        <Navbar />
      </div>
      <div className="w-full dark:bg-slate-800 bg-slate-100 overflow-y-auto ml-20 ">
        <Outlet /> {/* Renders the nested route (UserPage, WebsiteAudit, etc.) */}
      </div>
    </div>
  );
};

export default PrivateLayout;
