import React, { useState, useEffect } from 'react';

// Helper function to calculate the relative time
const timeAgo = (dateString) => {
  const now = new Date();
  const parsedDate = new Date(dateString);
  const diffInSeconds = Math.floor((now - parsedDate) / 1000);

  const intervals = [
    { unit: 'year', value: 31536000 },
    { unit: 'month', value: 2592000 },
    { unit: 'week', value: 604800 },
    { unit: 'day', value: 86400 },
    { unit: 'hour', value: 3600 },
    { unit: 'minute', value: 60 },
    { unit: 'second', value: 1 },
  ];

  const absoluteDiff = Math.abs(diffInSeconds);

  for (const interval of intervals) {
    const intervalCount = Math.floor(absoluteDiff / interval.value);
    if (intervalCount >= 1) {
      const time = diffInSeconds > 0 ? -intervalCount : intervalCount;
      return new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(time, interval.unit);
    }
  }

  return 'just now';
};

// TimeAgo component
const TimeAgo = ({ date }) => {
  const [relativeTime, setRelativeTime] = useState(timeAgo(date));

  useEffect(() => {
    // Update the relative time every 60 seconds (or as needed)
    const intervalId = setInterval(() => {
      setRelativeTime(timeAgo(date));
    }, 60000); // Update every 60 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [date]);

  return <span>{relativeTime}</span>;
};

export default TimeAgo;
