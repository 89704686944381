import React, { useState, useEffect } from 'react';
import LoadingScreen from './components/LoadingScreen';
import TimeAgo from './components/TimeAgo';
import Tooltip from './components/Tooltip';

const AIReport = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState('');
    const [MainUrl, setMainUrl] = useState('');
    const [auditReport, setAuditReport] = useState(null);
    const [error, setError] = useState('');
    const [project, setProject] = useState(null);
    const [auditDate, setAuditDate] = useState(null);
    const [aiUsageToday, setAiUsageToday] = useState(0);
    const [aiLimit, setAiLimit] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const timestamp = new Date().getTime();
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };

    useEffect(() => {
        // Simulate a delay for demonstration (e.g., fetching data)
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const projectData = JSON.parse(localStorage.getItem('project'));
        if (projectData && projectData.url) {
            const domain = new URL(projectData.url).hostname;
            setUrl(domain);
            setMainUrl(projectData.url);
            setProject(projectData);
        } else {
            setError('No project selected. Please select a project to audit.');
        }
        fetchAiUsageToday();
        fetchUserPlan();
    }, []);

    const fetchUserPlan = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('You need to log in to use this feature.');
            return;
        }

        try {
            const response = await fetch(`https://www.pixelliongroup.com/seotic/get_user_plan.php?_=${timestamp}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token }),
            });

            const data = await response.json();
            if (response.ok && data.status === 'success') {
                setAiLimit(data.plan.ai_limit);
            } else {
                throw new Error(data.message || 'Failed to fetch user plan.');
            }
        } catch (error) {
            console.error('Error fetching user plan:', error);
        }
    };

    const fetchAiUsageToday = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('You need to log in to use this feature.');
            return;
        }

        try {
            const response = await fetch(`https://www.pixelliongroup.com/seotic/get_ai_usage.php?_=${timestamp}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token }),
            });

            const data = await response.json();
            if (response.ok && data.status) {
                setAiUsageToday(data.ai_usage_per_day);
            } else {
                setAiUsageToday(0);
            }
        } catch (error) {
            console.error('Error fetching AI usage:', error);
            setAiUsageToday(0);
        }
    };

    const fetchAuditReport = async () => {
      if (typeof aiLimit === 'number' && aiUsageToday >= aiLimit) {
            setError('You have reached your daily limit for AI suggestions.');
              setIsModalOpen(true);
            return;
        }

        setLoading(true);
        setError('');

        try {
            const projectData = JSON.parse(localStorage.getItem('project'));
            const url = projectData ? projectData.url : null;

            const response = await fetch('https://www.pixelliongroup.com/seotic/googleAI.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url }),
            });

            const data = await response.json();
            if (data.success && data.data && data.data.report) {
                setAuditReport(data.data.report);
                setAuditDate(new Date().toISOString());
                updateAiUsage();
            } else {
                throw new Error(data.error || 'Invalid response data.');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const updateAiUsage = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('You need to log in to use this feature.');
            return;
        }

        try {
            const response = await fetch(`https://www.pixelliongroup.com/seotic/update_ai_usage.php?_=${timestamp}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token }),
            });

            const data = await response.json();
            if (response.ok && data.status) {
                setAiUsageToday((prevUsage) => prevUsage + 1);
            } else {
                console.error('Error updating AI usage:', data.message);
            }
        } catch (error) {
            console.error('Error updating AI usage:', error);
        }
    };

    const canRecrawl = () => {
        if (!auditDate) return true;
        const lastAuditTime = new Date(auditDate).getTime();
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - lastAuditTime;
        return timeDifference > 24 * 60 * 60 * 1000;
    };

    const renderAuditReport = (report) => {
        if (!report || !Array.isArray(report)) {
            return <p className="text-red-500">Invalid or missing audit report data.</p>;
        }

        return (
            <div className="space-y-6">
                {report.length === 0 ? (
                    <p className="text-slate-500 dark:text-slate-400">No issues found.</p>
                ) : (
                    report.map((item, index) => (
                        <div key={index} className="bg-white dark:bg-slate-700 border border-slate-100 dark:border-slate-600 rounded-lg p-5">
                            <div className="flex items-center space-x-4 mb-4">
                                <span className="uppercase text-yellow-500 text-sm dark:text-yellow-500">Line {item.line}</span>
                            </div>
                            <div className="mb-2">
                                <pre className="bg-gray-100 dark:bg-gray-800 p-5 rounded-md overflow-x-auto break-all">
                                    <code className="break-all">{item.code}</code>
                                </pre>
                            </div>
                            <div className="mt-5 text-slate-500 dark:text-slate-400">
                                
                                    <p className="text-slate-800 dark:text-white mb-2 font-medium">Suggestion</p>
                                    <p> {(item.suggestion ? item.suggestion.replace(/\/\//g, '') : 'N/A')}
</p>
                            </div>
                        </div>
                    ))
                )}
            </div>
        );
    };


 const renderEmptyState = () => {
    return (
        <div className="text-center p-10">
        <i className="bx bx-error-circle bx-rotate-180 bx-lg text-yellow-500"></i>
            <h2 className="text-4xl  mb-2 dark:text-white">No audit data?</h2>
           <p className="text-slate-500 dark:text-slate-400 mb-4">
                Click the button to perform an AI powered on page audit.
            </p>
           
            <p className="text-slate-500 dark:text-slate-400 mb-4">
                 
               <span className=" bg-yellow-500 text-white text-sm px-5 py-2 rounded-full"> {aiLimit - aiUsageToday} AI Credits Left for today</span>
             </p>
     
        </div>
      );
};

    const renderModal = () => {
        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                <div className="bg-white dark:bg-slate-700 p-8 rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold mb-4 dark:text-white">Daily Limit Reached</h2>
                    <p className="text-gray-700 mb-6 dark:text-slate-400">
                        You have used all your AI credits for today. Please come back tomorrow.
                    </p>
                    <button
                        onClick={() => setIsModalOpen(false)}
                        className="bg-yellow-500 text-white py-2 px-4 rounded-md hover:bg-yellow-600 focus:outline-none"
                    >
                        Close
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <div className="min-h-screen bg-slate-100 dark:bg-slate-800 text-slate-800 dark:text-white p-10">
                    <div className="container mx-auto">
                        <div className="w-full py-10 flex items-center relative">
                            <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
                                AI Powered On Page Audit
                                <div className="text-sm text-slate-500 mt-2">
                                    <i className="bx bx-folder bx-fw"></i> project / {url ? url.replace(/^(www\.)?/, '') : 'N/A'}
                                </div>
                            </div>

                            <div className="absolute right-0 text-right">
                                {auditDate && (
                                    <p className="text-slate-500 mb-4 text-sm">
                                        <span id="last-audit">
                                            Last Audited <TimeAgo date={auditDate} />
                                        </span>
                                        <Tooltip
                                            forid="last-audit"
                                            message={new Date(auditDate).toLocaleDateString('en-US', options)}
                                            position="top"
                                        />
                                    </p>
                                )}
                                {typeof aiLimit === 'number' && (
                                    <p className="text-sm text-slate-500">
                                        AI Usage: {aiUsageToday} / {aiLimit}
                                    </p>
                                 )}
                                <button
                                    onClick={fetchAuditReport}
                                    className={`uppercase text-sm py-2 px-4 rounded-lg
                                        ${
                                            typeof aiLimit === 'number' && aiUsageToday >= aiLimit
                                                ? 'bg-slate-200 dark:bg-slate-700 dark:text-slate-500 text-slate-500 cursor-not-allowed'
                                                : 'bg-yellow-500 text-white hover:bg-yellow-400 border border-yellow-500'
                                        }`}
                                    disabled={typeof aiLimit === 'number' && aiUsageToday >= aiLimit}
                                >
                                    <i className="bx bx-rotate-left bx-fw"></i> {loading ? 'Auditing...' : 'AI Audit'}
                                </button>
                            </div>
                        </div>

                        <div>
                            {error && (
                                <div className="flex items-center justify-center my-10 text-center">
                                    <p className="w-96 mt-4 mb-10 dark:text-white">
                                        <i className="bx bx-error-circle bx-rotate-180 bx-lg text-red-500"></i>
                                        <h3 className="text-4xl mb-2">Oh snap!</h3>
                                        Something went wrong. Please try again later or let us know what happened <a className="text-yellow-500" href="https://tally.so/r/nrdakL" target="_blank" rel="noopener noreferrer">here</a>
                                        <p className="text-sm text-slate-500 mt-5">{error}</p>
                                    </p>
                                </div>
                            )}
                         {typeof aiLimit === 'number' && aiUsageToday >= aiLimit && (
                             <div className="flex items-center justify-center my-10 text-center dark:text-white">
                                   <p className="w-96 mt-4 mb-10">
                                      <i className="bx bx-time-five bx-lg text-yellow-500"></i>
                                        <h3 className="text-4xl mb-2">AI Limit Reached</h3>
                                        You have used {aiUsageToday} out of {aiLimit} AI requests for today. Please try again tomorrow.
                                    </p>
                              </div>
                             )}

                            {loading && (
                                <div className="flex items-center justify-center my-10 text-center dark:text-white">
                                    <p className="w-96 mt-4 mb-10">
                                        <i className="bx bx-loader-alt bx-spin bx-rotate-180 bx-lg text-yellow-500"></i>
                                        <h3 className="text-4xl mb-2">Preparing your data</h3>
                                        Getting your website data and calculations...
                                    </p>
                                </div>
                            )}

                            {!auditReport && !loading && !error && renderEmptyState()}

                            {auditReport && (
                                <div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 rounded-lg p-10">
                                    <h3 className="text-xl mb-5 flex items-center">
                       <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
  <path d="M3 12C7.5 12 12 7.5 12 3C12 7.5 16.5 12 21 12C16.5 12 12 16.5 12 21C12 16.5 7.5 12 3 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M2 19.5C2.83333 19.5 4.5 17.8333 4.5 17C4.5 17.8333 6.16667 19.5 7 19.5C6.16667 19.5 4.5 21.1667 4.5 22C4.5 21.1667 2.83333 19.5 2 19.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M16 5C17 5 19 3 19 2C19 3 21 5 22 5C21 5 19 7 19 8C19 7 17 5 16 5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
</svg>
Seotic AI Audit 
                     
                      </h3>
                      <p className="mb-5 text-slate-500 dark:text-slate-400">AI powered on page audit and code suggestion tool designed to enhance your website's SEO and usability. Identify and fix issues like improper meta tags, semantic HTML structure and JavaScript dependencies. Get actionable recommendations to optimize every line of code for better search engine rankings and user experience!</p>
                                    {renderAuditReport(auditReport)}
                                </div>
                            )}

                            {isModalOpen && renderModal()}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AIReport;