// components/ForgotPassword.js
import React, { useState } from 'react';
import InputField from './components/InputField';
import Logo from './img/seotic-logo.png';
import LogoWhite from './img/seotic-logo-w.png';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const timestamp = new Date().getTime();

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error('Please enter your email address.');
      return;
    }

    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/forgot_password.php?_=${timestamp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (response.ok && result.status === 'success') {
        toast.success('Password reset link sent to your email!');
        setEmail(''); // Clear the input field
      } else {
        toast.error(result.message || 'Failed to send password reset link.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100 dark:bg-slate-800 dark:text-white">
      <div className="w-32 mb-10 dark:hidden">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="w-32 mb-10 hidden dark:block">
        <img src={LogoWhite} alt="Logo" />
      </div>
       <div className="md:min-w-96 md:bg-white rounded-lg border border-slate-100 md:border-slate-200 dark:bg-slate-800 md:dark:bg-slate-700 dark:border-slate-800 md:dark:border-slate-600">
         <div className="p-0 md:p-10 ">
          <h2 className="text-2xl">Forgot Password</h2>
          <p className="text-slate-400 text-sm mb-10">Enter your email to reset your password</p>

          <form onSubmit={handleForgotPassword}>
            <InputField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              icon="envelope"
              placeholder="Your email"
            />

            <button
              type="submit"
              className="w-full py-2 mt-4 text-white bg-yellow-500 rounded"
            >
              Send Reset Link
            </button>
          </form>
        </div>
        
         <div className="px-10 py-5 text-center bg-slate-100 md:bg-slate-50 md:dark:bg-slate-600 dark:bg-slate-800 rounded-b-lg ">
          Remembered your password?
          <Link
            className="text-green-500 ml-5"
            to="/login"
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
