import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
const InternalLinksTable = ({ mainUrl, onSelect, token }) => {
  const [linksData, setLinksData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedLink, setSelectedLink] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [userPlan, setUserPlan] = useState(null);
  const itemsPerPage = 10;

  // Function to fetch user plan
  const fetchUserPlan = async (token) => {
    try {
      const response = await fetch('https://www.pixelliongroup.com/seotic/get_user_plan.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();

      if (!response.ok || data.status !== 'success') {
        throw new Error(data.message || 'Failed to fetch user plan.');
      }


      return data.plan; // Return only the plan data object
    } catch (error) {
      console.error('Error fetching user plan:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchInternalLinks = async () => {
      if (!mainUrl) return;

      setLoading(true);
      setError('');
      setLinksData([]);

      try {
        const response = await fetch('https://www.pixelliongroup.com/seotic/internal_links.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ url: mainUrl }),
        });

        const data = await response.json();

        if (data.error) {
          setError(data.error);
        } else {
          setLinksData(data.internal_links);
          if (data.internal_links.length > 0) {
            setSelectedLink(data.internal_links[0].link); // Select the first link by default
            if (onSelect) onSelect(data.internal_links[0].link);
          }
        }
      } catch (err) {
        setError('Failed to fetch internal links.');
      } finally {
        setLoading(false);
      }
    };

    // Fetch user plan data
    const getUserPlan = async () => {
      const plan = await fetchUserPlan(token);
      setUserPlan(plan);
    };

    getUserPlan();
    fetchInternalLinks();
  }, [mainUrl, onSelect, token]);

  const handleRowClick = (link) => {
    setSelectedLink(link);
    if (onSelect) onSelect(link);
  };

  // Calculate paginated data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Limit links to 5 if user plan is Premium
  const isLimitedPlan = userPlan?.plan_type === 'seed';
  const displayedLinks = isLimitedPlan ? linksData.slice(0, 5) : linksData;
  const currentItems = displayedLinks.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(displayedLinks.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="dark:text-white">
      <h4 className="text-xl px-10 py-10 text-left flex items-center">
        <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
          <path d="M14 21H12C7.28595 21 4.92893 21 3.46447 19.5355C2 18.0711 2 15.714 2 11V7.94427C2 6.1278 2 5.21956 2.38032 4.53806C2.65142 4.05227 3.05227 3.65142 3.53806 3.38032C4.21956 3 5.1278 3 6.94427 3C8.10802 3 8.6899 3 9.19926 3.19101C10.3622 3.62712 10.8418 4.68358 11.3666 5.73313L12 7M8 7H16.75C18.8567 7 19.91 7 20.6667 7.50559C20.9943 7.72447 21.2755 8.00572 21.4944 8.33329C22 9.08996 22 10.1433 22 12.25C22 12.8889 22 13.4697 21.9949 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
          <path d="M17.6863 20.4315C18.4444 21.1895 19.6734 21.1895 20.4315 20.4315C21.1895 19.6734 21.1895 18.4444 20.4315 17.6863L18.7157 15.9705C17.9922 15.247 16.8396 15.2141 16.077 15.8717M16.3137 13.5685C15.5556 12.8105 14.3266 12.8105 13.5685 13.5685C12.8105 14.3266 12.8105 15.5557 13.5685 16.3137L15.2843 18.0294C16.0078 18.753 17.1604 18.7859 17.9231 18.1282" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
        </svg>
        Internal Links
      </h4>
      {loading ? (
        <p className="pb-10 text-center"><i className="bx bx-circle bx-burst bx-rotate-270 bx-fw"></i></p>
      ) : error ? (
        <p className="text-red-500 pb-10 text-center">Error: {error}</p>
      ) : linksData.length === 0 ? (
        <p className="pb-10 text-center">No internal links found.</p>
      ) : (
        <div>
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-slate-50 dark:bg-slate-600 text-sm rounded-t-lg">
                  <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">URLs</th>
                  <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr
                    key={index}
                    className="border-b border-slate-100 dark:border-slate-600 hover:bg-yellow-50 dark:hover:bg-slate-500"
                    onClick={() => handleRowClick(item.link)}
                  >
                    <td className="py-5 px-10 dark:text-slate-400 text-slate-700 w-full text-left">
                      <a
                        href={`/internal/?q=${item.page}`}
                      >
                        {item.link}
                      </a>
                    </td>
                    <td className="py-5 px-10 text-sm dark:text-slate-400 text-slate-700 w-full text-left">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded ${
                          item.status_code === 200
                            ? 'bg-green-100 text-green-800'
                            : typeof item.status_code === 'number' && item.status_code >= 400
                            ? 'bg-red-100 text-red-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {item.status_code}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Upgrade message if user is on a limited plan */}
          {isLimitedPlan && (
            <p className="text-center text-yellow-500 dark:text-yellow-400 my-10 ">
              We found more than {linksData.length} internal links on your website. To see all links, please <Link className="bg-yellow-500 text-white rounded px-4 py-2 mx-2 text-sm" to="/subscription">upgrade</Link> your plan.
            </p>
          )}

          {/* Conditionally render pagination */}
          {!isLimitedPlan && (
            <div className="flex space-x-2 mt-4 pl-10 pb-5">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-4 py-2 rounded-md ${
                    currentPage === index + 1
                      ? 'bg-yellow-500 text-white'
                      : 'bg-slate-100 dark:bg-slate-600 dark:text-slate-400 text-slate-600'
                  }`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InternalLinksTable;
