// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';  // Import PublicLayout
import PrivateLayout from './layouts/PrivateLayout';  // Import PrivateLayout
import Register from './Register';
import Login from './Login';
import Forgot from './ForgotPassword';
import ResetPassword from './ResetPassword'; // Import the ResetPassword component
import UserPage from './UserPage';
import Onboard from './Onboard';
import Dashboard from './Dashboard';
import AddWebsite from './AddWebsite';
import Projects from './Projects';
import WebsiteAudit from './WebsiteAudit';
import BacklinkChecker from './BacklinkChecker';
import Keywords from './Keywords';
import Schema from './tools/SchemaGen';
import Robot from './tools/RobotGen';
import Sitemap from './tools/SitemapGen';
import UTM from './tools/UTMGen';
import KeywordSugg from './tools/KeywordSugg';
import Writer from './tools/WriterPage.js';
import SerpResult from './SerpResult.js';
import Competitor from './Competitors.js';
import Tools from './Tools.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Settings from './SettingsPage.js';
import Subscription from './components/Subscription';
import Renew from './components/Renew';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import NoMobile from './pages/NoMobile';
import PrivateRoute from './components/PrivateRoute';
import ProtectedRoute from './ProtectedRoute';
import Internal from './Internal';
import BlogAudit from './BlogAudit';
import AIReport from './AIReport';
import NotFound from './404';
import GuestPosts from './GuestPostSitesPage';
import 'boxicons';  // Ensure Boxicons are imported
import ShortcutHandler from './utils/ShortcutHandler'; // Import the new component
import RankedKeywords from './RankedKeywords'; // Import the RankedKeywords component

function App() {
  return (
    <Router>
     <ShortcutHandler /> {/* Add the ShortcutHandler inside the Router */}
    <ToastContainer />
      <div className="App bg-slate-100">
        <Routes>
          {/* Public routes wrapped in PublicLayout */}
          <Route element={<PublicLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
              <Route path="/forgot" element={<Forgot />} />
               <Route path="/reset-password" element={<ResetPassword />} />
               <Route path="/sorry" element={<NoMobile />} />
          </Route>
          

          {/* Private routes wrapped in PrivateLayout */}
          <Route element={<PrivateLayout />}>
                     
            <Route path="/renew" element={<PrivateRoute><Renew /></PrivateRoute>} />
             <Route path="/subscription" element={<PrivateRoute><Subscription /></PrivateRoute>} />
          <Route path="/success" element={<PrivateRoute><Success /></PrivateRoute>} />
          <Route path="/cancel" element={<PrivateRoute><Cancel /></PrivateRoute>} />
             <Route path="/ranked/*" element={<RankedKeywords />} />
            
              <Route
              path="/onboard"
              element={
                <PrivateRoute>
                  <Onboard />
                </PrivateRoute>
              }
            />


            
        
        {/* Define a route for WebAuditPage */}
        <Route path="/internal/" element={<Internal />} />
        
        {/* You can add more routes here as needed */}
   

            
              <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            
            <Route
              path="/aireport"
              element={
                <PrivateRoute>
                  <AIReport />
                </PrivateRoute>
              }
            />

            
            <Route
              path="/blog-audit"
              element={
                <PrivateRoute>
                  <BlogAudit />
                </PrivateRoute>
              }
            />
            
            
         

            
        <Route
              path="/guest-post"
              element={
                <PrivateRoute>
                  <GuestPosts />
                </PrivateRoute>
              }
            />

                      
            
            <Route
              path="/projects"
              element={
                <PrivateRoute>
                  <Projects />
                </PrivateRoute>
              }
            />
            
            <Route
              path="/schema"
              element={
                <PrivateRoute>
                  <Schema />
                </PrivateRoute>
              }
            />
            
             <Route
              path="/robot"
              element={
                <PrivateRoute>
                  <Robot />
                </PrivateRoute>
              }
            />

    <Route
              path="/sitemap"
              element={
                <PrivateRoute>
                  <Sitemap />
                </PrivateRoute>
              }
            />
            
             <Route
              path="/utm"
              element={
                <PrivateRoute>
                  <UTM />
                </PrivateRoute>
              }
            />
            
             <Route
              path="/keysugg"
              element={
                <PrivateRoute>
                  <KeywordSugg />
                </PrivateRoute>
              }
            />




            
            <Route
              path="/addproject"
              element={
                <PrivateRoute>
                  <AddWebsite />
                </PrivateRoute>
              }
            />
            
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                  <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
                </PrivateRoute>
              }
            />
            
            <Route
              path="/tools"
              element={
                <PrivateRoute>
                  <Tools />
                </PrivateRoute>
              }
            />

               
            
             <Route element={<ProtectedRoute />}>
             
               <Route
              path="/serp"
              element={
                <PrivateRoute>
                  <SerpResult />
                </PrivateRoute>
              }
            />

             
              <Route
              path="/audit"
              element={
                <PrivateRoute>
                  <WebsiteAudit />
                </PrivateRoute>
              }
            />

             
             
                     <Route
              path="/backlinks"
              element={
                <PrivateRoute>
                  <BacklinkChecker />
                </PrivateRoute>
              }
            />
             
             <Route
              path="/competitor"
              element={
                <PrivateRoute>
                  <Competitor />
                </PrivateRoute>
              }
            />
            
             <Route
              path="/keywords"
              element={
                <PrivateRoute>
                  <Keywords />
                </PrivateRoute>
              }
            />
            
             <Route
              path="/writer"
              element={
                <PrivateRoute>
                  <Writer />
                </PrivateRoute>
              }
            />


            
            </Route>

            
                        
            

          </Route>

          {/* Default route redirects to login */}
          <Route path="/" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

      </div>
    </Router>
  );
}

export default App;
