import React, { useState } from 'react';

const DropdownWithSearch = ({ options, placeholder, onSelect, defaultValue }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue || null); // Set default value if passed

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelect = (option) => {
    setIsOpen(false);
    setSelectedOption(option); // Set the selected option
    setSearchTerm(''); // Clear search term after selecting an option
    onSelect(option); // Pass the selected option (label and value) to the parent component
  };

  // Filter options based on the search term
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative w-full">
      {/* Input field to toggle dropdown and search */}
      <input
        type="text"
        placeholder={placeholder} // Show placeholder
        value={searchTerm || (selectedOption ? selectedOption.label : '')} // Show search term when typing, but fallback to selected option
        onChange={handleSearch}
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none ${
          selectedOption ? 'text-black dark:text-white' : 'text-gray-400'
        }`} // Change text color based on selection (black for selected, gray for placeholder)
      />

      {/* Dropdown options */}
      {isOpen && (
        <div className="absolute w-full max-h-48 overflow-auto bg-white border border-slate-100 dark:border-slate-500 dark:bg-slate-600 rounded-lg mt-1 shadow-lg z-10">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleSelect(option)}
                className="p-3 hover:bg-yellow-500 hover:bg-slate-500 hover:text-white cursor-pointer"
              >
                {option.label}
              </div>
            ))
          ) : (
            <div className="p-3 text-slate-500">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownWithSearch;
