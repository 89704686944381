// src/components/AISuggestions.js

import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import Modal from './components/dashboard/AIModal';
import Typewriter from './components/dashboard/Typewriter';

const AISuggestions = ({
  backlinks,
  performanceScore,
  accessibilityScore,
  seoAuditScore,
  top1Count,
  top3Count,
  top10Count,
  bounceRate,
  aiLimit, // Receive aiLimit from props
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [aiResponse, setAIResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [aiUsageToday, setAiUsageToday] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [typedResponse, setTypedResponse] = useState('');

  const options = ['Summarize', 'What next?', 'Improve'];
const timestamp = new Date().getTime();
  // Fetch AI usage when the component mounts
  useEffect(() => {
    fetchAiUsageToday();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAiUsageToday = async () => {
	  
    const token = localStorage.getItem('token');
    if (!token) {
      setAIResponse('You need to log in to use this feature.');
      return;
    }

    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/get_ai_usage.php?_=${timestamp}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (response.ok && data.status) {
        setAiUsageToday(data.ai_usage_per_day);
      } else {
        setAiUsageToday(0);
      }
    } catch (error) {
      console.error('Error fetching AI usage:', error);
      setAiUsageToday(0);
    }
  };

  const handleOptionSelect = async (option) => {
    if (aiUsageToday >= aiLimit) {
      setAIResponse('You have reached your daily limit for AI suggestions.');
      setIsModalOpen(true);
      return;
    }

    setSelectedOption(option);
    setIsLoading(true);
    setAIResponse(''); // Clear previous response
    setTypedResponse('');
    setIsModalOpen(true); // Open the modal

    const prompt = generatePrompt(option);
    await fetchAIResponse(prompt);

    setIsLoading(false);

    // Update AI usage after successful response
    await updateAiUsage();
  };

  const updateAiUsage = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setAIResponse('You need to log in to use this feature.');
      return;
    }

    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/update_ai_usage.php?_=${timestamp}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (response.ok && data.status) {
        setAiUsageToday((prevUsage) => prevUsage + 1);
      } else {
        console.error('Error updating AI usage:', data.message);
      }
    } catch (error) {
      console.error('Error updating AI usage:', error);
    }
  };

  const generatePrompt = (option) => {
    const backlinksText =
      backlinks !== null && backlinks !== undefined ? backlinks : 'an unknown number of';
    const performanceScoreText =
      performanceScore !== null && performanceScore !== undefined ? performanceScore : 'an unknown';
    const accessibilityScoreText =
      accessibilityScore !== null && accessibilityScore !== undefined
        ? accessibilityScore
        : 'an unknown';
    const seoAuditScoreText =
      seoAuditScore !== null && seoAuditScore !== undefined ? seoAuditScore : 'an unknown';
    const top1Text =
      top1Count !== null && top1Count !== undefined ? top1Count : 'an unknown number of';
    const top3Text =
      top3Count !== null && top3Count !== undefined ? top3Count : 'an unknown number of';
    const top10Text =
      top10Count !== null && top10Count !== undefined ? top10Count : 'an unknown number of';
    const bounceRateText =
      bounceRate !== null && bounceRate !== undefined ? bounceRate : 'an unknown';

    let basePrompt = `I have performance score of ${performanceScoreText}, accessibility score of ${accessibilityScoreText}, SEO audit score of ${seoAuditScoreText}, bounce rate ${bounceRateText}%, ${top1Text} keywords in top 1, ${top3Text} keywords in top 3, ${top10Text} keywords in top 10.`;

    switch (option) {
      case 'Summarize':
        return `${basePrompt} Please provide a summary of my website's SEO performance. No list, just show me by paragraph. You can add <p className="mb-2">for paragraph</p>. short and simple words`;
      case 'What next?':
        return `${basePrompt} What should I do next? Provide suggestions in paragraph form. No list, just show me by paragraph. You can add <p className="mb-2">for paragraph</p>. short and simple words`;
      case 'Suggestion':
        return `${basePrompt} Can you give me some suggestions to improve my SEO? No list, just show me by paragraph. You can add <p className="mb-2">for paragraph</p>. short and simple words`;
      case 'Improve':
        return `${basePrompt} I need help understanding how to improve my website's SEO. No list, just show me by paragraph. You can add <p className="mb-2">for paragraph</p>. short and simple words`;
      default:
        return basePrompt;
    }
  };

  const fetchAIResponse = async (prompt) => {
    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/proxy_ai.php?_=${timestamp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });

      const data = await response.json();

      if (response.ok && data.status) {
        setAIResponse(data.message); // Use data.message based on your API response
      } else {
        console.error('API Error:', data);
        setAIResponse('An error occurred while fetching AI response.');
      }
    } catch (error) {
      console.error('Network Error:', error);
      setAIResponse('A network error occurred.');
    }
  };

  // Callback when typing animation completes
  const handleTypingComplete = () => {
    // Optionally, you can add actions after typing completes
    // For example, automatically close the modal after a few seconds
    // setTimeout(() => setIsModalOpen(false), 5000);
  };

  const sanitizedResponse = DOMPurify.sanitize(aiResponse);

  const remainingAiSuggestions = aiLimit - aiUsageToday;

  return (
    <div className="bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 border rounded-lg p-10 h-full relative">
      <h2 className="text-2xl mb-5 flex items-center text-black dark:text-white">
        <svg
          className="mr-2 text-black dark:text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
        >
          <path
            d="M15 2L15.5387 4.39157C15.9957 6.42015 17.5798 8.00431 19.6084 8.46127L22 9L19.6084 9.53873C17.5798 9.99569 15.9957 11.5798 15.5387 13.6084L15 16L14.4613 13.6084C14.0043 11.5798 12.4202 9.99569 10.3916 9.53873L8 9L10.3916 8.46127C12.4201 8.00431 14.0043 6.42015 14.4613 4.39158L15 2Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M7 12L7.38481 13.7083C7.71121 15.1572 8.84275 16.2888 10.2917 16.6152L12 17L10.2917 17.3848C8.84275 17.7112 7.71121 18.8427 7.38481 20.2917L7 22L6.61519 20.2917C6.28879 18.8427 5.15725 17.7112 3.70827 17.3848L2 17L3.70827 16.6152C5.15725 16.2888 6.28879 15.1573 6.61519 13.7083L7 12Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </svg>
        AI Suggestions
      </h2>
      <p className="text-xs text-slate-500 dark:text-slate-400 mb-5">
        You have {remainingAiSuggestions} credits remaining for today.
      </p>
      <div className="flex flex-wrap gap-2 absolute bottom-10">
        {options.map((option) => (
          <button
            key={option}
            onClick={() => handleOptionSelect(option)}
            className={`px-4 py-1 rounded-full dark:border-slate-500 dark:text-white border bg-white text-sm transition-colors duration-300 ${
              selectedOption === option
                ? 'bg-yellow-500 text-white hover:bg-yellow-600'
                : 'bg-slate-100 dark:bg-slate-600 hover:bg-slate-50 dark:hover:bg-slate-500'
            }`}
          >
            {option}
          </button>
        ))}
      </div>

      {/* Modal for AI Response */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {isLoading ? (
          <div className="flex flex-col items-center">
            <p className="bg-gradient-to-r from-slate-300 via-slate-200 to-slate-400 bg-clip-text text-transparent animate-pulse text-sm">
              Thinking
            </p>
            
          </div>
        ) : aiResponse ? (
          <div>
           <h2 className="text-2xl mb-5 flex items-center text-black dark:text-white">
        <svg
          className="mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
        >
          <path
            d="M15 2L15.5387 4.39157C15.9957 6.42015 17.5798 8.00431 19.6084 8.46127L22 9L19.6084 9.53873C17.5798 9.99569 15.9957 11.5798 15.5387 13.6084L15 16L14.4613 13.6084C14.0043 11.5798 12.4202 9.99569 10.3916 9.53873L8 9L10.3916 8.46127C12.4201 8.00431 14.0043 6.42015 14.4613 4.39158L15 2Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M7 12L7.38481 13.7083C7.71121 15.1572 8.84275 16.2888 10.2917 16.6152L12 17L10.2917 17.3848C8.84275 17.7112 7.71121 18.8427 7.38481 20.2917L7 22L6.61519 20.2917C6.28879 18.8427 5.15725 17.7112 3.70827 17.3848L2 17L3.70827 16.6152C5.15725 16.2888 6.28879 15.1573 6.61519 13.7083L7 12Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </svg>
        AI Suggestions
      </h2>
            <Typewriter className="" text={sanitizedResponse} onComplete={handleTypingComplete} />

          </div>
        ) : (
          <p className="text-slate-500 dark:text-slate-400 space-y-5 text-sm">No response available.</p>
        )}
        
      </Modal>
    </div>
  );
};

export default AISuggestions;
