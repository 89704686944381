// src/components/Typewriter.js

import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const words = text.split(' ');

  useEffect(() => {
    if (currentIndex < words.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => (prev ? `${prev} ${words[currentIndex]}` : words[currentIndex]));
        setCurrentIndex(currentIndex + 1);
      }, 100); // Adjust typing speed here (milliseconds)

      return () => clearTimeout(timeout);
    } else {
      if (onComplete) onComplete();
    }
  }, [currentIndex, words, onComplete]);

  return (
    <p className="flex flex-col space-y-5 text-slate-700 dark:text-slate-400" >
      <span className="flex flex-col space-y-5" dangerouslySetInnerHTML={{ __html: displayedText }}></span>
      {currentIndex < words.length && (
        <span className="inline-block w-5 h-1  bg-yellow-400 rounded-full animate-pulse"></span>
      )}
    </p>
  );
};

export default Typewriter;
