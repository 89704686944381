import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const SitemapGenerator = () => {
  const [urls, setUrls] = useState([
    { loc: '', lastmod: '', changefreq: 'weekly', priority: '0.5' }
  ]);
  const [sitemapXml, setSitemapXml] = useState('');
  const [copied, setCopied] = useState(false);

  // Handle adding a new URL entry
  const addUrl = () => {
    setUrls([...urls, { loc: '', lastmod: '', changefreq: 'weekly', priority: '0.5' }]);
  };

  // Remove a specific URL entry
  const removeUrl = (index) => {
    const newUrls = urls.filter((_, i) => i !== index);
    setUrls(newUrls);
  };

  // Update URL fields based on input changes
  const handleUrlChange = (index, field, value) => {
    const newUrls = [...urls];
    newUrls[index][field] = value;
    setUrls(newUrls);
  };

  // Generate the sitemap XML content
  const handleGenerateSitemap = (e) => {
    e.preventDefault();
    setCopied(false);

    let xml = `<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;
    urls.forEach(url => {
      if (url.loc) {
        xml += `  <url>\n`;
        xml += `    <loc>${url.loc}</loc>\n`;
        if (url.lastmod) xml += `    <lastmod>${url.lastmod}</lastmod>\n`;
        xml += `    <changefreq>${url.changefreq}</changefreq>\n`;
        xml += `    <priority>${url.priority}</priority>\n`;
        xml += `  </url>\n`;
      }
    });
    xml += `</urlset>`;
    setSitemapXml(xml);
  };

  return (
    <div className="min-h-screen mx-auto container p-10 bg-slate-100 dark:bg-slate-800 dark:text-white">
      <div className="w-full py-10 flex items-center relative">
        <div className="w-full text-4xl text-left text-slate-800 dark:text-white uppercase">
          Sitemap Generator
          <div className="text-sm text-slate-500">Generate XML sitemap for SEO</div>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-slate-200 dark:bg-slate-700 dark:border-slate-600 p-10">
        <form onSubmit={handleGenerateSitemap}>
          {urls.map((url, index) => (
            <div key={index} className="mb-6 border-b border-slate-300 pb-4">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium text-slate-700 dark:text-slate-300">URL {index + 1}</h3>
                {urls.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeUrl(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove URL
                  </button>
                )}
              </div>
              <div className="mt-4 mb-2">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">URL Location (loc)</label>
                <input
                  type="url"
                  value={url.loc}
                  onChange={(e) => handleUrlChange(index, 'loc', e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter full URL (e.g., https://example.com/page)"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Last Modified (lastmod)</label>
                <input
                  type="date"
                  value={url.lastmod}
                  onChange={(e) => handleUrlChange(index, 'lastmod', e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Change Frequency (changefreq)</label>
                <select
                  value={url.changefreq}
                  onChange={(e) => handleUrlChange(index, 'changefreq', e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                >
                  <option value="always">Always</option>
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                  <option value="never">Never</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Priority (priority)</label>
                <select
                  value={url.priority}
                  onChange={(e) => handleUrlChange(index, 'priority', e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                >
                  <option value="1.0">1.0 (Highest)</option>
                  <option value="0.9">0.9</option>
                  <option value="0.8">0.8</option>
                  <option value="0.7">0.7</option>
                  <option value="0.6">0.6</option>
                  <option value="0.5">0.5 (Default)</option>
                  <option value="0.4">0.4</option>
                  <option value="0.3">0.3</option>
                  <option value="0.2">0.2</option>
                  <option value="0.1">0.1 (Lowest)</option>
                </select>
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={addUrl}
            className="text-blue-500 hover:text-blue-700 mb-4"
          >
            + Add Another URL
          </button>

          <button
            type="submit"
            className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600"
          >
            Generate Sitemap
          </button>
        </form>

        {sitemapXml && (
          <div className="mt-6 bg-slate-50 dark:bg-slate-600 p-10 rounded-lg border-slate-100 dark:border-slate-500 border relative">
            <h3 className="text-sm text-slate-500 uppercase mb-4">Generated Sitemap XML</h3>
            <pre className="whitespace-pre-wrap text-sm">{sitemapXml}</pre>
            <CopyToClipboard text={sitemapXml} onCopy={() => setCopied(true)}>
              <button className="absolute top-5 right-5 text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-white">
                Copy
              </button>
            </CopyToClipboard>
            {copied && <p className="text-green-500 mt-2">Copied to clipboard!</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default SitemapGenerator;
