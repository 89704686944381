import React, { useState } from 'react';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion-container space-y-4">
      {items.map((item, index) => (
        <div key={index} className="border rounded-lg my-5 px-10 py-3 bg-white dark:bg-slate-700 border-slate-200 dark:border-slate-600">
          <div
            className="accordion-title cursor-pointer font-medium flex justify-between items-center text-lg"
            onClick={() => handleToggle(index)}
          >
            <h3 className="">{item.question}</h3>
            <span className="text-lg">
              {activeIndex === index ? '-' : '+'}
            </span>
          </div>
          {activeIndex === index && (
            <div className="accordion-content mt-2 text-slate-600 dark:text-slate-300">
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
