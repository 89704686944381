import React, { useState, useRef, useEffect } from 'react';

const CountryDropdown = ({ uniqueCountries, selectedCountry, setSelectedCountry }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };

  const selectedOption = selectedCountry === 'All' ? 'All Contries' : selectedCountry;

  return (
    <div className="relative"  ref={dropdownRef}>
      <button
        type="button"
        className="border border-slate-100 dark:text-white dark:border-slate-500 rounded-lg px-4 py-2 dark:bg-slate-600 rounded-lg px-4 py-2 text-left flex items-center justify-between focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="w-full flex items-center dark:bg-slate-600">
          {selectedCountry !== 'All' && (
            <img
              src={`https://flagcdn.com/w20/${selectedCountry.toLowerCase()}.png`}
              alt=""
              className="mr-2"
            />
          )}
          <span>{selectedOption}</span>
        </div>
        <i className={`bx ${isOpen ? 'bx-chevron-up' : 'bx-chevron-down'} ml-2`}></i>
      </button>

      {isOpen && (
        <ul className="absolute z-10 mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto">
          <li
            key="All"
            className="px-5 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
            onClick={() => handleSelect('All')}
          >
           <spam className="mr-2 w-[20px] h-[14px] bg-green-500 border border-green-700 text-xs flex items-center justify-center text-white">e</spam>
             <span>All</span>
          </li>
          {uniqueCountries.map((country) => (
            <li
              key={country}
              className="px-5 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
              onClick={() => handleSelect(country)}
            >
              <img
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`}
                alt=""
                className="mr-2"
              />
              <span>{country}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export default CountryDropdown;