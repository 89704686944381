// components/Register.js
import React, { useState, useEffect } from 'react';
import InputField from './components/InputField';
import Logo from './img/seotic-logo.png';
import LogoWhite from './img/seotic-logo-w.png';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isDisposableEmail, setIsDisposableEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const timestamp = new Date().getTime();
  const navigate = useNavigate();

  // Check password strength
  const checkPasswordStrength = (password) => {
    if (!password) {
      setPasswordStrength('');
      return;
    }

    const strengthRegex = {
      strong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
      medium: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/
    };

    if (strengthRegex.strong.test(password)) {
      setPasswordStrength('strong');
    } else if (strengthRegex.medium.test(password)) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
  };

  // Keep track if passwords match
  useEffect(() => {
    if (confirmPassword) {
      setPasswordsMatch(password === confirmPassword);
    }
  }, [password, confirmPassword]);

  // Live check for disposable email
  useEffect(() => {
    if (email && email.includes('@')) {
      fetch(`https://www.pixelliongroup.com/api/detect_disposable.php?email=${email}`)
        .then((res) => res.json())
        .then((data) => {
          setIsDisposableEmail(data.is_disposable);
        })
        .catch((err) => {
          console.error('Error checking email:', err);
          setIsDisposableEmail(false);
        });
    } else {
      setIsDisposableEmail(false);
    }
  }, [email]);

  // “All fields are OK” check
  const isFormValid =
    username.trim() !== '' &&           // Not empty
    email.trim() !== '' &&              // Not empty
    !isDisposableEmail &&               // Non-disposable
    password.trim() !== '' &&           // Not empty
    confirmPassword.trim() !== '' &&    // Not empty
    passwordsMatch &&                   // Passwords match
    passwordStrength !== 'weak';        // Password is not "weak"

  const handleRegister = async (e) => {
    e.preventDefault();

    if (isDisposableEmail) {
      toast.error('Disposable emails are not allowed!');
      return;
    }
    if (!passwordsMatch) {
      toast.error('Passwords do not match!');
      return;
    }

    const userData = { username, email, password };
    try {
      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/user/add_account.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userData)
        }
      );
      const result = await response.json();

      if (response.ok && result.status === 'success') {
        toast.success('Registration successful! Redirecting to login...');
        navigate('/login');
      } else {
        toast.error(result.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100 dark:bg-slate-800 dark:text-white">
      <div className="w-32 mb-10 dark:hidden mt-10 md:mt-0">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="w-32 mb-10 hidden dark:block mt-10 md:mt-0">
        <img src={LogoWhite} alt="Logo" />
      </div>

      <div className="md:min-w-96 md:bg-white rounded-lg border border-slate-100 md:border-slate-200 dark:bg-slate-800 md:dark:bg-slate-700 dark:border-slate-800 md:dark:border-slate-600">
        <div>
          <div className="p-8 md:p-10 ">
            <h2 className="text-2xl">Welcome</h2>
            <p className="text-slate-400 text-sm mb-10">Sign up to rank up</p>

            <form onSubmit={handleRegister}>
              <InputField
                label="Name"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                icon="user"
                placeholder="Your name"
              />
              <InputField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                icon="envelope"
                placeholder="Your email"
              />
              {isDisposableEmail && (
                <div className="text-xs text-red-500 mb-2">
                  * This appears to be a disposable email.
                </div>
              )}

              <InputField
                label="Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                icon="lock-alt"
                placeholder="Your password"
              />
              <div
                className={`mb-5 -mt-2 text-xs ${
                  passwordStrength === 'strong'
                    ? 'text-green-500'
                    : passwordStrength === 'medium'
                    ? 'text-yellow-500'
                    : 'text-red-500'
                }`}
              >
                {passwordStrength === 'strong' && '* Strong password'}
                {passwordStrength === 'medium' && '* Medium strength password'}
                {passwordStrength === 'weak' && '* Weak password'}
              </div>

              <InputField
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                icon="lock-alt"
                placeholder="Confirm password"
              />
              {!passwordsMatch && confirmPassword && (
                <div className="mb-5 -mt-2 text-xs text-red-500 mt-2">
                  * Passwords do not match!
                </div>
              )}

              <div className="my-5 text-slate-500 dark:text-slate-400">
                By using Seotic, you agree to the{' '}
                <a href="https://www.seotic.co/terms" target="_blank">
                  terms of service
                </a>{' '}
                and{' '}
                <a href="https://www.seotic.co/privacy" target="_blank">
                  privacy policy
                </a>
                .
              </div>
              <button
                type="submit"
                className="w-full py-2 mt-4 text-white bg-yellow-500 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={!isFormValid} // <-- Disable if form is invalid
              >
                Register
              </button>
            </form>
          </div>

          <div className="px-10 py-5 text-center bg-slate-100 md:bg-slate-50 md:dark:bg-slate-600 dark:bg-slate-800 rounded-b-lg">
            Already have an account?
            <Link className="text-green-500 ml-5" to="/login">
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;