// src/hooks/useDarkMode.js

import { useEffect, useState } from 'react';

const useDarkMode = () => {
  // Initialize state based on user's preference
  const [isDarkMode, setIsDarkMode] = useState(() => {
    if (typeof window !== 'undefined' && window.matchMedia) {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return false;
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && window.matchMedia) {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

      // Define a handler for changes
      const handleChange = (event) => {
        setIsDarkMode(event.matches);
      };

      // Add the event listener
      if (mediaQuery.addEventListener) {
        mediaQuery.addEventListener('change', handleChange);
      } else {
        // For older browsers
        mediaQuery.addListener(handleChange);
      }

      // Cleanup the event listener on unmount
      return () => {
        if (mediaQuery.removeEventListener) {
          mediaQuery.removeEventListener('change', handleChange);
        } else {
          // For older browsers
          mediaQuery.removeListener(handleChange);
        }
      };
    }
  }, []);

  return isDarkMode;
};

export default useDarkMode;
