import React, { useState, useEffect } from 'react';
import 'boxicons/css/boxicons.min.css';
import LoadingScreen from './components/LoadingScreen';
import Tooltip from './components/Tooltip';
import Modal from './components/ComModal';

const Competitors = () => {
  // State variables
  const [competitorUrls, setCompetitorUrls] = useState([]);
  const [competitorDataMap, setCompetitorDataMap] = useState({});
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const [projectKeywords, setProjectKeywords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', keywords: [] });
  const [dataRefresh, setDataRefresh] = useState(false);
const timestamp = new Date().getTime();

  // Retrieve token and project from localStorage
  const token = localStorage.getItem('token');
  const project = JSON.parse(localStorage.getItem('project'));
  const websiteId = project?.id || null;
  const projectUrl = project?.url || '';

  // Extract domain from project URL
  const getDomain = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname.replace('www.', '').toLowerCase();
    } catch (error) {
      console.error(`Invalid URL encountered: "${url}"`);
      return '';
    }
  };
  const projectDomain = getDomain(projectUrl);

  // Fetch User Plan on component mount
  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        if (!token) {
          throw new Error('User authentication token is missing.');
        }

        const response = await fetch(`https://www.pixelliongroup.com/seotic/get_user_plan.php?_=${timestamp}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        });

        const data = await response.json();

        if (!response.ok || data.status !== 'success') {
          throw new Error(data.message || 'Failed to fetch user plan.');
        }

        setUserPlan(data.plan);
      } catch (error) {
        console.error('Error fetching user plan:', error);
        setError(error.message);
      }
    };

    fetchUserPlan();
  }, [token]);

  // Fetch Project Keywords and Competitor URLs
useEffect(() => {
  const fetchData = async () => {
    if (!token || !projectUrl || !websiteId) {
      setError('Token, project URL, or website ID is missing. Please log in again.');
      return;
    }

    setLoadingStatus(true); // Show loading screen during data fetch

    try {
      const result = await fetchProjectKeywords();
      if (result) {
        const { projectKeywordsFetched, projectDataObj } = result;
        await fetchCompetitorUrls(projectKeywordsFetched, projectDataObj);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data.');
    } finally {
      setLoadingStatus(false); // Hide loading screen once data is fetched
      setDataRefresh(false); // Reset after data is fetched
    }
  };

  fetchData();
}, [token, websiteId, projectUrl, dataRefresh]);


  const fetchProjectKeywords = async () => {
    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/get_competitors_data.php?_=${timestamp}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, url: projectUrl }),
      });

      const data = await response.json();

      if (!response.ok || data.status !== 'success') {
        throw new Error(data.message || 'Failed to fetch project keywords.');
      }

      const projectKeywordsFetched = data.data.com_data.keywords;
      const domainStatistics = data.data.com_data.domain_statistics?.organic || {};

      const projectDataObj = { id: websiteId, url: projectUrl };

      setProjectKeywords(projectKeywordsFetched);
      setProjectData(projectDataObj);
      setCompetitorDataMap((prevDataMap) => ({
        ...prevDataMap,
        [projectUrl]: {
          keywordsInPos1: domainStatistics.keywords_in_pos_1 || 0,
          keywordsInPos2To3: domainStatistics.keywords_in_pos_2_3 || 0,
          keywordsInPos4To10: domainStatistics.keywords_in_pos_4_10 || 0,
          allData: projectKeywordsFetched,
          estimatedTrafficVolume: domainStatistics.estimated_traffic_volume || 0,
        },
      }));

      return { projectKeywordsFetched, projectDataObj };
    } catch (err) {
      console.error('Error fetching project keywords:', err);
      setError(err.message || 'Failed to fetch project keywords.');
      return null;
    }
  };

 const fetchCompetitorUrls = async (projectKeywordsFetched, projectDataObj) => {
    try {
        if (!websiteId) {
            throw new Error('Website ID is missing.');
        }

        // Step 1: Fetch Competitors List
        const response = await fetch(`https://www.pixelliongroup.com/seotic/get_competitors.php?_=${timestamp}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, website_id: websiteId }),
        });

        const data = await response.json();

        if (!response.ok || data.status !== 'success') {
            throw new Error(data.message || 'Failed to fetch competitor URLs.');
        }

        // Step 2: Set Competitors List
        const competitors = data.data;
        setCompetitorUrls(competitors);

        // Step 3: Save Project URL to Database
        await saveProjectData(projectUrl);

        // Step 4: Save Each Competitor URL Sequentially
        for (const competitor of competitors) {
            await saveCompetitorData(competitor.url);
        }

        // Step 5: Fetch All Competitor Data
        await fetchAllCompetitorData(competitors, projectKeywordsFetched);
    } catch (err) {
        console.error('Error fetching competitor URLs:', err);
        setError(err.message || 'Failed to fetch competitor URLs.');
    }
};

// Unified setLoading to cover various loading scenarios
const setLoadingStatus = (status) => {
  setLoading(status);
  if (status) {
    setError(''); // Clear any existing errors when setting loading to true
  }
};

// Modify existing functions to use setLoadingStatus:
const saveProjectData = async (projectUrl) => {
  setLoadingStatus(true); // Show loading screen while saving
  try {
    const response = await fetch(`https://www.pixelliongroup.com/seotic/save_competitors_data.php?_=${timestamp}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, url: projectUrl }),
    });

    const data = await response.json();

    if (!response.ok || data.status !== 'success') {
      console.warn(`Failed to save project URL (${projectUrl}): ${data.message}`);
    } else {
      console.log(`Successfully saved project URL: ${projectUrl}`);
      setDataRefresh(true); // Trigger data refresh
    }
  } catch (error) {
    console.error(`Error saving project URL (${projectUrl}):`, error);
  } finally {
    setLoadingStatus(false); // Hide loading screen after saving
  }
};

const saveCompetitorData = async (competitorUrl, projectUrl) => {
  setLoadingStatus(true); // Show loading screen while saving
  try {
    const response = await fetch(`https://www.pixelliongroup.com/seotic/save_competitors_data.php?_=${timestamp}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, url: competitorUrl, project_url: projectUrl }),
    });

    const data = await response.json();

    if (!response.ok || data.status !== 'success') {
      console.warn(`Failed to save competitor data for ${competitorUrl}: ${data.message}`);
    } else {
      console.log(`Successfully saved competitor data for ${competitorUrl}`);
      setDataRefresh(true); // Trigger data refresh
    }
  } catch (error) {
    console.error(`Error saving competitor data for ${competitorUrl}:`, error);
  } finally {
    setLoadingStatus(false); // Hide loading screen after saving
  }
};

  const fetchAllCompetitorData = async (competitors) => {
    setTableLoading(true);
    try {
        const fetchPromises = competitors.map(async (competitor) => {
            return handleFetchCompetitorData(competitor);
        });

        const results = await Promise.all(fetchPromises);
        const updatedDataMap = results.reduce((acc, result) => {
            if (result) {
                return { ...acc, ...result };
            } else {
                return acc;
            }
        }, {});

        setCompetitorDataMap((prevDataMap) => ({ ...prevDataMap, ...updatedDataMap }));
    } catch (err) {
        console.error('Error fetching competitor data:', err);
        setError(err.message || 'Failed to fetch competitor data.');
    } finally {
        setTableLoading(false);
    }
};


  const handleFetchCompetitorData = async (competitor) => {
  try {
    const response = await fetch(`https://www.pixelliongroup.com/seotic/get_competitors_data.php?_=${timestamp}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, url: competitor.url }),
    });

    const data = await response.json();

    if (!response.ok || data.status !== 'success') {
      throw new Error(data.message || 'Failed to fetch competitor data.');
    }

    // Step 1: Fetch project keywords explicitly for comparison
    const projectResponse = await fetch(`https://www.pixelliongroup.com/seotic/get_competitors_data.php?_=${timestamp}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, url: projectUrl }), // Use projectUrl directly
    });

    const projectData = await projectResponse.json();

    if (!projectResponse.ok || projectData.status !== 'success') {
      throw new Error(projectData.message || 'Failed to fetch project keywords.');
    }

    const projectKeywordsList = projectData.data.com_data.keywords.map((keyword) => keyword.keyword.toLowerCase().trim());
    const competitorKeywordsList = data.data.com_data.keywords;

    // Identify common and different keywords
    const commonKeywords = competitorKeywordsList.filter((keyword) =>
      projectKeywordsList.includes(keyword.keyword.toLowerCase().trim())
    );

    const differentKeywords = competitorKeywordsList.filter(
      (keyword) => !projectKeywordsList.includes(keyword.keyword.toLowerCase().trim())
    );

    const domainStatistics = data.data.com_data.domain_statistics?.organic || {};
    const paidDomainStatistics = data.data.com_data.domain_statistics?.paid || {};

    return {
      [competitor.url]: {
        commonKeywords, // This will be an array of keyword objects
        differentKeywords, // This will be an array of keyword objects
        averageRank:
          data.data.com_data.keywords.length > 0
            ? (
                data.data.com_data.keywords.reduce((acc, keyword) => acc + keyword.rank, 0) /
                data.data.com_data.keywords.length
              ).toFixed(2)
            : 'N/A',
        keywordsInPos1: domainStatistics.keywords_in_pos_1 || 0,
        keywordsInPos2To3: domainStatistics.keywords_in_pos_2_3 || 0,
        keywordsInPos4To10: domainStatistics.keywords_in_pos_4_10 || 0,
        allData: data.data.com_data.keywords,
        estimatedTrafficVolume: domainStatistics.estimated_traffic_volume || 0,
        estimatedTrafficVolumePaid: paidDomainStatistics.estimated_traffic_volume || 0,
      },
    };
  } catch (err) {
    console.error('Error fetching competitor data:', err);
    setError(err.message || 'Failed to fetch competitor data.');
    return null;
  }
};


useEffect(() => {
  fetchCompetitorUrls(); // Run this without conditions to test if it works
}, []);


  const handleShowModal = (title, keywords) => {
    setModalContent({ title, keywords: keywords || [] });
    setShowModal(true);
  };

  // Loading Delay
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  // Count Animation
  const useCountAnimation = (endValue, duration = 2000) => {
    const [value, setValue] = useState(0);
    useEffect(() => {
      if (isNaN(endValue)) {
        setValue(0);
        return;
      }

      let start = 0;
      const increment = endValue / (duration / 20);
      const timer = setInterval(() => {
        start += increment;
        if (start >= endValue) {
          setValue(Math.round(endValue));
          clearInterval(timer);
        } else {
          setValue(Math.round(start));
        }
      }, 20);

      return () => clearInterval(timer);
    }, [endValue, duration]);

    return value;
  };

  // Metrics Calculation
  const totalCompetitors = competitorUrls.length;
  const totalCommonKeywords = competitorUrls.reduce(
    (acc, competitor) => acc + (competitorDataMap[competitor.url]?.commonKeywords?.length || 0),
    0
  );
  const averageCommonKeywords = totalCompetitors ? Math.round(totalCommonKeywords / totalCompetitors) : 0;
  const totalDifferentKeywords = competitorUrls.reduce(
    (acc, competitor) => acc + (competitorDataMap[competitor.url]?.differentKeywords?.length || 0),
    0
  );
  const averageDifferentKeywords = totalCompetitors
    ? Math.round(totalDifferentKeywords / totalCompetitors)
    : 0;

  const animatedTotalCompetitors = useCountAnimation(totalCompetitors);
  const animatedAverageCommonKeywords = useCountAnimation(averageCommonKeywords);
  const animatedAverageDifferentKeywords = useCountAnimation(averageDifferentKeywords);
  
const formatNumber = (num) => {
  if (num >= 1000) {
    return (Math.round((num / 1000) * 10) / 10).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return Math.round(num).toString(); // Ensure no decimals for numbers below 1000
};

  // Conditional Return for Loading
if (isLoading || loading) {
  return <LoadingScreen />;
}

  return (
    <>
      {(loading || tableLoading) && <LoadingScreen />}
      <div className="min-h-screen p-10 mx-auto container dark:text-white">
        <div className="w-full py-10 flex items-center">
          <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
            Competitor Analysis
            <div className="text-sm text-slate-500">
              <i className="bx bx-folder bx-fw"></i> Project / {projectDomain.replace(/(www\.)?/, '')}
            </div>
          </div>
        
        </div>

        {error && (
          <div className="flex items-center justify-center my-10 text-center">
            <p className="w-96 mt-4 mb-10">
              <i className="bx bx-error-circle bx-rotate-180 bx-lg text-red-500"></i>
              <h3 className="text-4xl mb-2">Oh snap!</h3>
              Something went wrong, please try again later or tell us what was wrong{' '}
              <a
                className="text-yellow-500"
                href="https://tally.so/r/nrdakL"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              <p className="text-sm text-slate-500 mt-5">{error}</p>
            </p>
          </div>
        )}

        {/* Display Metrics */}
        {projectData && (
          <div className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5 mb-5">
            {/* Total Competitors */}
            <MetricCard
              label="Competitors"
              value={animatedTotalCompetitors}
              bgColor="bg-gradient-to-t from-white dark:from-slate-700 dark:to-blue-500 to-blue-300"
              icon="bx bx-window-alt bx-fw"
            />

            {/* Average Common Keywords */}
            <MetricCard
              label="Average Common"
              value={animatedAverageCommonKeywords}
              bgColor="bg-gradient-to-t from-white dark:from-slate-700 dark:to-green-500 to-green-300"
              icon="bx bx-text bx-fw"
            />

            {/* Average Different Keywords */}
            <MetricCard
              label="Average Gap"
              value={animatedAverageDifferentKeywords}
              bgColor="bg-gradient-to-t from-white dark:from-slate-700 dark:to-yellow-500 to-yellow-300"
              icon="bx bx-text bx-fw"
            />

            {/* Project Data Summary */}
            <div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 rounded-lg">
              {projectData && (
                <>
                <div className="flex px-10 py-5">
    <p className="text-md text-slate-900 dark:text-white w-full">
      <i className="bx bx-star bx-fw"></i>
     Top Position
    </p>
    <p className="text-md text-right w-fit">{competitorDataMap[projectData.url]?.keywordsInPos1 || 0 }</p>
  </div>
  
  <div className="flex px-10 py-5 border-t border-slate-100 dark:border-slate-600">
    <p className="text-md text-slate-900 dark:text-white w-full">
      <i className="bx bx-star bx-fw"></i>
     Top 3 Position
    </p>
    <p className="text-md text-right w-fit">{competitorDataMap[projectData.url]?.keywordsInPos2To3 || 0 }</p>
  </div>
  
   <div className="flex px-10 py-5 border-t border-slate-100 dark:border-slate-600">
    <p className="text-md text-slate-900 dark:text-white w-full">
      <i className="bx bx-star bx-fw"></i>
     Top 10 Position
    </p>
    <p className="text-md text-right w-fit">{competitorDataMap[projectData.url]?.keywordsInPos4To10 || 0 }</p>
  </div>
  
  <div className="flex px-10 py-5 border-t border-slate-100 dark:border-slate-600">
    <p className="text-md text-slate-900 dark:text-white w-full">
      <i className="bx bx-star bx-fw"></i>
     Est Traffic
    </p>
    <p className="text-md text-right w-full">
  {formatNumber(competitorDataMap[projectData.url]?.estimatedTrafficVolume || 0)} / {formatNumber(
    competitorDataMap[projectData.url]?.estimatedTrafficVolumePaid || 0
  )}
</p>
  </div>


                                                  </>
              )}
            </div>
          </div>
        )}

        {/* Competitors Table */}
        <div className="bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 mt-5">
          <div className="overflow-x-auto">
            <div className="flex p-10 items-center w-full">
              <h3 className="text-xl w-full">
                <i className="bx bx-link bx-fw"></i>Competitors{' '}
                <i className="bx bxs-help-circle bx-xs text-slate-300 hover:text-slate-500"></i>
              </h3>
            </div>

            <table className="min-w-full bg-white dark:bg-slate-700 rounded-lg">
              <thead>
                <TableHeader />
              </thead>
              <tbody>
                {competitorUrls.map((competitor) => (
                  <CompetitorRow
                    key={competitor.id}
                    competitor={competitor}
                    competitorData={competitorDataMap[competitor.url]}
                    handleShowModal={handleShowModal}
                    handleRefresh={() => handleFetchCompetitorData(competitor, projectKeywords)}
                  />
                ))}
              </tbody>
            </table>
            <p className="px-10 py-5 text-xs uppercase text-slate-500 -mt-[1px] border-t border-slate-100 dark:border-slate-600">
              Competitors: {competitorUrls.length} / {userPlan?.competitor_limit || '...'}
            </p>
          </div>

          {/* Modal for showing keywords */}
          {showModal && (
            <Modal
              title={modalContent.title}
              keywords={modalContent.keywords}
              onClose={() => setShowModal(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

// Helper Component: MetricCard
const MetricCard = ({ label, value, bgColor, icon }) => (
  <div className="bg-white rounded-lg border border-slate-200 dark:border-slate-600 dark:bg-slate-700 text-center">
    <div className="py-12 relative">
      <div className="relative flex justify-center items-center mt-12 z-10">
        <div className={`blur-md h-32 w-64 transform -translate-y-1/2 ${bgColor} rounded-tl-full rounded-tr-full absolute top-5 z-[-10]`} />
        <p className="text-xs uppercase text-white px-3 py-1 z-20 opacity-75 relative bg-slate-900 rounded-full">
          <i className={icon}></i>
          {label}
        </p>
      </div>
      <p className="text-8xl font-bold z-20 relative">{value}</p>
    </div>
  </div>
);

// Helper Component: DataSummaryRow
const DataSummaryRow = ({ label, value }) => (
  <div className="flex px-10 py-5 border-t border-slate-100 dark:border-slate-600">
    <p className="text-md text-slate-900 dark:text-white w-full">
      <i className="bx bx-star bx-fw"></i>
      {label}
    </p>
    <p className="text-md text-right w-full">{value != null ? value : 'N/A'}</p>
  </div>
);

// Helper Component: TableHeader
const TableHeader = () => (
  <tr className="bg-slate-50 dark:bg-slate-600 text-sm border-b border-slate-100 dark:border-slate-600 text-slate-500 dark:text-slate-300">
    {['URL', 'Common Keywords', 'Gap Keywords', 'Top 1', 'Top 3', 'Top 10', 'Est Traffic'].map((heading) => (
      <th key={heading} className="py-5 px-10 text-center first:text-left text-xs font-medium uppercase tracking-wider">
        {heading}
      </th>
    ))}
  </tr>
);

const formatNumber = (num) => {
  if (num >= 1000) {
    return (Math.round((num / 1000) * 10) / 10).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return Math.round(num).toString(); // Ensure no decimals for numbers below 1000
};
// Helper Component: CompetitorRow
const CompetitorRow = ({ competitor, competitorData, handleShowModal, handleRefresh }) => (
  <tr className="border-y border-slate-100 dark:border-slate-600 dark:hover:bg-slate-600 first:border-none hover:bg-yellow-50">
    <td className="py-5 px-10 text-slate-700 dark:text-white">
      <a href={competitor.url} target="_blank" rel="noopener noreferrer" className="no-underline">
        {competitor.url.replace(/(https?:\/\/)?(www\.)?/, '')}
      </a>
    </td>
    <td className="py-5 px-10 text-center text-slate-500 dark:text-white">
      <button
        onClick={() => handleShowModal('Common Keywords', competitorData?.commonKeywords)}
        className="text-blue-500 dark:text-white hover:text-yellow-500 bg-slate-100 dark:bg-slate-500 px-2 py-1 rounded-lg"
      >
        {competitorData?.commonKeywords?.length != null ? competitorData.commonKeywords.length : 'N/A'}
      </button>
    </td>
    <td className="py-5 px-10 text-center text-slate-500">
      <button
        onClick={() => handleShowModal('Gap Keywords', competitorData?.differentKeywords)}
        className="text-blue-500 dark:text-white hover:text-yellow-500 bg-slate-100 dark:bg-slate-500 px-2 py-1 rounded-lg"
      >
        {competitorData?.differentKeywords?.length != null ? competitorData.differentKeywords.length : 'N/A'}
      </button>
    </td>
    <td className="py-5 px-10 text-center text-slate-500 dark:text-white">
      {competitorData?.keywordsInPos1 != null ? competitorData.keywordsInPos1 : 'N/A'}
    </td>
    <td className="py-5 px-10 text-center text-slate-500 dark:text-white">
      {competitorData?.keywordsInPos2To3 != null ? competitorData.keywordsInPos2To3 : 'N/A'}
    </td>
    <td className="py-5 px-10 text-center text-slate-500 dark:text-white">
      {competitorData?.keywordsInPos4To10 != null ? competitorData.keywordsInPos4To10 : 'N/A'}
    </td>
    <td className="py-5 px-10 text-center text-slate-500 dark:text-white">
     {competitorData?.estimatedTrafficVolume != null
  ? `${formatNumber(competitorData.estimatedTrafficVolume)} / ${formatNumber(
      competitorData.estimatedTrafficVolumePaid || 0
    )}`
  : 'N/A'}
  
      </td>
      </tr>
);

export default Competitors;