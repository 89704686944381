// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();
const timestamp = new Date().getTime();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: localStorage.getItem('token') || null,
    plan: null,
    loading: true, 
    error: null,
  });

  const fetchUserPlan = async (token) => {
    const response = await fetch(`https://www.pixelliongroup.com/seotic/get_user_plan.php?_=${timestamp}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });

    const data = await response.json();
    if (!response.ok || data.status !== 'success') {
      throw new Error(data.message || 'Failed to fetch user plan.');
    }
    return data.plan;
  };

  useEffect(() => {
    const initializeAuth = async () => {
      if (authState.token) {
        try {
          const plan = await fetchUserPlan(authState.token);
          setAuthState((prev) => ({
            ...prev,
            plan,
            loading: false,
          }));
        } catch (error) {
          console.error('Error fetching user plan:', error);
          // If there's an error, you might consider leaving plan as null and still set loading to false
          setAuthState((prev) => ({
            ...prev,
            plan: null,
            loading: false,
            error: error.message,
          }));
        }
      } else {
        // No token means user is not logged in, set loading to false
        setAuthState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    initializeAuth();
  }, [authState.token]);

  const updateAuth = (newState) => {
    setAuthState((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  return (
    <AuthContext.Provider value={{ ...authState, setAuthState: updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
