import React, { useState, useEffect } from 'react';
import DropdownWithSearch from './components/DropdownWithSearch'; // Import the DropdownWithSearch component
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify

const AddWebsite = () => {
  // State Hooks
  const [projectURL, setProjectURL] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState({ keyword: '', gl: 'us', hl: 'en' });
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Manage steps
const timestamp = new Date().getTime();
  // State Hooks for User Plan
  const [userPlan, setUserPlan] = useState(null); // To store user plan info
  const [planLoading, setPlanLoading] = useState(true); // To manage loading state for plan
  const [websiteCount, setWebsiteCount] = useState(0); // To store websites count
  const [urlStatus, setUrlStatus] = useState(null); // Store URL status
const [urlMessage, setUrlMessage] = useState(''); // Store URL status message

// Function to check URL status
const checkUrlStatus = async (url) => {
  try {
    const token = localStorage.getItem('token'); // Get the token from localStorage
    if (!token) {
      toast.error('User authentication token is missing.');
      return;
    }

    const payload = {
      token,
      urls: [url], // Send the URL as an array
    };

    const response = await fetch('https://www.pixelliongroup.com/seotic/check_url.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (data.status !== 'success') {
      throw new Error(data.message || 'Failed to check URL status.');
    }

    const urlData = data.data[0]; // Assuming only one URL is checked at a time
    setUrlStatus(urlData.status_code);
    setUrlMessage(urlData.message);

    if (urlData.redirected) {
      // If redirected, update project URL with the final URL
      setProjectURL(urlData.final_url);
   
    } else {
      toast.success('URL is valid and accessible.');
    }
  } catch (error) {
    toast.error('Failed to check URL status.');
    console.error('Error checking URL status:', error);
  }
};

// Call the function when the URL input loses focus
const handleUrlBlur = (e) => {
  const url = e.target.value.trim();
  if (isValidURL(url)) {
    checkUrlStatus(url); // Call the API only if the URL format is valid
  } else {
    setUrlStatus(null);
    setUrlMessage('');
    toast.error('Please enter a valid URL.');
  }
};


  // Predefined options for gl and hl
  const glOptions = [
    { value: 'us', label: 'United States' },
    { value: 'uk', label: 'United Kingdom' },
    { value: 'ca', label: 'Canada' },
    { value: 'au', label: 'Australia' },
    // Add more as needed
  ];

  const hlOptions = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'es', label: 'Spanish' },
    // Add more as needed
  ];

  // Fetch User Plan on Component Mount
  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        const token = localStorage.getItem('token'); // Ensure the token is stored securely

        if (!token) {
          toast.error('User authentication token is missing.');
          setPlanLoading(false);
          return;
        }

        const response = await fetch(`https://www.pixelliongroup.com/seotic/get_user_plan.php?_=${timestamp}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }), // Send token in the request body
        });
        const data = await response.json();

        if (!response.ok || data.status !== 'success') {
          throw new Error(data.message || 'Failed to fetch user plan.');
        }

        if (data.status === 'success') {
          setUserPlan(data.plan);
          setWebsiteCount(data.website_count); // Store website_count in state
        } else {
          toast.error(data.message || 'Failed to fetch user plan.');
        }
      } catch (error) {
        toast.error('Network error while fetching user plan.');
        console.error('Error fetching user plan:', error);
      } finally {
        setPlanLoading(false);
      }
    };

    fetchUserPlan();
  }, []);

  // Handle input changes for new keyword
  const handleNewKeywordChange = (e) => {
    const { name, value } = e.target;
    setNewKeyword((prev) => ({ ...prev, [name]: value }));
  };

  // Handle adding a new keyword
  const handleAddKeyword = () => {
    const trimmedKeyword = newKeyword.keyword.trim();
    if (trimmedKeyword === '') {
      toast.error('Keyword cannot be empty.');
      return;
    }

    // Check if user has reached the keyword limit
    if (userPlan && keywords.length >= userPlan.keywords_limit) {
      toast.error(`You have reached your keyword limit of ${userPlan.keywords_limit}. Please upgrade your plan to add more keywords.`);
      return;
    }

    setKeywords([...keywords, { ...newKeyword, keyword: trimmedKeyword }]);
    setNewKeyword({ keyword: '', gl: 'us', hl: 'en' });
  };

  // Handle removing a keyword
  const handleRemoveKeyword = (index) => {
    const updatedKeywords = [...keywords];
    updatedKeywords.splice(index, 1);
    setKeywords(updatedKeywords);
  };

  // Reset form to Step 1
  const handleReset = () => {
    setCurrentStep(1);
    setProjectURL('');
    setProjectDescription('');
    setKeywords([]);
    setNewKeyword({ keyword: '', gl: 'us', hl: 'en' });
    // No need to reset success message as we're using toasts
  };

  // Validate URL format
  const isValidURL = (url) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*))\\.)+[a-z]{2,}' + // domain name
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return !!urlPattern.test(url);
  };

  // Separate handlers for each step to avoid confusion
  const handleNextStep = (e) => {
    e.preventDefault();

    if (currentStep === 1) {
      // Step 1: Validate Project URL
      if (!projectURL.trim()) {
        toast.error('Project URL is required.');
        return;
      }

      if (!isValidURL(projectURL.trim())) {
        toast.error('Please enter a valid URL (must start with http:// or https://).');
        return;
      }

      // Check if user has reached the project limit
      if (userPlan && websiteCount >= userPlan.projects_limit) {
        toast.error(`You have reached your project limit of ${userPlan.projects_limit}. Please upgrade your plan to add more projects.`);
        return;
      }

      // Proceed to Step 2
      setCurrentStep(2);
      return;
    }

    if (currentStep === 2) {
      // Step 2: Validate Keywords
      if (keywords.length === 0) {
        toast.error('At least one keyword is required.');
        return;
      }

      // Proceed to Step 3
      setCurrentStep(3);
      return;
    }
  };

  const handleFinalSubmit = async () => {
    // Final Submission: Step 3
    try {
      setLoading(true);
      const token = localStorage.getItem('token'); // Get JWT token from localStorage

      if (!token) {
        toast.error('User authentication token is missing.');
        setLoading(false);
        return;
      }

      // Prepare payload for the single endpoint
      const payload = {
        token,
        url: projectURL.trim(),
        description: projectDescription.trim(),
        keywords: keywords,
        website_hl: 'en', // You can make this dynamic based on user input
        website_gl: 'us', // You can make this dynamic based on user input
      };

      // Check if user has reached the project limit before submitting
      if (userPlan && websiteCount >= userPlan.projects_limit) {
        toast.error(`You have reached your project limit of ${userPlan.projects_limit}. Please upgrade your plan to add more projects.`);
        setLoading(false);
        return;
      }

      // Check if adding these keywords would exceed the keyword limit
      if (userPlan && keywords.length > userPlan.keywords_limit) {
        toast.error(`You can only add up to ${userPlan.keywords_limit} keywords.`);
        setLoading(false);
        return;
      }

      // Send POST request to the single endpoint
      const response = await fetch('https://www.pixelliongroup.com/seotic/create_project.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const resData = await response.json();

      if (!response.ok || resData.status !== 'success') {
        throw new Error(resData.message || 'Failed to add project.');
      }

      // If successful, reset the form and show success message
      setProjectURL('');
      setProjectDescription('');
      setKeywords([]);
      toast.success('Project successfully added!');
      setCurrentStep(4); // Proceed to Success Step

      // Update websiteCount if the API returns the new project count
      if (resData.website_count !== undefined) {
        setWebsiteCount(resData.website_count);
      }
    } catch (error) {
      toast.error(error.message || 'Failed to add project.');
      console.error('Error adding project:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100 dark:bg-slate-800 p-5 dark:text-white">
      <div className="w-full max-w-2xl p-10 bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600">
        {/* Toast Container */}
        <ToastContainer />

        {/* Header */}
        <div>
          <h2 className="mb-6 text-2xl flex items-center">
            <svg
              className="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={30}
              height={30}
              fill={"none"}
            >
              <path
                d="M13 21H12C7.28595 21 4.92893 21 3.46447 19.5355C2 18.0711 2 15.714 2 11V7.94427C2 6.1278 2 5.21956 2.38032 4.53806C2.65142 4.05227 3.05227 3.65142 3.53806 3.38032C4.21956 3 5.1278 3 6.94427 3C8.10802 3 8.6899 3 9.19926 3.19101C10.3622 3.62712 10.8418 4.68358 11.3666 5.73313L12 7M8 7H16.75C18.8567 7 19.91 7 20.6667 7.50559C20.9943 7.72447 21.2755 8.00572 21.4944 8.33329C21.9796 9.05942 21.9992 10.0588 22 12"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M18 13V21M22 17H14"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {currentStep === 4 ? 'Success' : 'Add Project'}
          </h2>

          {/* Display Loading State for User Plan */}
          {planLoading && <div className="text-center my-10"><i className='bx bx-circle bx-burst bx-rotate-270'></i></div>}

          {/* Step Indicators */}
          <div className="flex mb-6 space-x-5">
            <div className={`flex items-center ${currentStep >= 1 ? 'text-yellow-500' : 'text-gray-300'}`}>
              <span className="w-8 h-8 flex items-center justify-center border rounded-full">
                1
              </span>
              <span className="ml-2">Project Details</span>
            </div>
            {currentStep > 1 && (
              <div className="flex items-center text-yellow-500"><i className='bx bx-chevron-right bx-sm'></i></div>
            )}
            <div className={`flex items-center ${currentStep >= 2 ? 'text-yellow-500' : 'text-gray-300'}`}>
              <span className="w-8 h-8 flex items-center justify-center border rounded-full">
                2
              </span>
              <span className="ml-2">Keywords</span>
            </div>
            {currentStep > 2 && (
              <div className="flex items-center  text-yellow-500"><i className='bx bx-chevron-right bx-sm'></i></div>
            )}
            <div className={`flex items-center ${currentStep >= 3 ? 'text-yellow-500' : 'text-gray-300'}`}>
              <span className="w-8 h-8 flex items-center justify-center border rounded-full">
                3
              </span>
              <span className="ml-2">Review & Submit</span>
            </div>
          </div>

          {/* Step 1: Project URL and Description */}
          {currentStep === 1 && (
            <form onSubmit={handleNextStep} className="">
              <div className="mb-10">
                <label htmlFor="projectURL" className="block text-slate-400 mb-2 text-sm">
                  Project URL <span className="text-red-500">*</span>
                </label>
               <input
  type="text"
  id="projectURL"
  value={projectURL} // Dynamically bound to projectURL state
  onChange={(e) => setProjectURL(e.target.value)}
  onBlur={handleUrlBlur} // Call the API when the input loses focus
  placeholder="https://example.com"
  className="w-full px-5 py-3 text-gray-700 dark:text-white bg-white dark:bg-slate-800 border border-slate-300 dark:border-slate-600 rounded focus:outline-none"
  required
/>


              </div>

              <div className="mb-4">
                <label htmlFor="projectDescription" className="block text-slate-400 mb-2 text-sm">
                  Description (Optional)
                </label>
                <textarea
                  id="projectDescription"
                  value={projectDescription}
                  onChange={(e) => setProjectDescription(e.target.value)}
                  placeholder="Enter a brief description of your project"
                  className="w-full px-5 py-3 text-gray-700 dark:text-white bg-white dark:bg-slate-800 border border-gray-300 dark:border-slate-600 rounded focus:outline-none"
                  rows={4}
                />
              </div>

              {/* Display Remaining Projects Count */}
              {userPlan && (
                <p className="text-sm text-slate-500 dark:text-slate-300">
                  You have {userPlan.projects_limit - websiteCount} project{userPlan.projects_limit - websiteCount !== 1 ? 's' : ''} remaining.
                </p>
              )}

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="px-5 py-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-400"
                  disabled={loading || (userPlan && websiteCount >= userPlan.projects_limit)}
                >
                  Next Step
                </button>
              </div>
            </form>
          )}

          {/* Step 2: Add Keywords */}
          {currentStep === 2 && (
            <form onSubmit={handleNextStep} className="">
              <div className="mb-4">
                <label htmlFor="keyword" className="block text-slate-500 mb-2 text-sm">
                  Add Keyword <span className="text-red-500">*</span>
                </label>
                <div className="flex mb-2">
                  <input
                    type="text"
                    id="keyword"
                    name="keyword"
                    value={newKeyword.keyword}
                    onChange={handleNewKeywordChange}
                    placeholder="Enter keyword"
                    className="flex-grow  px-5 py-3 text-slate-700 dark:text-white bg-white dark:bg-slate-800 border border-slate-300 dark:border-slate-600 rounded focus:outline-none"
                  />
                  <div className="w-48 ml-2">
                    <DropdownWithSearch
                      options={glOptions}
                      placeholder="Select Location"
                      onSelect={(option) => {
                        setNewKeyword((prev) => ({ ...prev, gl: option.value }));
                      }}
                      defaultValue={glOptions.find((opt) => opt.value === newKeyword.gl)}
                    />
                  </div>
                  <div className="w-48 ml-2">
                    <DropdownWithSearch
                      options={hlOptions}
                      placeholder="Select Language"
                      onSelect={(option) => {
                        setNewKeyword((prev) => ({ ...prev, hl: option.value }));
                      }}
                      defaultValue={hlOptions.find((opt) => opt.value === newKeyword.hl)}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleAddKeyword}
                    className={`p-3 ml-2 bg-blue-500 text-white rounded-lg hover:bg-blue-400 ${
                      userPlan && keywords.length >= userPlan.keywords_limit ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={userPlan && keywords.length >= userPlan.keywords_limit}
                  >
                    <i className="bx bx-plus bx-fw"></i>
                  </button>
                </div>
              </div>

              {/* Display Added Keywords */}
              {keywords.length > 0 && (
                <div className="mb-4">
                  <p className="text-slate-500 mb-2 text-sm">Added Keywords</p>
                  <ul>
                    {keywords.map((kw, index) => (
                      <li key={index} className="mb-2 border border-yellow-200 flex items-center justify-between bg-yellow-50 text-yellow-700 py-5 px-10 relative rounded-lg">
                        <div>
                          <p className="font-semibold text-slate-900">{kw.keyword}</p>
                          <p className="text-xs text-slate-500">
                            Location: {glOptions.find((opt) => opt.value === kw.gl)?.label || kw.gl}, Language: {hlOptions.find((opt) => opt.value === kw.hl)?.label || kw.hl}
                          </p>
                        </div>
                        <button
                          type="button"
                          onClick={() => handleRemoveKeyword(index)}
                          className="absolute right-3 top-1 text-slate-500 hover:text-red-700"
                        >
                          &times;
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Display Remaining Keywords Count */}
              {userPlan && (
                <p className="mb-5 text-sm text-slate-500">
                  You can add {userPlan.keywords_limit - keywords.length} more keyword{userPlan.keywords_limit - keywords.length !== 1 ? 's' : ''}.
                </p>
              )}

              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setCurrentStep(1)}
                  className="px-7 py-3 bg-slate-100 text-gray-700 rounded-lg hover:bg-slate-200"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="px-7 py-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-400"
                  disabled={loading || keywords.length === 0}
                >
                  Next Step
                </button>
              </div>
            </form>
          )}

          {/* Step 3: Review & Submit */}
          {currentStep === 3 && (
            <div className="dark:text-white">
              {/* Review and Submit Content */}
              <h3 className="text-md mt-10 mb-5 text-left flex items-center">
                {/* SVG Icon */}
                <span className="ml-2">Review Your Project</span>
              </h3>
              <div className="border p-10 mb-5 rounded-xl space-y-5">
                <p className="text-slate-700 dark:text-white">
                  <h3 className="uppercase text-slate-500 text-xs">Project URL</h3>
                  <p className="">{projectURL}</p>
                </p>
                <p className="text-slate-700 dark:text-white">
                  <h3 className="uppercase text-slate-500 text-xs">Description</h3>
                  <p className="">{projectDescription || 'N/A'}</p>
                </p>
                <div className="text-slate-700 dark:text-white">
                  <h3 className="uppercase text-slate-500 text-xs">Keywords</h3>
                  <ul className="list-decimal list-inside space-y-1">
                    {keywords.map((kw, index) => (
                      <li className="" key={index}>
                        {kw.keyword} ({glOptions.find((opt) => opt.value === kw.gl)?.label || kw.gl}, {hlOptions.find((opt) => opt.value === kw.hl)?.label || kw.hl})
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setCurrentStep(2)}
                  className="px-7 py-3 bg-slate-100 text-gray-700 rounded-lg hover:bg-gray-200"
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={handleFinalSubmit}
                  className="px-7 py-3 bg-green-500 text-white rounded-lg hover:bg-green-400"
                  disabled={loading}
                >
                  Confirm
                </button>
              </div>
            </div>
          )}

          {/* Step 4: Success Message */}
          {currentStep === 4 && (
            <div className="text-center">
              <div className="flex flex-col justify-center space-x-4">
                <div className="flex justify-center my-10">
                  <button
                    onClick={() => {
                      // Define what the launch button does, e.g., redirect or initiate a process
                      window.location.href = '/projects'; // Example: Redirect to dashboard
                    }}
                    className="px-7 py-3 w-fit bg-green-500 text-white rounded-lg hover:bg-green-400"
                  >
                    Launch
                  </button>
                </div>
                <button
                  onClick={handleReset}
                  className="text-slate-500 hover:text-slate-900 text-sm"
                >
                  Add Another Project
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddWebsite;
