import React, { useState, useEffect, useMemo } from 'react';
import DropdownWithSearch from './components/DropdownWithSearch'; // Import the DropdownWithSearch component

const KeywordsPage = () => {
  const [url, setUrl] = useState(''); // Store URL from local storage
  const [project, setProject] = useState(null); // Store project object
  const [keyword, setKeyword] = useState('');
  const [country, setCountry] = useState({ label: 'United States', value: 'US' });
  const [language, setLanguage] = useState({ label: 'English', value: 'en' });
  const [keywordsData, setKeywordsData] = useState([]);
  const [mainKeyword, setMainKeyword] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [usageCount, setUsageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rekeywordLimit, setRekeywordLimit] = useState(1); // New state for rekeyword_limit
  const resultsPerPage = 10;
  const apiKey = '55ea5afe75mshb6474983193e5c6p1e08d9jsn15aacda2d704';
  const timestamp = new Date().getTime();
 // Extended list of countries with label and value (ISO 3166-1 Alpha-2 codes)
const countryOptions = [
    { label: 'United States', value: 'US' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'Australia', value: 'AU' },
    { label: 'Canada', value: 'CA' },
    { label: 'Germany', value: 'DE' },
    { label: 'France', value: 'FR' },
    { label: 'Japan', value: 'JP' },
    { label: 'India', value: 'IN' },
    { label: 'Brazil', value: 'BR' },
    { label: 'Russia', value: 'RU' },
    { label: 'China', value: 'CN' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Italy', value: 'IT' },
    { label: 'Spain', value: 'ES' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'South Korea', value: 'KR' },
    { label: 'Turkey', value: 'TR' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Norway', value: 'NO' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Finland', value: 'FI' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Austria', value: 'AT' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Chile', value: 'CL' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'Israel', value: 'IL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Greece', value: 'GR' },
    { label: 'Poland', value: 'PL' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Romania', value: 'RO' },
    { label: 'Vietnam', value: 'VN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'Peru', value: 'PE' },
    { label: 'Egypt', value: 'EG' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Oman', value: 'OM' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Sri Lanka', value: 'LK' },
    // Add any additional countries as needed
];

// Extended list of languages with label and value (ISO 639-1 codes)
const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Spanish', value: 'es' },
    { label: 'German', value: 'de' },
    { label: 'French', value: 'fr' },
    { label: 'Chinese', value: 'zh' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Korean', value: 'ko' },
    { label: 'Russian', value: 'ru' },
    { label: 'Portuguese', value: 'pt' },
    { label: 'Italian', value: 'it' },
    { label: 'Dutch', value: 'nl' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Danish', value: 'da' },
    { label: 'Finnish', value: 'fi' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Greek', value: 'el' },
    { label: 'Polish', value: 'pl' },
    { label: 'Thai', value: 'th' },
    { label: 'Czech', value: 'cs' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Malay', value: 'ms' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Bengali', value: 'bn' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Estonian', value: 'et' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Serbian', value: 'sr' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Macedonian', value: 'mk' },
    { label: 'Albanian', value: 'sq' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Maltese', value: 'mt' },
    { label: 'Burmese', value: 'my' }, // Language for Myanmar
    // Add any additional languages as needed
];

  // Handle dropdown selections
  const handleCountrySelect = (selectedCountry) => {
    setCountry(selectedCountry);
  };

  const handleLanguageSelect = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  // Fetch project data from localStorage
  useEffect(() => {
    const projectData = JSON.parse(localStorage.getItem('project')); // Store the project in state
    if (projectData && projectData.url) {
      const domain = new URL(projectData.url).hostname;
      setUrl(domain);
      setProject(projectData); 
    } else {
      setError('No project selected. Please choose a website.');
    }
  }, []);

  // Fetch user usage and plan data from APIs
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token is missing.');
        return;
      }

      try {
        // Fetch usage data
        const usageResponse = await fetch(`https://www.pixelliongroup.com/seotic/get_user_usage.php?_=${timestamp}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        });

        if (!usageResponse.ok) {
          throw new Error(`Usage API error! status: ${usageResponse.status}`);
        }

        const usageData = await usageResponse.json();
        setUsageCount(usageData.search_keywords_per_day || 0);

        // Fetch plan data
        const planResponse = await fetch(`https://www.pixelliongroup.com/seotic/get_user_plan.php?_=${timestamp}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        });

        if (!planResponse.ok) {
          throw new Error(`Plan API error! status: ${planResponse.status}`);
        }

        const planData = await planResponse.json();
        setRekeywordLimit(planData.plan.rekeyword_limit || 1); // Set rekeyword_limit
        // Optionally, if planData includes plan_type, you can set it as well
        // setSubscription(planData.plan_type);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data.');
      }
    };

    fetchUserData();
  }, []);

  // Determine if the user can search more keywords
  const canSearchMore = () => {
    return usageCount < rekeywordLimit;
  };

  // Handle keyword search
  const handleKeywordSearch = async (e) => {
    e.preventDefault();
    if (!canSearchMore()) {
      setError('You have reached your keyword search limit for today.');
      return;
    }
    setLoading(true);
    setError('');

    try {
      const response = await fetch(
        `https://google-keyword-insight1.p.rapidapi.com/keysuggest/?keyword=${encodeURIComponent(
          keyword
        )}&location=${country.value}&lang=${language.value}`,
        {
          method: 'GET',
          headers: {
            'x-rapidapi-host': 'google-keyword-insight1.p.rapidapi.com',
            'x-rapidapi-key': apiKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`API error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response:', data);

      const keywordsArray = Array.isArray(data) ? data : data.keywords || [];
      setKeywordsData(keywordsArray);
      setMainKeyword(keywordsArray.length > 0 ? keywordsArray[0] : null);

      await updateUsage();
    } catch (error) {
      console.error('Error fetching keyword data:', error);
      setError('Failed to fetch keyword data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Update usage count after a successful search
  const updateUsage = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/update_usage.php?_=${timestamp}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        throw new Error(`Update Usage API error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.message === 'Usage updated successfully.') {
        setUsageCount(prevCount => prevCount + 1);
      }
    } catch (error) {
      console.error('Failed to update usage:', error);
      // Optionally, you can set an error state or notify the user
    }
  };

  // Pagination logic
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = Array.isArray(keywordsData)
    ? keywordsData.slice(indexOfFirstResult, indexOfLastResult)
    : [];

  const totalPages = Math.ceil(keywordsData.length / resultsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    if (totalPages <= 1) return null; // No pagination needed

    const pageNumbers = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    if (startPage > 1) {
      pageNumbers.push(1);
    }

    if (startPage > 2) {
      pageNumbers.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push('...');
    }

    if (endPage < totalPages) {
      pageNumbers.push(totalPages);
    }

    return (
      <div className="flex space-x-2 mt-5 pl-10">
        {pageNumbers.map((number, index) =>
          typeof number === 'number' ? (
            <button
              key={index}
              onClick={() => paginate(number)}
              className={`px-4 py-2 rounded-lg text-sm focus:outline-none ${
                currentPage === number ? 'bg-yellow-500 text-white' : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
              }`}
            >
              {number}
            </button>
          ) : (
            <span key={index} className="px-4 py-2 text-sm text-slate-600 px-4 py-2 rounded-lg bg-slate-100">...</span>
          )
        )}
      </div>
    );
  };

  // Sorting logic
  const sortedResults = useMemo(() => {
    let sortableData = [...currentResults];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [currentResults, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="min-h-screen p-10 dark:text-white mx-auto container">
      {/* Header Section */}
      <div className="w-full py-10 flex items-center relative">
        <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
          Keywords Research 
          <div className="text-sm text-slate-500">project / {url.replace(/(www\.)?/, '')}</div>
        </div>
      </div>

      {/* Search Form */}
      <div className="bg-white border border-slate-200 rounded-lg dark:border-slate-600 dark:bg-slate-700 p-10">
        <form onSubmit={handleKeywordSearch}>
          <div className="flex items-center space-x-2">
            <div className="mb-4 w-7/12">
              <label className="block text-sm text-slate-600 dark:text-slate-400 mb-2">Keyword <span className="text-red-500">*</span></label>
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                className="w-full p-3 border border-slate-100 dark:border-slate-500 rounded-lg focus:outline-none dark:bg-slate-600"
                placeholder="Enter your keyword"
                required
              />
            </div>

            <div className="mb-4 w-2/12">
              <label className="block text-sm text-slate-600 dark:text-slate-400 mb-2">Country</label>
              <DropdownWithSearch
                options={countryOptions}
                placeholder="Select a country"
                defaultValue={country} // Set default value to "United States"
                onSelect={handleCountrySelect}
              />
            </div>

            <div className="mb-4 w-2/12">
              <label className="block text-sm text-slate-600 dark:text-slate-4000 mb-2">Language</label>
              <DropdownWithSearch
                options={languageOptions}
                placeholder="Select a language"
                defaultValue={language} // Set default value to "English"
                onSelect={handleLanguageSelect}
              />
            </div>

            <button
              type="submit"
              className="w-fit bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-400 border-yellow-400 border mt-2 flex items-center justify-center"
              disabled={loading || !canSearchMore()}
            >
              <i className="bx bx-rotate-left bx-fw mr-2"></i>
              {loading ? 'Searching' : 'Search'}
            </button>
          </div>

          {/* Usage Information */}
          <p className="text-sm text-slate-500 dark:text-slate-400">
            You used {usageCount} of {rekeywordLimit} Daily Keywords Limit.
            {rekeywordLimit <= 20 && (
              <a href="#" className="text-yellow-500 underline hover:text-yellow-600 ml-2 hidden">Upgrade</a>
            )}
          </p>
        </form>

        {/* Error Message */}
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>

      {/* Main Keyword Details */}
      <div className="">
        {/* Ensure mainKeyword is not an object before rendering */}
        {mainKeyword && typeof mainKeyword === 'object' && (
          <div className="grid grid-cols-3 gap-5 mt-5">
            <div className="py-5 px-10 bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-2xl">
              <p className="text-xs text-slate-500 uppercase">Keyword</p> 
              {mainKeyword.text || 'None'}
            </div>
            <div className="py-5 px-10 bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-2xl">
              <p className="text-xs text-slate-500 uppercase">Volume</p> 
              {mainKeyword.volume.toLocaleString() || 'None'}
            </div>
            <div className="py-5 px-10 bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-2xl">
              <p className="text-xs text-slate-500 uppercase">Competition Level</p> 
              {mainKeyword.competition_level.toLocaleString() || 'None'}
            </div>
            <div className="py-5 px-10 bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-2xl">
              <p className="text-xs text-slate-500 uppercase">Low Bid</p> 
              ${mainKeyword.low_bid.toLocaleString() || '0'}
            </div>
            <div className="py-5 px-10 bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-2xl">
              <p className="text-xs text-slate-500 uppercase">High Bid</p> 
              ${mainKeyword.high_bid.toLocaleString() || '0'}
            </div>
            <div className="py-5 px-10 bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-2xl">
              <p className="text-xs text-slate-500 uppercase">Trend</p> 
              {mainKeyword.trend || '0'}
            </div>
          </div>
        )}
      </div>

      {/* Empty State */}
      {currentResults.length === 0 && (
        <div className="">
                 </div>
      )}

      {/* Display Keywords Research Results */}
      {currentResults.length > 0 && (
        <div className="bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-2xl pb-5 mt-5">
          <div className="">
            <h3 className="text-lg px-10 py-10 flex items-center">
              <i className="bx bx-rotate-left bx-fw mr-2"></i>
              Related Keywords
            </h3>
            
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-slate-50 dark:bg-slate-600 text-sm">
                  <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">
                    Keyword
                  </th>
                  <th 
                    className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left cursor-pointer" 
                    onClick={() => requestSort('volume')}
                  >
                    Volume {sortConfig.key === 'volume' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                  </th>
                  <th 
                    className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left cursor-pointer" 
                    onClick={() => requestSort('competition_level')}
                  >
                    Competition {sortConfig.key === 'competition_level' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                  </th>
                  <th 
                    className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left cursor-pointer" 
                    onClick={() => requestSort('low_bid')}
                  >
                    Low Bid {sortConfig.key === 'low_bid' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                  </th>
                  <th 
                    className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left cursor-pointer" 
                    onClick={() => requestSort('high_bid')}
                  >
                    High Bid {sortConfig.key === 'high_bid' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                  </th>
                  <th 
                    className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left cursor-pointer" 
                    onClick={() => requestSort('trend')}
                  >
                    Trend {sortConfig.key === 'trend' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedResults.map((keyword, index) => (
                  <tr key={index} className="border-y border-slate-100 dark:border-slate-600 hover:bg-yellow-50 dark:hover:bg-slate-500 text-sm">
                    <td className="py-5 px-10">{keyword.text}</td>
                    <td className="py-5 px-10">{keyword.volume.toLocaleString()}</td>
                    <td className="py-5 px-10">
                      <span className={`py-1 px-3 text-xs rounded-lg ${keyword.competition_level === 'HIGH' ? 'bg-red-500 text-white' : keyword.competition_level === 'MEDIUM' ? 'bg-yellow-500 text-black' : 'bg-green-500 text-white'}`}>
                        {keyword.competition_level}
                      </span>
                    </td>
                    <td className="py-5 px-10">
                      ${keyword.low_bid.toLocaleString()}
                    </td>
                    <td className="py-5 px-10">
                      ${keyword.high_bid.toLocaleString()}
                    </td>
                    <td className="py-5 px-10">
                      <span className={`py-1 px-3 text-xs rounded-lg ${keyword.trend > 100 ? 'bg-green-500 text-white' : keyword.trend > 10 ? 'bg-yellow-500 text-black' :  'bg-red-500 text-white'}`}>
                        {keyword.trend.toLocaleString()}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          {renderPagination()}
        </div>
      )}
    </div>
  );
};

export default KeywordsPage;
