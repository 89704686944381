// src/components/NotFoundPage.js

import React from 'react';
import { Link } from 'react-router-dom';

const quotes = [
  "Well, this is embarrassing...",
  "Looks like you're lost!",
  "Oops! This page took a wrong turn.",
  "404: The page you’re looking for isn’t here.",
  "Whoops! Nothing to see here.",
  "You’ve reached the end of the internet.",
  "This page is a ghost!",
];

const NotFoundPage = () => {
  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-slate-100">
      <div className="max-w-md text-center">
        {/* Funny Illustration or Image */}
        <img
          src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExajZkeXRsYm94dW1iaWNlODgzZmxqN3Ywb3Rra21qeHYzeW1mZWY2MSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ZY7ueJ6aQgSsk3cgZ0/giphy.gif" // Replace with your preferred funny image or GIF
          alt="404 Illustration"
          className="mx-auto mb-6 w-full object-contain rounded-xl"
        />

        {/* Humorous Message */}
        <h1 className="text-5xl font-bold mb-2">Oops!</h1>
        <p className="text-lg mb-4">{randomQuote}</p>

        {/* Navigation Buttons */}
        <div className="flex justify-center space-x-4">
          <Link
            to="/dashboard"
            className="flex items-center px-4 py-2 bg-white rounded-lg hover:bg-yellow-50"
          >
           
            <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
    <path d="M15.0002 17C14.2007 17.6224 13.1504 18 12.0002 18C10.8499 18 9.79971 17.6224 9.00018 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M2.35157 13.2135C1.99855 10.9162 1.82204 9.76763 2.25635 8.74938C2.69065 7.73112 3.65421 7.03443 5.58132 5.64106L7.02117 4.6C9.41847 2.86667 10.6171 2 12.0002 2C13.3832 2 14.5819 2.86667 16.9792 4.6L18.419 5.64106C20.3462 7.03443 21.3097 7.73112 21.744 8.74938C22.1783 9.76763 22.0018 10.9162 21.6488 13.2135L21.3478 15.1724C20.8473 18.4289 20.5971 20.0572 19.4292 21.0286C18.2613 22 16.5538 22 13.139 22H10.8614C7.44652 22 5.73909 22 4.57118 21.0286C3.40327 20.0572 3.15305 18.4289 2.65261 15.1724L2.35157 13.2135Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
</svg>
Go Home
          </Link>
          <Link
            to="/contact"
            className="px-4 py-2 bg-transparent hover:text-slate-600 text-slate-500"
          >
            Contact Support
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
