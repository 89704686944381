import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import 'boxicons';  // Ensure Boxicons are imported

const Navbar = () => {
  const [websites, setWebsites] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState(null); // Store the selected website object
  const [dropdownOpen, setDropdownOpen] = useState(false); // Dropdown state
  const [error, setError] = useState(null); // Track errors
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route
  const timestamp = new Date().getTime();
  // Fetch websites from the API
  useEffect(() => {

    const fetchWebsites = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found in localStorage');
        }
        const response = await fetch(`https://www.pixelliongroup.com/seotic/user/get_websites.php?_=${timestamp}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),  // Send the token in the body
        });

        const data = await response.json();
        console.log('API Response:', data); // Log API response for debugging

        if (response.ok && Array.isArray(data)) {
          setWebsites(data);

          // Set the first website as the selected one if none is selected
          const storedProject = JSON.parse(localStorage.getItem('project'));
          if (storedProject) {
            setSelectedWebsite(storedProject);
          } else if (data.length > 0) {
            setSelectedWebsite({ id: data[0].id, url: data[0].url });
            localStorage.setItem('project', JSON.stringify({ id: data[0].id, url: data[0].url }));
          }
        } else {
          throw new Error('Failed to fetch websites');
        }
      } catch (error) {
        console.error('Error fetching websites:', error);
        setError('Error fetching websites');
      }
    };

    fetchWebsites();
  }, []);

  const handleWebsiteSelect = (website) => {
    // Store the selected website in localStorage
    localStorage.setItem('project', JSON.stringify({ id: website.id, url: website.url }));
    setSelectedWebsite(website); // Update the state with the selected website
    setDropdownOpen(false); // Close dropdown after selection
     navigate('/dashboard', { state: { selectedWebsite: website } });
  };

  const handleLogout = () => {
    // Clear token or any user authentication data from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('project'); // Clear the selected project
    // Redirect to login page
    navigate('/login');
  };

  // Helper function to get favicon URL from website URL
const getFaviconUrl = (websiteUrl) => {
  const url = new URL(websiteUrl);
  return `https://icon.horse/icon/${url.hostname}`;
};


  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Determine if the current route is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className="h-screen w-12 flex text-slate-400 items-center z-50">
      {/* Navigation Links */}
      <nav className="flex flex-col space-y-2 p-2 dark:bg-slate-700 dark:border dark:border-slate-600 dark:text-white bg-slate-200 ml-7 mr-5 rounded-xl">
        <Link
          to="/dashboard"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 ${
            isActive('/dashboard') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
    <path d="M15.0002 17C14.2007 17.6224 13.1504 18 12.0002 18C10.8499 18 9.79971 17.6224 9.00018 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M2.35157 13.2135C1.99855 10.9162 1.82204 9.76763 2.25635 8.74938C2.69065 7.73112 3.65421 7.03443 5.58132 5.64106L7.02117 4.6C9.41847 2.86667 10.6171 2 12.0002 2C13.3832 2 14.5819 2.86667 16.9792 4.6L18.419 5.64106C20.3462 7.03443 21.3097 7.73112 21.744 8.74938C22.1783 9.76763 22.0018 10.9162 21.6488 13.2135L21.3478 15.1724C20.8473 18.4289 20.5971 20.0572 19.4292 21.0286C18.2613 22 16.5538 22 13.139 22H10.8614C7.44652 22 5.73909 22 4.57118 21.0286C3.40327 20.0572 3.15305 18.4289 2.65261 15.1724L2.35157 13.2135Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
</svg>
        </Link>

        <Link
          to="/projects"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 ${
            isActive('/projects') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
    <path d="M8 7H16.75C18.8567 7 19.91 7 20.6667 7.50559C20.9943 7.72447 21.2755 8.00572 21.4944 8.33329C22 9.08996 22 10.1433 22 12.25C22 15.7612 22 17.5167 21.1573 18.7779C20.7926 19.3238 20.3238 19.7926 19.7779 20.1573C18.5167 21 16.7612 21 13.25 21H12C7.28595 21 4.92893 21 3.46447 19.5355C2 18.0711 2 15.714 2 11V7.94427C2 6.1278 2 5.21956 2.38032 4.53806C2.65142 4.05227 3.05227 3.65142 3.53806 3.38032C4.21956 3 5.1278 3 6.94427 3C8.10802 3 8.6899 3 9.19926 3.19101C10.3622 3.62712 10.8418 4.68358 11.3666 5.73313L12 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
</svg>
        </Link>
        
         <Link
          to="/audit"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 ${
            isActive('/audit') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}  fill={"none"}>
    <path d="M2.5 8.18677C2.60406 6.08705 2.91537 4.77792 3.84664 3.84664C4.77792 2.91537 6.08705 2.60406 8.18677 2.5M21.5 8.18677C21.3959 6.08705 21.0846 4.77792 20.1534 3.84664C19.2221 2.91537 17.9129 2.60406 15.8132 2.5M15.8132 21.5C17.9129 21.3959 19.2221 21.0846 20.1534 20.1534C21.0846 19.2221 21.3959 17.9129 21.5 15.8132M8.18676 21.5C6.08705 21.3959 4.77792 21.0846 3.84664 20.1534C2.91537 19.2221 2.60406 17.9129 2.5 15.8132" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.49986 12H21.4999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 12C6 8.68629 8.68629 6 12 6C12 7.65685 13.3431 9 15 9C15.6755 9 16.2989 8.77672 16.8004 8.39993C17.5536 9.40273 18 10.6492 18 12M17.1973 15C16.1599 16.7934 14.2208 18 12 18C9.77915 18 7.84012 16.7934 6.80269 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>
        </Link>
        
        <Link
          to="/aireport"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 ${
            isActive('/aireport') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
  <path d="M3 12C7.5 12 12 7.5 12 3C12 7.5 16.5 12 21 12C16.5 12 12 16.5 12 21C12 16.5 7.5 12 3 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M2 19.5C2.83333 19.5 4.5 17.8333 4.5 17C4.5 17.8333 6.16667 19.5 7 19.5C6.16667 19.5 4.5 21.1667 4.5 22C4.5 21.1667 2.83333 19.5 2 19.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M16 5C17 5 19 3 19 2C19 3 21 5 22 5C21 5 19 7 19 8C19 7 17 5 16 5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
</svg>


        </Link>

        
        <Link
          to="/backlinks"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 ${
            isActive('/backlinks') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
    <path d="M9.14339 10.691L9.35031 10.4841C11.329 8.50532 14.5372 8.50532 16.5159 10.4841C18.4947 12.4628 18.4947 15.671 16.5159 17.6497L13.6497 20.5159C11.671 22.4947 8.46279 22.4947 6.48405 20.5159C4.50532 18.5372 4.50532 15.329 6.48405 13.3503L6.9484 12.886" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M17.0516 11.114L17.5159 10.6497C19.4947 8.67095 19.4947 5.46279 17.5159 3.48405C15.5372 1.50532 12.329 1.50532 10.3503 3.48405L7.48405 6.35031C5.50532 8.32904 5.50532 11.5372 7.48405 13.5159C9.46279 15.4947 12.671 15.4947 14.6497 13.5159L14.8566 13.309" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
</svg>
        </Link>
        
        
        <Link
          to="/serp"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 ${
            isActive('/serp') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
    <path d="M8.9835 1.99998C6.17689 2.06393 4.53758 2.33109 3.41752 3.44727C2.43723 4.42416 2.10954 5.79742 2 7.99998M15.0165 1.99998C17.8231 2.06393 19.4624 2.33109 20.5825 3.44727C21.5628 4.42416 21.8905 5.79742 22 7.99998M15.0165 22C17.8231 21.9361 19.4624 21.6689 20.5825 20.5527C21.5628 19.5758 21.8905 18.2026 22 16M8.9835 22C6.17689 21.9361 4.53758 21.6689 3.41752 20.5527C2.43723 19.5758 2.10954 18.2026 2 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 15L17 17M16 11.5C16 9.01468 13.9853 6.99998 11.5 6.99998C9.01469 6.99998 7 9.01468 7 11.5C7 13.9853 9.01469 16 11.5 16C13.9853 16 16 13.9853 16 11.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>

        </Link>
        
        <Link
          to="/competitor"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 ${
            isActive('/competitor') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"  fill="none">
    <path d="M11.1075 5.57624C11.3692 6.02707 11.5 6.25248 11.5 6.5C11.5 6.74752 11.3692 6.97293 11.1075 7.42376L9.85804 9.57624C9.59636 10.0271 9.46551 10.2525 9.25 10.3762C9.03449 10.5 8.7728 10.5 8.24943 10.5H5.75057C5.2272 10.5 4.96551 10.5 4.75 10.3762C4.53449 10.2525 4.40364 10.0271 4.14196 9.57624L2.89253 7.42376C2.63084 6.97293 2.5 6.74752 2.5 6.5C2.5 6.25248 2.63084 6.02707 2.89253 5.57624L4.14196 3.42376C4.40364 2.97293 4.53449 2.74752 4.75 2.62376C4.96551 2.5 5.2272 2.5 5.75057 2.5L8.24943 2.5C8.7728 2.5 9.03449 2.5 9.25 2.62376C9.46551 2.74752 9.59636 2.97293 9.85804 3.42376L11.1075 5.57624Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.1075 11.5762C21.3692 12.0271 21.5 12.2525 21.5 12.5C21.5 12.7475 21.3692 12.9729 21.1075 13.4238L19.858 15.5762C19.5964 16.0271 19.4655 16.2525 19.25 16.3762C19.0345 16.5 18.7728 16.5 18.2494 16.5H15.7506C15.2272 16.5 14.9655 16.5 14.75 16.3762C14.5345 16.2525 14.4036 16.0271 14.142 15.5762L12.8925 13.4238C12.6308 12.9729 12.5 12.7475 12.5 12.5C12.5 12.2525 12.6308 12.0271 12.8925 11.5762L14.142 9.42376C14.4036 8.97293 14.5345 8.74752 14.75 8.62376C14.9655 8.5 15.2272 8.5 15.7506 8.5L18.2494 8.5C18.7728 8.5 19.0345 8.5 19.25 8.62376C19.4655 8.74752 19.5964 8.97293 19.858 9.42376L21.1075 11.5762Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.1075 16.5762C11.3692 17.0271 11.5 17.2525 11.5 17.5C11.5 17.7475 11.3692 17.9729 11.1075 18.4238L9.85804 20.5762C9.59636 21.0271 9.46551 21.2525 9.25 21.3762C9.03449 21.5 8.7728 21.5 8.24943 21.5H5.75057C5.2272 21.5 4.96551 21.5 4.75 21.3762C4.53449 21.2525 4.40364 21.0271 4.14196 20.5762L2.89253 18.4238C2.63084 17.9729 2.5 17.7475 2.5 17.5C2.5 17.2525 2.63084 17.0271 2.89253 16.5762L4.14196 14.4238C4.40364 13.9729 4.53449 13.7475 4.75 13.6238C4.96551 13.5 5.2272 13.5 5.75057 13.5L8.24943 13.5C8.7728 13.5 9.03449 13.5 9.25 13.6238C9.46551 13.7475 9.59636 13.9729 9.85804 14.4238L11.1075 16.5762Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg></Link>

        
        
         <Link
          to="/tools"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 ${
            isActive('/keywords') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M17.0235 3.03358L16.0689 2.77924C13.369 2.05986 12.019 1.70018 10.9555 2.31074C9.89196 2.9213 9.53023 4.26367 8.80678 6.94841L7.78366 10.7452C7.0602 13.4299 6.69848 14.7723 7.3125 15.8298C7.92652 16.8874 9.27651 17.247 11.9765 17.9664L12.9311 18.2208C15.631 18.9401 16.981 19.2998 18.0445 18.6893C19.108 18.0787 19.4698 16.7363 20.1932 14.0516L21.2163 10.2548C21.9398 7.57005 22.3015 6.22768 21.6875 5.17016C21.0735 4.11264 19.7235 3.75295 17.0235 3.03358Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M16.8538 7.43306C16.8538 8.24714 16.1901 8.90709 15.3714 8.90709C14.5527 8.90709 13.889 8.24714 13.889 7.43306C13.889 6.61898 14.5527 5.95904 15.3714 5.95904C16.1901 5.95904 16.8538 6.61898 16.8538 7.43306Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M12 20.9463L11.0477 21.2056C8.35403 21.9391 7.00722 22.3059 5.94619 21.6833C4.88517 21.0608 4.52429 19.6921 3.80253 16.9547L2.78182 13.0834C2.06006 10.346 1.69918 8.97731 2.31177 7.89904C2.84167 6.96631 4 7.00027 5.5 7.00015" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>        </Link>
        
   

        {/* Display selected website's favicon */}
        {selectedWebsite && (
          <div className="relative absolute bottom-0 hidden">
            <div
              onClick={toggleDropdown}
              className="hover:text-slate-700 cursor-pointer dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3"
            >
              <img
                src={getFaviconUrl(selectedWebsite.url)}
                alt="Selected Website Favicon"
                className="w-full mr-2"
                onError={(e) => {
                  e.target.src = 'https://cdn-icons-png.flaticon.com/512/5339/5339181.png'; // Fallback if no favicon is available
                }}
              />
              
            </div>
			
			
			
			
            {/* Dropdown for website selection */}
            {dropdownOpen && (
              <div className="absolute w-64 bg-white dark:text-white dark:bg-slate-600 dark:border dark:border-slate-500 shadow  space-y-2 p-2 rounded-xl mt-2 z-50">
              <h3 className="mb-2 ml-2">Choose your project</h3>
                {error ? (
                  <div className="p-2 text-red-500">{error}</div>
                ) : websites.length > 0 ? (
                  websites.map((website) => (
                    <div
                      key={website.id}
                      className={`flex items-center space-x-2 px-3 py-2 rounded dark:hover:bg-slate-300 hover:bg-yellow-100 hover:text-black cursor-pointer ${
                        selectedWebsite.url === website.url ? 'dark:bg-slate-300 bg-yellow-100 text-black' : 'text-black dark:text-white'
                      }`}
                      onClick={() => handleWebsiteSelect(website)}
                    >
                      {/* Display favicon for each website */}
                      <img
                        src={getFaviconUrl(website.url)}
                        alt="favicon"
                        className="w-6 h-6 mr-2"
                        onError={(e) => {
                          e.target.src = 'https://cdn-icons-png.flaticon.com/512/5339/5339181.png'; // Fallback if no favicon is available
                        }}
                      />
                      <span>{website.url.replace(/^https?:\/\//, '')}</span> {/* Remove protocol */}
                    </div>
                  ))
                ) : (
                  <div className="p-2 text-gray-500">No websites available</div>
                )}
              </div>
            )}
          </div>
        )}

 <Link
          to="/settings"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 hidden ${
            isActive('/settings') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >

       
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
    <path d="M2.52992 14.394C2.31727 15.7471 3.268 16.6862 4.43205 17.1542C8.89481 18.9486 15.1052 18.9486 19.5679 17.1542C20.732 16.6862 21.6827 15.7471 21.4701 14.394C21.3394 13.5625 20.6932 12.8701 20.2144 12.194C19.5873 11.2975 19.525 10.3197 19.5249 9.27941C19.5249 5.2591 16.1559 2 12 2C7.84413 2 4.47513 5.2591 4.47513 9.27941C4.47503 10.3197 4.41272 11.2975 3.78561 12.194C3.30684 12.8701 2.66061 13.5625 2.52992 14.394Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 21C9.79613 21.6219 10.8475 22 12 22C13.1525 22 14.2039 21.6219 15 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>
</Link>
        
         <Link
          to="/settings"
          className={`hover:text-slate-700  dark:hover:bg-slate-600 dark:hover:text-slate-300 hover:bg-slate-300 rounded-xl p-3 ${
            isActive('/settings') ? 'dark:bg-slate-600 bg-slate-300 dark:text-white text-slate-900' : ''
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
    <path d="M15.5 12C15.5 13.933 13.933 15.5 12 15.5C10.067 15.5 8.5 13.933 8.5 12C8.5 10.067 10.067 8.5 12 8.5C13.933 8.5 15.5 10.067 15.5 12Z" stroke="currentColor" strokeWidth="1.5" />
    <path d="M20.7906 9.15201C21.5969 10.5418 22 11.2366 22 12C22 12.7634 21.5969 13.4582 20.7906 14.848L18.8669 18.1638C18.0638 19.548 17.6623 20.2402 17.0019 20.6201C16.3416 21 15.5402 21 13.9373 21L10.0627 21C8.45982 21 7.6584 21 6.99807 20.6201C6.33774 20.2402 5.93619 19.548 5.13311 18.1638L3.20942 14.848C2.40314 13.4582 2 12.7634 2 12C2 11.2366 2.40314 10.5418 3.20942 9.152L5.13311 5.83621C5.93619 4.45196 6.33774 3.75984 6.99807 3.37992C7.6584 3 8.45982 3 10.0627 3L13.9373 3C15.5402 3 16.3416 3 17.0019 3.37992C17.6623 3.75984 18.0638 4.45197 18.8669 5.83622L20.7906 9.15201Z" stroke="currentColor" strokeWidth="1.5" />
</svg>
        </Link>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="hover:text-slate-700 hover:bg-slate-300 rounded-xl p-3"
        >
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
    <path d="M6 6.50006C4.15875 8.14802 3 10.3345 3 13C3 17.9706 7.02944 22 12 22C16.9706 22 21 17.9706 21 13C21 10.3345 19.8412 8.14802 18 6.50006" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 2V11M12 2C11.2998 2 9.99153 3.9943 9.5 4.5M12 2C12.7002 2 14.0085 3.9943 14.5 4.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>
          </button>
          
          
          
          
          
      </nav>
    </div>
  );
};

export default Navbar;
