// src/components/Feedback.js

import React, { useState } from 'react';
import 'boxicons/css/boxicons.min.css'; // Ensure Boxicons are installed: npm install boxicons

const Feedback = () => {
  const [isSubmitting, setIsSubmitting] = useState(false); // Tracks if a request is in progress
  const [message, setMessage] = useState({ type: '', content: '' }); // Stores success or error messages

  // Define the mapping between button labels and feedback keys
  const ratings = [
    { label: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30} fill={"none"}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 11L8.5 9.5M8.5 9.5L7 8M8.5 9.5L7 11M8.5 9.5L10 8M17 11L15.5 9.5M15.5 9.5L14 8M15.5 9.5L14 11M15.5 9.5L17 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 15C8.91212 16.2144 10.3643 17 12 17C13.6357 17 15.0879 16.2144 16 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>, key: 'bad' },
    { label: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30}  fill={"none"}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.00897 9H8M16 9H15.991" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 16H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>, key: 'notbad' },
    { label: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30}  fill={"none"}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 15C8.91212 16.2144 10.3643 17 12 17C13.6357 17 15.0879 16.2144 16 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.00897 9L8 9M16 9L15.991 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>, key: 'good' },
    { label: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30}  fill={"none"}>
    <path d="M12.5 2.01228C12.3344 2.00413 12.1677 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.1368 21.8906 10.299 21.685 9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 15C8.91212 16.2144 10.3643 17 12 17C13.6357 17 15.0879 16.2144 16 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 9.5H8.70711C8.25435 9.5 7.82014 9.67986 7.5 10M14 9.5H15.2929C15.7456 9.5 16.1799 9.67986 16.5 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.8881 2.33117C16.8267 1.78287 17.6459 2.00383 18.138 2.35579C18.3398 2.50011 18.4406 2.57227 18.5 2.57227C18.5594 2.57227 18.6602 2.50011 18.862 2.35579C19.3541 2.00383 20.1733 1.78287 21.1119 2.33117C22.3437 3.05077 22.6224 5.42474 19.7812 7.42757C19.24 7.80905 18.9694 7.99979 18.5 7.99979C18.0306 7.99979 17.76 7.80905 17.2188 7.42757C14.3776 5.42474 14.6563 3.05077 15.8881 2.33117Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
</svg>, key: 'best' },
  ];

  // Function to handle rating clicks
  const handleRatingClick = async (ratingKey) => {
    setIsSubmitting(true);
    setMessage({ type: '', content: '' });

    // Define your namespace
    const namespace = 'seotic'; // Replace 'seotic' with your actual namespace

    if (!namespace || !ratingKey) {
      setMessage({ type: 'error', content: 'API configuration is missing.' });
      setIsSubmitting(false);
      return;
    }

    const apiUrl = `https://abacus.jasoncameron.dev/hit/${namespace}/${ratingKey}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET', // Abacus uses GET for the hit endpoint
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        // Since you don't want to display the count, omit accessing data.value
        setMessage({ type: 'success', content: 'Thank you for your feedback!' });

        // Optionally, reset the message after 5 seconds
        setTimeout(() => {
          setMessage({ type: '', content: '' });
        }, 1000);
      } else if (response.status === 429) { // Handle rate limiting
        const errorData = await response.json();
        setMessage({ type: 'error', content: `Rate limit exceeded. Please wait and try again. ${errorData.error}` });

        // Optionally, reset the message after 5 seconds
        setTimeout(() => {
          setMessage({ type: '', content: '' });
        }, 5000);
      } else {
        const errorData = await response.json();
        setMessage({ type: 'error', content: errorData.error || 'Failed to submit feedback. Please try again later.' });

        // Optionally, reset the message after 5 seconds
        setTimeout(() => {
          setMessage({ type: '', content: '' });
        }, 5000);
      }
    } catch (error) {
      console.error('Feedback Submission Error:', error);
      setMessage({ type: 'error', content: 'An error occurred while submitting your feedback.' });

      // Optionally, reset the message after 5 seconds
      setTimeout(() => {
        setMessage({ type: '', content: '' });
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-8">
      <p className="text-sm text-slate-300">Feedbacks</p>
      <h3 className="text-xl mb-5 dark:text-white">How is your expirence with Seotic?</h3>
      {/* Rating Buttons */}
      <div className="flex justify-center space-x-4">
        {ratings.map(({ label, key }) => (
          <button
            key={key}
            onClick={() => handleRatingClick(key)}
            disabled={isSubmitting}
            className={`p-2 flex items-center justify-center rounded-full text-xl font-bold ${
              isSubmitting ? 'bg-slate-300 dark:bg-slate-700 cursor-not-allowed' : 'bg-slate-50 dark:bg-slate-600 hover:bg-yellow-50 dark:hover:bg-yellow-500'
            } dark:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-yellow-500`}
            aria-label={`Rate ${label}`}
          >
            {label}
          </button>
        ))}
      </div>

      {/* Submission Feedback */}
      {message.content && (
        <div className={`p-3 w-full text-xs mt-2 rounded-full ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'} animate-fade-in`}>
          <i className={`bx ${message.type === 'success' ? 'bx-check-circle' : 'bx-error-circle'} bx-fw`}></i>
          {message.content}
        </div>
      )}
    </div>
  );
};

export default Feedback;
