// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';


const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');  // Check for JWT token

  // Function to check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token); // Decode the token
      if (decoded.exp * 1000 < Date.now()) {
        // Token expiration is in seconds, convert to milliseconds and compare with current time
        return true; // Token is expired
      }
      return false; // Token is still valid
    } catch (error) {
      console.error('Error decoding token:', error);
      return true; // Treat errors as expired tokens
    }
  };

  // If token doesn't exist or is expired, log the user out and redirect to login
  if (!token || isTokenExpired(token)) {
    localStorage.removeItem('token'); // Remove expired or non-existent token
    localStorage.removeItem('project'); // Optional: Clear the project as well
    return <Navigate to="/login" />;
  }

  // If token exists and is valid, allow access to the child component (e.g., UserPage)
  return children;
};

export default PrivateRoute;
