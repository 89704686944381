import React from 'react';

const SettingsButton = ({ onSettingsClick }) => {
  return (
    <button
      onClick={onSettingsClick}
      className="p-1 text-slate-300  dark:bg-slate-600 bg-slate-100  rounded-lg hover:bg-yellow-500 hover:text-white focus:outline-none"
    >
      <i className="bx bx-cog bx-fw"></i>
    </button>
  );
};

export default SettingsButton;
