import React, { useState, useEffect } from 'react';
import TimeAgo from './components/TimeAgo';
import Tooltip from './components/Tooltip'; // Import the Tooltip component
import Notification from './components/Notification'; // Adjust path as necessary
import LoadingScreen from './components/LoadingScreen';

import InternalTable from './components/InternalLinksTable';
const WebAuditPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState(''); // Store URL from query parameter
  const [mobileData, setMobileData] = useState(null); // Store mobile audit data
  const [desktopData, setDesktopData] = useState(null); // Store desktop audit data
  const [onPageData, setOnPageData] = useState(null); // Store on-page SEO audit data
  const [viewMode, setViewMode] = useState('mobile'); // Toggle between mobile and desktop
  const [selectedTab, setSelectedTab] = useState('onPageSEO'); // Track active tab
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(''); // Error state
  const [expandedIssueIndex, setExpandedIssueIndex] = useState(null); // State to track which issue is expanded
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const itemsPerPage = 10; // Number of items per page
    const [project, setProject] = useState(null); // Store project object
    const timestamp = new Date().getTime();
 const [showOffCanvas, setShowOffCanvas] = useState(false); // State for Off-Canvas visibility
  useEffect(() => {
    // Simulate a delay for demonstration (e.g., fetching data)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Replace 2000 with the duration you want the loader to show

    // Cleanup the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  // Custom Hook for Count Animation
  const useCountAnimation = (endValue, duration = 2000) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
      // Convert endValue to a number
      const end = Number(endValue);
      if (isNaN(end)) {
        setValue(0);
        return;
      }

      let start = 0;
      const increment = end / (duration / 20); // Update every 20ms

      let timer;

      const animate = () => {
        start += increment;
        if (start >= end) {
          setValue(end.toFixed(0)); // Ensure integer
          clearInterval(timer);
        } else {
          setValue(start.toFixed(0));
        }
      };

      timer = setInterval(animate, 20);

      // Clean up the interval on unmount or when endValue changes
      return () => clearInterval(timer);
    }, [endValue, duration]);

    return value;
  };

  // Google Lighthouse API Key (Optional)
  const apiKey = 'AIzaSyBLp1KOUGA8FSS0U4KNNnvFwJD12VJngFI'; // Replace with your API key

 // Fetch URL from localStorage and construct full URL
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const internalParam = queryParams.get('q'); // Get the 'internal' query parameter

    // Get project data from localStorage
    const projectData = JSON.parse(localStorage.getItem('project'));

    if (projectData && projectData.url) {
      const mainUrl = projectData.url;
      setProject(projectData); // Set project data in state

      let fullUrl = mainUrl;

      if (internalParam) {
        // Ensure proper concatenation without double slashes
        if (mainUrl.endsWith('/') && internalParam.startsWith('/')) {
          fullUrl = mainUrl + internalParam.substring(1);
        } else if (!mainUrl.endsWith('/') && !internalParam.startsWith('/')) {
          fullUrl = mainUrl + '/' + internalParam;
        } else {
          fullUrl = mainUrl + internalParam;
        }
      }

      setUrl(fullUrl);
    } else {
      setError('No project selected. Please choose a project.');
    }
  }, []);

  useEffect(() => {
    if (url) {
      fetchAuditData();
    }
  }, [url]);

  const fetchAuditData = async () => {
    setLoading(true);
    if (!url) {
      setError('No URL specified. Please provide a URL as a query parameter.');
      setLoading(false);
      return;
    }
    try {
      // Fetch data from APIs
      const { mobileData, desktopData } = await handleAudit(url);
      const onPageData = await handleOnPageSEO(url);
      setMobileData(mobileData.lighthouseResult || mobileData);
      setDesktopData(desktopData.lighthouseResult || desktopData);
      setOnPageData(onPageData);
    } catch (error) {
      setError('Failed to fetch data from APIs.');
    } finally {
      setLoading(false);
    }
  };

  const handleAudit = async (url) => {
    try {
      const baseURL = 'https://www.googleapis.com/pagespeedonline/v5/runPagespeed';
      const categories = '&category=performance&category=seo&category=accessibility';

      const [mobileResponse, desktopResponse] = await Promise.all([
        fetch(`${baseURL}?url=${encodeURIComponent(url)}&key=${apiKey}&strategy=mobile${categories}`),
        fetch(`${baseURL}?url=${encodeURIComponent(url)}&key=${apiKey}&strategy=desktop${categories}`),
      ]);

      if (!mobileResponse.ok || !desktopResponse.ok) {
        throw new Error(
          `Lighthouse API failed. Mobile status: ${mobileResponse.status}, Desktop status: ${desktopResponse.status}`
        );
      }

      const mobileData = await mobileResponse.json();
      const desktopData = await desktopResponse.json();

      return { mobileData, desktopData };
    } catch (error) {
      setError('Failed to perform web audit [ref : TR-04]');
      throw error;
    }
  };

  const handleOnPageSEO = async (url) => {
    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/seo-audit.php?_=${timestamp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });

      if (!response.ok) {
        throw new Error(`Failed to connect [ref : DN-05]`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      setError('Failed to fetch [ref : DN-06]');
      throw error;
    }
  };

  // Handle the Recrawl button click
  const handleRecrawl = () => {
    fetchAuditData();
  };

  // Define the variables correctly
  const currentData = viewMode === 'mobile' ? mobileData : desktopData;

  const parseDisplayValue = (value) => {
    if (typeof value === 'string') {
      const num = parseFloat(value.replace(/[^0-9.]/g, ''));
      return isNaN(num) ? 0 : num;
    }
    return 0;
  };

  const getScores = (data) => {
    const lighthouseResult = data?.lighthouseResult || data;
    const categories = lighthouseResult?.categories;
    const audits = lighthouseResult?.audits;

    if (!categories || !audits) {
      return {
        performance: 0,
        accessibility: 0,
        seo: 0,
        overall: 0,
        speed: 0,
        loadTime: 0,
        interactivity: 0,
        visualStability: 0,
      };
    }

    const performance = (categories.performance?.score || 0) * 100;
    const accessibility = (categories.accessibility?.score || 0) * 100;
    const seo = (categories.seo?.score || 0) * 100;

    // Parse display values to get numeric values
    const speedValue = audits['speed-index']?.displayValue || '0';
    const speed = parseDisplayValue(speedValue);

    const loadTimeValue = audits['first-contentful-paint']?.displayValue || '0';
    const loadTime = parseDisplayValue(loadTimeValue);

    const interactivityValue = audits['interactive']?.displayValue || '0';
    const interactivity = parseDisplayValue(interactivityValue);

    const visualStabilityValue = audits['cumulative-layout-shift']?.displayValue || '0';
    const visualStability = parseDisplayValue(visualStabilityValue);

    const overall = Math.round((performance + accessibility + seo) / 3);

    return {
      performance,
      accessibility,
      seo,
      overall,
      speed,
      loadTime,
      interactivity,
      visualStability,
    };
  };

  const scores = currentData
    ? getScores(currentData)
    : {
        performance: 0,
        accessibility: 0,
        seo: 0,
        overall: 0,
        speed: 0,
        loadTime: 0,
        interactivity: 0,
        visualStability: 0,
      };

  const getIssues = (data) => {
    const lighthouseResult = data?.lighthouseResult || data;
    const audits = lighthouseResult?.audits;

    if (!audits) return [];

    return Object.values(audits)
      .filter((audit) => audit.score !== 1 && audit.score !== undefined)
      .map((audit) => ({
        title: audit.title,
        description: audit.description,
        displayValue: audit.displayValue || '',
        score: audit.score,
      }));
  };

  const issues = currentData ? getIssues(currentData) : [];

  // Toggle the expansion of an issue by its index
  const toggleIssueDetail = (index) => {
    if (expandedIssueIndex === index) {
      setExpandedIssueIndex(null); // Collapse if the same issue is clicked again
    } else {
      setExpandedIssueIndex(index); // Expand the selected issue
    }
  };

  // Calculate the start and end index for slicing the issues array based on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentIssues = issues.slice(indexOfFirstItem, indexOfLastItem); // Get the current page's items

  // Calculate total pages
  const totalPages = Math.ceil(issues.length / itemsPerPage);

  // Function to change the current page
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setExpandedIssueIndex(null); // Collapse all issues when changing page
  };

  // Utility function to format descriptions with custom links
  const formatIssueDescription = (description) => {
    // Regular expression to find patterns like [text](url)
    const linkPattern = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;

    // Replace [text](url) with <a href="url" target="_blank" className="text-yellow-500">text</a>
    const formattedDescription = description.replace(linkPattern, (match, text, url) => {
      return `<a href="${url}" target="_blank" className="text-yellow-500">${text}</a>`;
    });

    return formattedDescription;
  };

  // Helper function to determine icon and text color based on assessment
  const getAssessmentIconAndColor = (assessment) => {
    if (!assessment) {
      return {
        icon: <i className="text-gray-500 bx bx-circle bx-fw mr-1"></i>,
        textColor: 'text-gray-600',
      };
    } else {
      const passedAssessments = ['perfect', 'good', 'declared', 'provided', 'present', 'yes', 'enabled'];
      const isPassed = passedAssessments.includes(assessment.toLowerCase());

      if (isPassed) {
        return {
          icon: <i className="bx bx-check-circle bx-fw text-green-500 mr-1"></i>,
          textColor: 'text-green-600',
        };
      } else {
        return {
          icon: <i className="text-red-500 bx bx-error-circle bx-fw mr-1"></i>,
          textColor: 'text-red-600',
        };
      }
    }
  };

  // Render functions for SEO items
  const renderSeoItem = (label, data) => {
    if (!data) return null;

    const { icon } = getAssessmentIconAndColor(data.assessment);

    return (
      <div className="mb-4 border-b border-slate-100 dark:border-slate-600 pb-4 grid grid-cols-5 px-10">
        <div className="flex items-center ">
          <h4 className={`text-xl`}>{icon}{label}</h4>
        </div>
        {/* Display the content */}
        {typeof data.content === 'object' && data.content !== null ? (
          <div className="col-span-4">
            {Object.entries(data.content).map(([key, value]) => (
              <p key={key}>
                {key.toUpperCase()}: {Array.isArray(value) ? value.join(', ') : value}
              </p>
            ))}
          </div>
        ) : (
          <p className="text-slate-500 dark:text-slate-400 col-span-4 flex items-start">{data.content || 'Nothing to show.'}</p>
        )}
      </div>
    );
  };

  // Render function for Headings
  const renderHeadings = (headings) => {
    if (!headings) return null;

    const { icon } = getAssessmentIconAndColor(headings.assessment);

    return (
      <div className="mb-4 border-b border-slate-100 dark:border-slate-600 pb-4 grid grid-cols-5 px-10">
        <div className="flex items-center ">
          <h4 className={`text-xl`}>{icon}Headings</h4>
        </div>
        <div className="col-span-4">
          {headings.content && headings.content.h1 && (
            <p className="text-slate-500 dark:text-slate-400">H1: {headings.content.h1}</p>
          )}
          {headings.content && headings.content.h2 && (
            <p className="text-slate-500 dark:text-slate-400">H2: {headings.content.h2.join(', ')}</p>
          )}
          {headings.content && headings.content.h3 && (
            <p className="text-slate-500 dark:text-slate-400">H3: {headings.content.h3.join(', ')}</p>
          )}
          {/* Add H4, H5, H6 if needed */}
        </div>
      </div>
    );
  };

  // Render function for In-Page Links
  const renderInPageLinks = (internal_links, external_links) => {
    return (
      <div className="mb-4 border-b border-slate-100 dark:border-slate-600 pb-4 grid grid-cols-5 px-10">
        <div className="flex items-center ">
          <h4 className={`text-xl`}>
            <i className="bx bx-link-alt bx-fw text-slate-500 mr-1"></i>In-Page Links
          </h4>
        </div>
        <div className="col-span-4">
          <p className="text-slate-500 dark:text-slate-400">Internal Links: {internal_links?.content?.length || 0}</p>
          <p className="text-slate-500 dark:text-slate-400">External Links: {external_links?.content?.length || 0}</p>
        </div>
      </div>
    );
  };

  // Render function for HTTP Requests
  const renderHttpRequests = (http_requests) => {
    if (!http_requests) return null;

    const { icon } = getAssessmentIconAndColor(http_requests.assessment);

    return (
      <div className="mb-4 border-b border-slate-100 dark:border-slate-600 pb-4 grid grid-cols-5 px-10">
        <div className="flex items-center ">
          <h4 className={`text-xl`}>{icon}HTTP Requests</h4>
        </div>
        <div className="col-span-4">
          <p className="text-slate-500 dark:text-slate-400">Total Requests: {http_requests.content}</p>
        </div>
      </div>
    );
  };

    // Updated renderInlineCss function
  const renderInlineCss = (inlineCss) => {
    if (!inlineCss) return null;

    const { icon, textColor } = getAssessmentIconAndColor(inlineCss.assessment);

    return (
      <div className="mb-4 border-b pb-4 px-10 grid grid-cols-5 border-slate-100 dark:border-slate-600">
        <div className="mb-2 col-span-1">
          
          <h4 className={`text-xl `}>{icon}Inline CSS</h4>
        </div>
       
       <div className="col-span-4">
        <p className="text-slate-500 dark:text-white">You have <p className="inline mx-1 px-3 py-1 text-sm rounded-full bg-yellow-500 text-white">{inlineCss.count}</p> Inline CSS</p>
        {inlineCss.styles && inlineCss.styles.length > 0 && (
          <div className="mt-4 rounded-lg border p-5 px-10 border-slate-100 dark:border-slate-600">
          
            <ul className="list-decimal px-5 ">
              {inlineCss.styles.map((style, index) => (
                <li className="text-yellow-500 mb-2" key={index}><p className="break-words text-black dark:text-white">{style}</p></li>
              ))}
            </ul>
          </div>
        )}
        </div>
      </div>
    );
  };


  // Render function for Structured Data
  const renderStructuredData = (structured_data) => {
    if (!structured_data) return null;

    const { icon } = getAssessmentIconAndColor(structured_data.assessment);

    return (
      <div className="mb-4 border-b border-slate-100 dark:border-slate-600 pb-4 grid grid-cols-5 px-10">
        <div className="flex items-center ">
          <h4 className={`text-xl`}>{icon}Structured Data</h4>
        </div>
        <div className="col-span-4">
          <p className="text-slate-500 dark:text-slate-400">{structured_data.content}</p>
        </div>
      </div>
    );
  };

  // Render function for Social Links
  const renderSocialLinks = (social_links) => {
    if (!social_links) return null;

    const { icon } = getAssessmentIconAndColor(social_links.assessment);

    return (
      <div className="mb-4 border-b border-slate-100 dark:border-slate-600 pb-4 grid grid-cols-5 px-10">
        <div className="flex items-center ">
          <h4 className={`text-xl`}>{icon}Social Links</h4>
        </div>
        <div className="col-span-4">
          <p className="text-slate-500 dark:text-slate-400">{social_links.content?.join(', ') || 'No social links found.'}</p>
        </div>
      </div>
    );
  };

  const defaultHttps = {
    content: "No",
  };

  const https_encryption = onPageData?.https_encryption || defaultHttps;

  const defaultSize = {
    content: 0,
  };

  const page_size = onPageData?.page_size?.content || 0;

  const defaultAuditStatistics = {
    total_checks: 0,
    passed: 0,
    errors: 0,
    needs_improvement: 0,
    percentage_passed: 0,
  };

  const audit_statistics = onPageData?.audit_statistics || defaultAuditStatistics;

  const totalChecks = audit_statistics.total_checks || 0;
  const passedPercentage = totalChecks
    ? ((audit_statistics.passed / totalChecks) * 100).toFixed(2)
    : 0;
  const errorsPercentage = totalChecks
    ? ((audit_statistics.errors / totalChecks) * 100).toFixed(2)
    : 0;
  const improvementPercentage = totalChecks
    ? ((audit_statistics.needs_improvement / totalChecks) * 100).toFixed(2)
    : 0;

  // Use the custom hook for each score
  const animatedOverall = useCountAnimation(scores.overall);
  const animatedPerformance = useCountAnimation(scores.performance);
  const animatedAccessibility = useCountAnimation(scores.accessibility);
  const animatedSEO = useCountAnimation(scores.seo);
  const animatedSpeed = useCountAnimation(scores.speed);
  const animatedTime = useCountAnimation(scores.loadTime);
  const animatedInteractivity = useCountAnimation(scores.interactivity);
  const animatedVisual = useCountAnimation(scores.visualStability);
  const animatedAudit = useCountAnimation(audit_statistics.percentage_passed);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  
  const toggleOffCanvas = () => setShowOffCanvas(!showOffCanvas); // Toggle Off-Canvas visibility

  return (
    <>
      {/* Show LoadingScreen component while loading */}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="min-h-screen p-10 mx-auto container">
          {/* Header Section */}
          <div className="w-full py-10 flex items-center relative">
            <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
              Internal Page Audit
              <div className="text-sm text-slate-500 mt-2 lowercase">
                <i className="bx bx-folder bx-fw"></i> {url ? url.replace(/^(www\.)?/, '') : 'N/A'}
              </div>
              
              {/* Off-Canvas Button */}
              <div className="absolute right-0 bottom-10 text-sm">
                <button
                  onClick={toggleOffCanvas}
                  className="text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300 flex items-center"
                >
                 Internal Links List
                </button>
              </div>
            </div>
          </div>

          {/* Off-Canvas for Internal Links */}
          {showOffCanvas && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-end" >
              <div className="bg-white dark:bg-gray-800 shadow-lg w-1/3 h-full overflow-y-auto">
                <button
                  onClick={toggleOffCanvas}
                 className="absolute top-3 right-3 text-slate-500 hover:text-slate-700"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>

                </button>
                
                <InternalTable mainUrl={url} token={localStorage.getItem('token')} />
              </div>
            </div>
          )}
          <div className="">
            {error && (
              <div className="flex items-center justify-center my-10 text-center">
                <p className="w-96  mt-4  mb-10 dark:text-white">
                  <i className="bx bx-error-circle bx-rotate-180 bx-lg text-red-500"></i>
                  <h3 className="text-4xl mb-2">Oh snap!</h3>
                  Something went wrong, please try again later or tell us what was wrong{' '}
                  <a className="text-yellow-500" href="https://tally.so/r/nrdakL" target="_blank" rel="noopener noreferrer">
                    here
                  </a>
                  <p className="text-sm text-slate-500 mt-5">{error}</p>
                </p>
              </div>
            )}

            {loading && (
              <div className="flex items-center justify-center my-10 text-center dark:text-white">
                <p className="w-96  mt-4  mb-10">
                  <i className="bx bx-loader-alt bx-spin bx-rotate-180 bx-lg text-yellow-500"></i>
                  <h3 className="text-4xl mb-2">Preparing your data</h3>
                  getting your website data and calculation...
                </p>
              </div>
            )}

            {/* Display Scores and Page Speed */}
            {currentData && (
              <div className="">
                {/* Include your code for displaying scores, progress bars, and other metrics here */}
                {/* Ensure all JSX elements are properly opened and closed */}
                {/* For example: */}
                {/* The code for displaying the scores and metrics, as shown earlier */}
                {/* ... */}
                {/* Start of Scores and Metrics */}
                <div className="grid grid-cols-4 gap-5">
                  {/* Overall Score */}
                  <div className="bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-center p-10 relative">
                    <div className="relative">
                      {/* Background Half-Circle with Gradient */}
                      <div className="relative flex justify-center items-center mt-12 z-10">
                        <div
                          className={`blur-md h-32 w-64 transform -translate-y-1/2
                          ${
                            scores.overall >= 80
                              ? 'bg-gradient-to-t from-white dark:from-slate-700 to-green-300 dark:to-green-700'
                              : scores.overall >= 50
                              ? 'bg-gradient-to-t from-white dark:from-slate-700 to-yellow-300 dark:to-yellow-700'
                              : 'bg-gradient-to-t from-white dark:from-slate-700 to-red-300 dark:to-red-700'
                          }
                          rounded-tl-full rounded-tr-full absolute top-5 z-[-10]`}
                        ></div>
                        <p className="text-xs uppercase text-white px-5 py-2 z-20 opacity-75 relative bg-slate-900 dark:bg-slate-800 rounded-full">
                          <i className="bx bxs-grid bx-fw"></i>Overall
                        </p>
                      </div>

                      {/* Text Elements */}
                      <p className="text-8xl font-bold z-20 dark:text-white relative">
                        {animatedOverall.toString().padStart(2, '0')}
                      </p>
                      <p className="text-slate-500 dark:text-slate-400 text-sm">out of 100</p>
                    </div>
                  </div>

                  {/* On-Page Audit Statistics */}
                  <div className="bg-white dark:bg-slate-700 dark:border-slate-600 border border-slate-200 col-span-2 rounded-lg text-center flex items-center justify-center">
                    {audit_statistics && (
                      <div className="grid grid-cols-2 w-full">
                        <div className="relative">
                          {/* Background Half-Circle with Gradient */}
                          <div className="relative flex justify-center items-center mt-12  z-10">
                            <div
                              className={`blur-md h-32 w-64 transform -translate-y-1/2
                              ${
                                parseFloat(audit_statistics.percentage_passed) > 90
                                  ? 'bg-gradient-to-t from-white dark:from-slate-700 to-green-300 dark:to-green-700'
                                  : parseFloat(audit_statistics.percentage_passed) > 60
                                  ? 'bg-gradient-to-t from-white dark:from-slate-700 to-yellow-300 dark:to-yellow-700'
                                  : 'bg-gradient-to-t from-white dark:from-slate-700 to-red-300 dark:to-red-700'
                              }
                              rounded-tl-full rounded-tr-full absolute top-5 z-[-10]`}
                            ></div>

                            {/* Text Elements */}
                            <p className="text-xs uppercase text-white px-5 py-2 z-20 opacity-75 relative bg-slate-900 dark:bg-slate-800 rounded-full">
                              <i className="bx bxs-chart bx-fw"></i>On Page
                            </p>
                          </div>
                          <p className="text-8xl font-bold z-20 dark:text-white relative">
                            {animatedAudit.toString().padStart(2, '0')}
                          </p>
                          <p className="text-slate-500 dark:text-slate-400 text-sm">out of 100</p>
                        </div>

                        <div className="flex items-center">
                          <div className="px-10 w-full">
                            {/* Progress Bars */}
                            {/* Passed */}
                            <div className="mb-4 ">
                              <div className="flex justify-between text-slate-500 dark:text-slate-400 text-sm">
                                <span>Passed: {audit_statistics.passed}/{totalChecks}</span>
                                <span>{passedPercentage}%</span>
                              </div>
                              <div className="w-full bg-slate-200 dark:bg-slate-800 rounded-full h-1 mt-1">
                                <div
                                  className="bg-green-500 h-1 rounded-full"
                                  style={{ width: `${passedPercentage}%` }}
                                ></div>
                              </div>
                            </div>

                            {/* Errors */}
                            <div className="mb-4">
                              <div className="flex justify-between text-slate-500 dark:text-slate-400 text-sm">
                                <span>Errors: {audit_statistics.errors}/{totalChecks}</span>
                                <span>{errorsPercentage}%</span>
                              </div>
                              <div className="w-full bg-slate-200 dark:bg-slate-800 rounded-full h-1 mt-1">
                                <div
                                  className="bg-red-500 h-1 rounded-full"
                                  style={{ width: `${errorsPercentage}%` }}
                                ></div>
                              </div>
                            </div>

                            {/* Needs Improvement */}
                            <div className="mb-4">
                              <div className="flex justify-between text-slate-500 dark:text-slate-400 text-sm">
                                <span>Improvement: {audit_statistics.needs_improvement}/{totalChecks}</span>
                                <span>{improvementPercentage}%</span>
                              </div>
                              <div className="w-full bg-slate-200 dark:bg-slate-800 rounded-full h-1 mt-1">
                                <div
                                  className="bg-yellow-500 h-1 rounded-full"
                                  style={{ width: `${improvementPercentage}%` }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Page Speed */}
                  <div className="bg-white dark:text-white border border-slate-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg text-center  flex flex-wrap items-center justify-center">
                    <div className="relative">
                      {/* Background Half-Circle with Gradient */}
                      <div className="relative flex justify-center items-center mt-12 z-10">
                        <div
                          className={`blur-md h-32 w-64 transform -translate-y-1/2
                          ${
                            parseFloat(scores.speed) < 3
                              ? 'bg-gradient-to-t from-white dark:from-slate-700 to-green-300 dark:to-green-700'
                              : parseFloat(scores.speed) < 5
                              ? 'bg-gradient-to-t from-white dark:from-slate-700 to-yellow-300 dark:to-yellow-700'
                              : 'bg-gradient-to-t from-white dark:from-slate-700 to-red-300 dark:to-red-700'
                          }
                          rounded-tl-full rounded-tr-full absolute top-5 z-[-10]`}
                        ></div>

                        {/* Text Elements */}
                        <p className="text-xs uppercase text-white px-5 py-2 z-20 opacity-75 relative bg-slate-900 dark:bg-slate-800 rounded-full">
                          <i className="bx bxs-time bx-fw"></i>Page Speed
                        </p>
                      </div>
                      <p className="text-8xl font-bold z-20 relative">
                        {animatedSpeed.toString().padStart(1, '0')} <span className="text-4xl -ml-5">s</span>
                      </p>
                      <p className="text-slate-500 dark:text-slate-400 text-sm">under 3s is best</p>
                    </div>
                  </div>

                  {/* Additional Metrics */}
                  {/* Performance and Accessibility */}
                  <div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 dark:text-white rounded-lg">
                    <div className="flex w-full border-b border-slate-100 dark:border-slate-600 px-10 py-5">
                      <p id="performance" className="w-full flex">
                        <svg
                          className="mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={24}
                          height={24}
                          fill={'none'}
                        >
                          <path
                            d="M4.31802 19.682C3 18.364 3 16.2426 3 12C3 7.75736 3 5.63604 4.31802 4.31802C5.63604 3 7.75736 3 12 3C16.2426 3 18.364 3 19.682 4.31802C21 5.63604 21 7.75736 21 12C21 16.2426 21 18.364 19.682 19.682C18.364 21 16.2426 21 12 21C7.75736 21 5.63604 21 4.31802 19.682Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 14L9.79289 11.2071C10.1834 10.8166 10.8166 10.8166 11.2071 11.2071L12.7929 12.7929C13.1834 13.1834 13.8166 13.1834 14.2071 12.7929L17 10"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Performance{' '}
                      </p>
                      <Tooltip
                        forid="performance"
                        message="Performance is how efficiently a website functions, affecting speed, responsiveness, and user satisfaction. Faster, optimized sites offer better experiences and higher rankings."
                        position="top"
                      />

                      <p
                        className={`text-right w-fit ${
                          scores.performance >= 80
                            ? 'text-green-500'
                            : scores.performance >= 50
                            ? 'text-yellow-500'
                            : 'text-red-500'
                        }`}
                      >
                        {animatedPerformance.toString().padStart(2, '0')}
                      </p>
                    </div>

                    <div className="flex w-full  px-10 py-5">
                      <p id="accessibility" className="w-full">
                        <i className="bx bx-pointer bx-fw"></i>Accessibility{' '}
                      </p>
                      <Tooltip
                        forid="accessibility"
                        message="Accessibility ensures a website is usable by everyone, including people with disabilities. It involves design, structure, and content that support screen readers, keyboard navigation, and other assistive tools."
                        position="top"
                      />
                      <p
                        className={`text-right w-fit ${
                          scores.accessibility >= 80
                            ? 'text-green-500'
                            : scores.accessibility >= 50
                            ? 'text-yellow-500'
                            : 'text-red-500'
                        }`}
                      >
                        {animatedAccessibility.toString().padStart(2, '0')}
                      </p>
                    </div>
                  </div>

                  {/* Interactivity and Visual Stability */}
                  <div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 dark:text-white rounded-lg">
                    <div className="flex w-full border-b border-slate-100 dark:border-slate-600 px-10 py-5">
                      <p id="interactivity" className="w-full">
                        <i className="bx bx-shape-square bx-fw"></i>Interactivity
                      </p>
                      <Tooltip
                        forid="interactivity"
                        message="Interactivity refers to how users engage with a website, like clicking buttons, filling forms, or navigating menus. Good interactivity makes a site dynamic, responsive, and engaging."
                        position="top"
                      />
                      <p
                        className={`text-right w-fit ${
                          scores.interactivity >= 80
                            ? 'text-green-500'
                            : scores.interactivity >= 50
                            ? 'text-yellow-500'
                            : 'text-red-500'
                        }`}
                      >
                        {animatedInteractivity.toString().padStart(2, '0')}
                      </p>
                    </div>

                    <div className="flex w-full px-10 py-5">
                      <p id="visual" className="w-full">
                        <i className="bx bx-glasses-alt bx-fw"></i>Visual Stability
                      </p>
                      <Tooltip
                        forid="visual"
                        message="Visual stability means elements on a webpage don’t shift unexpectedly as it loads. It ensures a smooth, consistent experience and prevents accidental clicks or disorientation for users."
                        position="top"
                      />
                      <p
                        className={`text-right w-fit ${
                          scores.visualStability >= 0.1
                            ? 'text-yellow-500'
                            : scores.visualStability >= 0.5
                            ? 'text-yellow-500'
                            : 'text-green-500'
                        }`}
                      >
                        {animatedVisual.toString().padStart(2, '0')}
                      </p>
                    </div>
                  </div>

                  {/* SEO and Load Time */}
                  <div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 dark:text-white rounded-lg">
                    <div className="flex w-full border-b border-slate-100 dark:border-slate-600 px-10 py-5">
                      <p id="seo" className="w-full">
                        <i className="bx bx-window-alt bx-fw"></i>SEO
                      </p>
                      <Tooltip
                        forid="seo"
                        message="Website's optimization for search engines. It evaluates factors like content quality, keywords, page speed, and backlinks, indicating how well a site can rank in search results."
                        position="top"
                      />
                      <p
                        className={`text-right w-fit ${
                          scores.seo >= 80
                            ? 'text-green-500'
                            : scores.seo >= 50
                            ? 'text-yellow-500'
                            : 'text-red-500'
                        }`}
                      >
                        {animatedSEO.toString().padStart(2, '0')}
                      </p>
                    </div>

                    <div className="flex px-10 py-5">
                      <p id="load" className="w-full">
                        <i className="bx bx-timer bx-fw"></i>Page load
                      </p>
                      <Tooltip
                        forid="load"
                        message="Page load time is the duration it takes for a webpage to fully display its content. Faster load times improve user experience and SEO."
                        position="top"
                      />
                      <p
                        className={`text-right w-fit ${
                          scores.loadTime >= 80
                            ? 'text-green-500'
                            : scores.loadTime >= 50
                            ? 'text-yellow-500'
                            : 'text-red-500'
                        }`}
                      >
                        {animatedTime.toString().padStart(2, '0')}s
                      </p>
                    </div>
                  </div>

                  {/* Page Size and HTTPS Encryption */}
                  <div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 dark:text-white rounded-lg">
                    <div className="flex w-full border-b border-slate-100 dark:border-slate-600 px-10 py-5">
                      <p id="size" className="w-full">
                        <i className="bx bx-file bx-fw"></i>Page size
                      </p>
                      <Tooltip
                        forid="size"
                        message="Page size is the total amount of data in KB needed to load a webpage, including images, scripts, and styles. Smaller page sizes lead to faster load times."
                        position="top"
                      />
                      <p
                        className={`text-right w-full ${
                          page_size >= 5000
                            ? 'text-red-500'
                            : page_size >= 1000
                            ? 'text-yellow-500'
                            : 'text-green-500'
                        }`}
                      >
                        {page_size.toString().padStart(2, '0')} KB
                      </p>
                    </div>

                    <div className="flex w-full  px-10 py-5">
                      <p id="secure" className="w-full">
                        <i className="bx bx-lock-alt bx-fw"></i>Secure
                      </p>
                      <Tooltip
                        forid="secure"
                        message="HTTPS (Hypertext Transfer Protocol Secure) encrypts data exchanged between a website and its users, ensuring security and privacy. It helps protect against data breaches and boosts SEO rankings."
                        position="top"
                      />
                      <p
                        className={`text-right w-fit ${
                          https_encryption.content === 'Yes'
                            ? 'text-green-500'
                            : https_encryption.content === 'No'
                            ? 'text-red-500'
                            : 'text-slate-500'
                        }`}
                      >
                        {https_encryption.content}
                      </p>
                    </div>
                  </div>
                </div>
                {/* End of Scores and Metrics */}
              </div>
            )}

            {currentData && (
              <>
                {/* Toggle between Mobile and Desktop */}
                <div className="flex justify-between w-full my-5">
                  <div className="flex justify-start space-x-4 bg-slate-100 dark:bg-slate-800 rounded-lg w-fit">
                    <button
                      type="button"
                      onClick={() => setViewMode('mobile')}
                      className={`px-5 py-2 rounded-md  transition-all duration-300 ease-in-out transform ${
                        viewMode === 'mobile'
                          ? 'bg-white dark:bg-slate-700 dark:border-slate-600 border border-slate-200 dark:text-white text-slate-500 '
                          : 'bg-slate-100  dark:bg-slate-800 dark:border-slate-700 border border-slate-100 dark:text-white text-slate-500 '
                      }`}
                    >
                      <i className="bx bx-mobile bx-fw"></i>Mobile
                    </button>
                    <button
                      type="button"
                      onClick={() => setViewMode('desktop')}
                      className={`px-5 py-2 rounded-md transition-all duration-300 ease-in-out transform  ${
                        viewMode === 'desktop'
                          ? 'bg-white dark:bg-slate-700 dark:border-slate-600 border border-slate-200 dark:text-white text-slate-500 '
                          : 'bg-slate-100  dark:bg-slate-800 dark:border-slate-700 border border-slate-100 dark:text-white text-slate-500 '
                      }`}
                    >
                      <i className="bx bx-desktop bx-fw"></i>Desktop
                    </button>
                  </div>

                  {/* Tabs for Issues and On-Page SEO Data */}
                  <div className="flex justify-end space-x-4 rounded-lg w-fit">
                    <button
                      type="button"
                      onClick={() => setSelectedTab('onPageSEO')}
                      className={`py-2 px-5 rounded-md transition-all duration-300 ease-in-out transform ${
                        selectedTab === 'onPageSEO'
                          ? 'bg-white dark:bg-slate-700 dark:border-slate-600 border border-slate-200 dark:text-white text-slate-500 '
                          : 'bg-slate-100  dark:bg-slate-800 dark:border-slate-700 border border-slate-100 dark:text-white text-slate-500 '
                      }`}
                    >
                      <i className="bx bx-search-alt-2 bx-fw"></i>Seotic Audit
                    </button>

                    <button
                      type="button"
                      onClick={() => setSelectedTab('issues')}
                      className={`py-2 px-5  rounded-md transition-all duration-300 ease-in-out transform ${
                        selectedTab === 'issues'
                          ? 'bg-white dark:bg-slate-700 dark:border-slate-600 border border-slate-200 dark:text-white text-slate-500 '
                          : 'bg-slate-100  dark:bg-slate-800 dark:border-slate-700 border border-slate-100 dark:text-white text-slate-500 '
                      }`}
                    >
                      <i className="bx bxl-google bx-fw"></i> Lighthouse
                    </button>
                  </div>
                </div>

                {/* Display Data Based on Selected Tab */}
                {selectedTab === 'onPageSEO' ? (
                  <div className="bg-white dark:bg-slate-700 dark:text-white rounded-lg">
                    {/* Rendering the On-Page SEO data */}
                    <h3 className="text-xl px-10 py-10 border-b border-slate-100 dark:border-slate-600">
                      <i className="bx bx-search-alt bx-fw"></i>Seotic Audit{' '}
                      <i
                        id="seotic"
                        className="bx bxs-help-circle bx-xs text-slate-300 hover:text-slate-500"
                      ></i>

                      <Tooltip
                        forid="seotic"
                        message="Seotic [On-Page SEO] involves optimizing elements within a webpage, like content, meta tags, headings, and URLs, to improve search rankings and user experience."
                        position="top"
                      />
                    </h3>
                    {onPageData ? (
                      <div className="pt-5 pb-10">
                        {(() => {
                          const {
                            title,
                            meta_description,
                            meta_keywords,
                            seo_friendly_url,
                            headings,
                            internal_links,
                            external_links,
                            favicon,
                            language,
                            text_compression,
                            http_requests,
                            doctype,
                            https_encryption,
                            structured_data,
                            meta_viewport,
                            character_set,
                            sitemap,
                            robots_txt,
                            social_links,
                            inline_css,
                            content_length,
                            suggestions,
                            // ... (other on-page SEO data)
                          } = onPageData;

                          return (
                            <>
                              {/* Title */}
                              {renderSeoItem('Title', title)}
                              {/* Meta Description */}
                              {renderSeoItem('Meta Description', meta_description)}

                              {/* Meta Keywords */}
                              {renderSeoItem('Meta Keywords', meta_keywords)}

                              {/* SEO Friendly URL */}
                              {seo_friendly_url && (
                                <div className="mb-4 border-b border-slate-100 dark:border-slate-600 pb-4 grid grid-cols-5 px-10">
                                  <div className="flex items-center ">
                                    <h4 className={`text-xl`}>
                                      <i className="bx bx-link bx-fw text-slate-500 mr-1"></i>SEO Friendly URL
                                    </h4>
                                  </div>
                                  <div className="col-span-4">
                                    <p className="text-slate-500 dark:text-slate-400">
                                      Is SEO Friendly:{' '}
                                      {seo_friendly_url.is_seo_friendly ? 'Yes' : 'No'}
                                    </p>
                                    {seo_friendly_url.suggestion && (
                                      <p className="text-slate-500 dark:text-slate-400">
                                        Suggestion: {seo_friendly_url.suggestion}
                                      </p>
                                    )}
                                    {seo_friendly_url.difficulty && (
                                      <p className="text-slate-500 dark:text-slate-400">
                                        Difficulty: {seo_friendly_url.difficulty}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )}

                              {/* Headings */}
                              {renderHeadings(headings)}

                              {/* In-Page Links */}
                              {renderInPageLinks(internal_links, external_links)}

                              {/* Favicon */}
                              {renderSeoItem('Favicon', favicon)}

                              {/* Language */}
                              {renderSeoItem('Language', language)}

                              {/* Text Compression */}
                              {renderSeoItem('Text Compression', text_compression)}

                              {/* HTTP Requests */}
                              {renderHttpRequests(http_requests)}

                              {/* Doctype */}
                              {renderSeoItem('Doctype', doctype)}

                              {/* HTTPS Encryption */}
                              {renderSeoItem('HTTPS Encryption', https_encryption)}

                              {/* Structured Data */}
                              {renderStructuredData(structured_data)}

                              {/* Meta Viewport */}
                              {renderSeoItem('Meta Viewport', meta_viewport)}

                              {/* Character Set */}
                              {renderSeoItem('Character Set', character_set)}

                              {/* Sitemap */}
                              {renderSeoItem('Sitemap', sitemap)}

                              {/* Robots.txt */}
                              {renderSeoItem('Robot.txt', robots_txt)}

                              {/* Social Links */}
                              {renderSocialLinks(social_links)}

                              {/* Inline CSS */}
                              {renderInlineCss(inline_css)}

                              {/* Content Length */}
                              {content_length && (
                                <div className="mb-4 border-b border-slate-100 dark:border-slate-600 pb-4 flex items-center px-10 grid grid-cols-5">
                                  <h4 className="col-span-1 text-xl mb-2">
                                    <i className="bx bx-fw bx-message-square-dots"></i> Content Length
                                  </h4>
                                  <p className="col-span-4 text-slate-500">
                                    {content_length.toLocaleString()} characters
                                  </p>
                                </div>
                              )}

                              {/* Suggestions */}
                              {suggestions && (
                                <div className="mb-4 px-10 grid grid-cols-5">
                                  <h4 className="col-span-1 text-xl mb-2">
                                    <i className="bx bx-band-aid bx-fw"></i>Suggestions
                                  </h4>
                                  <div className="col-span-4">
                                    <div className="text-slate-500 mb-5">
                                      You should fix these{' '}
                                      <p className="inline mx-1 px-3 py-1 text-sm rounded-full bg-yellow-500 text-white">
                                        {suggestions.length}
                                      </p>{' '}
                                      issues.
                                    </div>

                                    {suggestions.map((suggestion, index) => (
                                      <div
                                        className="border rounded-lg mb-2 border-lg px-10 py-5 border-slate-100 dark:border-slate-600"
                                        key={index}
                                      >
                                        <p className="text-xl text-yellow-500">
                                          {suggestion.category}
                                        </p>
                                        <p className="text-slate-500 text-xs mb-2 uppercase">
                                          Difficulty: {suggestion.difficulty}
                                        </p>
                                        {suggestion.suggestion}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })()}
                      </div>
                    ) : (
                      <p className="px-10 py-5">No On-Page SEO audit data available.</p>
                    )}
                  </div>
                ) : (
                  <div className="bg-white dark:bg-slate-700 dark:text-white rounded-lg">
                    {/* Rendering the Lighthouse issues */}
                    <h3 className="text-xl px-10 py-10 border-b border-slate-200 dark:border-slate-600">
                      <i className="bx bx-error-circle bx-fw"></i>Lighthouse Data{' '}
                      <i
                        id="lighthouse"
                        className="bx bxs-help-circle bx-xs text-slate-300 hover:text-slate-500"
                      ></i>
                      <Tooltip
                        forid="lighthouse"
                        message="Google Lighthouse that evaluates web pages on performance, accessibility, SEO, and more. It provides insights and recommendations to improve site quality and user experience."
                        position="top"
                      />
                    </h3>

                    {issues.length === 0 ? (
                      <p className="px-10 py-5">
                        No issues found. Your website is in great shape!
                      </p>
                    ) : (
                      <div className="">
                        {currentIssues.map((issue, index) => (
                          <div
                            key={index}
                            className="px-10 py-5 border-b border-slate-100 dark:border-slate-600 relative cursor-pointer"
                            onClick={() => toggleIssueDetail(index)}
                          >
                            {/* Clickable Title */}
                            <button className="text-md text-left">{issue.title}</button>
                            <i
                              className={`absolute right-10 top-6 bx bxs-chevron-${
                                expandedIssueIndex === index ? 'up' : 'down'
                              }`}
                            ></i>
                            {/* Display issue details conditionally based on the expanded index */}
                            {expandedIssueIndex === index && (
                              <div className="mt-2 text-sm text-slate-500 dark:text-slate-300">
                                <p
                                  className="mt-2"
                                  dangerouslySetInnerHTML={{
                                    __html: formatIssueDescription(issue.description),
                                  }}
                                />
                                {issue.displayValue && (
                                  <p className="mt-1 text-slate-600 dark:text-slate-400">
                                    Note: {issue.displayValue}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        ))}

                        {/* Pagination Controls */}
                        <div className="flex space-x-2 mt-4 pl-10 pb-5">
                          {/* Page Indicators */}
                          {Array.from({ length: totalPages }, (_, index) => (
                            <button
                              key={index}
                              onClick={() => handlePageChange(index + 1)}
                              className={`px-4 py-2 rounded-md ${
                                currentPage === index + 1
                                  ? 'bg-yellow-500 text-white'
                                  : 'bg-slate-100 dark:bg-slate-600 dark:text-slate-400 text-slate-600'
                              }`}
                            >
                              {index + 1}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default WebAuditPage;
