import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DropdownWithSearch from '../components/DropdownWithSearch';
import 'boxicons';

const SchemaMarkupGenerator = () => {
  const [schemaType, setSchemaType] = useState('Article');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [author, setAuthor] = useState('');
  const [ratingValue, setRatingValue] = useState('');
  const [bestRating, setBestRating] = useState('');
  const [itemReviewed, setItemReviewed] = useState('');
  const [reviewBody, setReviewBody] = useState('');
  const [price, setPrice] = useState('');
  const [brand, setBrand] = useState('');
  const [availability, setAvailability] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [location, setLocation] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [instructions, setInstructions] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [duration, setDuration] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [uploadDate, setUploadDate] = useState('');
  const [isbn, setIsbn] = useState('');
  const [publisher, setPublisher] = useState('');
  const [address, setAddress] = useState('');
  const [openingHours, setOpeningHours] = useState('');
  const [telephone, setTelephone] = useState('');
  const [generatedSchema, setGeneratedSchema] = useState(null);
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);
  
  const schemaOptions = [
    { label: 'Article', value: 'Article' },
    { label: 'Review', value: 'Review' },
    { label: 'Person', value: 'Person' },
    { label: 'Organization', value: 'Organization' },
    { label: 'Product', value: 'Product' },
    { label: 'Event', value: 'Event' },
    { label: 'Recipe', value: 'Recipe' },
    { label: 'FAQ Page', value: 'FAQPage' },
    { label: 'Video Object', value: 'VideoObject' },
    { label: 'Book', value: 'Book' },
    { label: 'Local Business', value: 'LocalBusiness' }
  ];

  const handleGenerateSchema = (e) => {
    e.preventDefault();

    if (!name || !description || !url) {
      setError('Please fill out all required fields.');
      return;
    }

    setError('');
    setCopied(false);

    let schema = {
      "@context": "https://schema.org",
      "@type": schemaType,
      name: name,
      description: description,
      url: url,
    };

    switch (schemaType) {
      case 'Article':
        if (author) schema.author = author;
        break;
      case 'Review':
        schema.author = author;
        schema.reviewRating = {
          "@type": "Rating",
          ratingValue: ratingValue,
          bestRating: bestRating,
        };
        schema.itemReviewed = {
          "@type": "Thing",
          name: itemReviewed,
        };
        schema.reviewBody = reviewBody;
        break;
      case 'Person':
        schema.birthDate = startDate;
        schema.jobTitle = description;
        break;
      case 'Organization':
        schema.location = location;
        schema.telephone = telephone;
        break;
      case 'Product':
        schema.price = price;
        schema.brand = { "@type": "Brand", name: brand };
        schema.availability = availability;
        break;
      case 'Event':
        schema.startDate = startDate;
        schema.endDate = endDate;
        schema.location = location;
        break;
      case 'Recipe':
        schema.recipeIngredient = ingredients.split(',').map(ingredient => ingredient.trim());
        schema.recipeInstructions = instructions;
        schema.author = author;
        break;
      case 'FAQPage':
        schema.mainEntity = [{
          "@type": "Question",
          name: question,
          acceptedAnswer: {
            "@type": "Answer",
            text: answer,
          }
        }];
        break;
      case 'VideoObject':
        schema.duration = duration;
        schema.thumbnailUrl = thumbnailUrl;
        schema.uploadDate = uploadDate;
        break;
      case 'Book':
        schema.isbn = isbn;
        schema.author = author;
        schema.publisher = publisher;
        break;
      case 'LocalBusiness':
        schema.address = address;
        schema.openingHours = openingHours;
        schema.telephone = telephone;
        break;
      default:
        break;
    }

    setGeneratedSchema(JSON.stringify(schema, null, 2));
  };

  return (
    <div className="min-h-screen mx-auto container p-10 bg-slate-100 dark:bg-slate-800 dark:text-white">
      <div className="w-full py-10 flex items-center relative">
        <div className="w-full text-4xl text-left text-slate-800 dark:text-white uppercase">
          Schema Markup Generator
          <div className="text-sm text-slate-500">Generate JSON-LD schema markup</div>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-slate-200 dark:bg-slate-700 dark:border-slate-600 p-10">
        <form onSubmit={handleGenerateSchema}>
          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Schema Type</label>
            <DropdownWithSearch
              options={schemaOptions}
              placeholder="Select a schema"
              defaultValue={schemaOptions.find(option => option.value === schemaType)}
              onSelect={(option) => setSchemaType(option.value)}
              className="border border-slate-200"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter name"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter description"
              required
            ></textarea>
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">URL</label>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter URL"
              required
            />
          </div>

          {schemaType === 'Article' && (
            <div className="mb-4">
              <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Author</label>
              <input
                type="text"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                placeholder="Enter author name"
              />
            </div>
          )}

          {schemaType === 'Review' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Author</label>
                <input
                  type="text"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter author name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Rating Value</label>
                <input
                  type="number"
                  value={ratingValue}
                  onChange={(e) => setRatingValue(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter rating value (e.g., 4.5)"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Best Rating</label>
                <input
                  type="number"
                  value={bestRating}
                  onChange={(e) => setBestRating(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter best rating (e.g., 5)"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Item Reviewed</label>
                <input
                  type="text"
                  value={itemReviewed}
                  onChange={(e) => setItemReviewed(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter item reviewed (e.g., product name)"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Review Body</label>
                <textarea
                  value={reviewBody}
                  onChange={(e) => setReviewBody(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter review body"
                ></textarea>
              </div>
            </>
          )}

          {schemaType === 'Person' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Job Title</label>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter job title"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Birth Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                />
              </div>
            </>
          )}

          {schemaType === 'Organization' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Location</label>
                <input
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter location"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Telephone</label>
                <input
                  type="tel"
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter telephone number"
                />
              </div>
            </>
          )}

          {schemaType === 'Product' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Price</label>
                <input
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter price"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Brand</label>
                <input
                  type="text"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter brand name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Availability</label>
                <input
                  type="text"
                  value={availability}
                  onChange={(e) => setAvailability(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter availability status"
                />
              </div>
            </>
          )}

          {schemaType === 'Event' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Location</label>
                <input
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter location"
                />
              </div>
            </>
          )}

          {schemaType === 'Recipe' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Ingredients</label>
                <textarea
                  value={ingredients}
                  onChange={(e) => setIngredients(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter ingredients, separated by commas"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Instructions</label>
                <textarea
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter recipe instructions"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Author</label>
                <input
                  type="text"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter author name"
                />
              </div>
            </>
          )}

          {schemaType === 'FAQPage' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Question</label>
                <input
                  type="text"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter question"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Answer</label>
                <textarea
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter answer"
                ></textarea>
              </div>
            </>
          )}

          {schemaType === 'VideoObject' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Duration</label>
                <input
                  type="text"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter duration (e.g., PT1M30S for 1 minute 30 seconds)"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Thumbnail URL</label>
                <input
                  type="text"
                  value={thumbnailUrl}
                  onChange={(e) => setThumbnailUrl(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter thumbnail URL"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Upload Date</label>
                <input
                  type="date"
                  value={uploadDate}
                  onChange={(e) => setUploadDate(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                />
              </div>
            </>
          )}

          {schemaType === 'Book' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">ISBN</label>
                <input
                  type="text"
                  value={isbn}
                  onChange={(e) => setIsbn(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter ISBN"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Author</label>
                <input
                  type="text"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter author name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Publisher</label>
                <input
                  type="text"
                  value={publisher}
                  onChange={(e) => setPublisher(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter publisher name"
                />
              </div>
            </>
          )}

          {schemaType === 'LocalBusiness' && (
            <>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Address</label>
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter address"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Opening Hours</label>
                <input
                  type="text"
                  value={openingHours}
                  onChange={(e) => setOpeningHours(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter opening hours (e.g., Mo-Fr 09:00-17:00)"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Telephone</label>
                <input
                  type="tel"
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                  className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
                  placeholder="Enter telephone number"
                />
              </div>
            </>
          )}

          <button
            type="submit"
            className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600"
          >
            Generate Schema
          </button>
        </form>

        {error && <p className="text-red-500 mt-4">{error}</p>}

        {generatedSchema && (
          <div className="mt-6 bg-slate-50 dark:bg-slate-600 p-10 rounded-lg border-slate-100 dark:border-slate-500 border relative">
            <h3 className="text-sm text-slate-500 uppercase mb-4">Generated Schema Markup</h3>
            <pre className="whitespace-pre-wrap text-sm">{generatedSchema}</pre>
            <CopyToClipboard text={generatedSchema} onCopy={() => setCopied(true)}>
              <button className="absolute top-5 right-5  text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"  fill="none">
    <path d="M16.9637 8.98209C16.9613 6.03194 16.9167 4.50384 16.0578 3.45753C15.892 3.25546 15.7067 3.07019 15.5047 2.90436C14.4008 1.99854 12.7609 1.99854 9.48087 1.99854C6.20089 1.99854 4.5609 1.99854 3.45708 2.90436C3.255 3.07018 3.06971 3.25546 2.90387 3.45753C1.99799 4.56128 1.99799 6.20116 1.99799 9.48091C1.99799 12.7607 1.99799 14.4005 2.90387 15.5043C3.0697 15.7063 3.255 15.8916 3.45708 16.0574C4.50346 16.9162 6.03167 16.9608 8.98201 16.9632" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.0283 9.02455L16.994 8.98193M14.0143 22.0013L16.9799 21.9586M21.9716 14.0221L21.9436 16.9818M9.01033 14.0357L8.98236 16.9953M11.4873 9.02455C10.6545 9.17371 9.31781 9.32713 9.01033 11.0488M19.4946 21.9586C20.3296 21.8223 21.6685 21.6894 22.0025 19.9726M19.4946 9.02455C20.3274 9.17371 21.6641 9.32713 21.9716 11.0488M11.5 21.9573C10.6672 21.8086 9.33039 21.6559 9.02197 19.9344" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
              </button>
            </CopyToClipboard>
            {copied && <p className="text-green-500 mt-2">Copied to clipboard!</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default SchemaMarkupGenerator;
