import React, { useState, useEffect } from 'react';
import 'boxicons/css/boxicons.min.css'; // Import Boxicons
import { FlagIcon } from 'react-flag-kit'; // Import FlagIcon for country flags
import TimeAgo from './components/TimeAgo';
import Tooltip from './components/Tooltip';
import LoadingScreen from './components/LoadingScreen';
import CountryDropdown from './components/CountryDropdown'; // Adjust the path accordingly

// Import Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title as ChartTitle,
  Tooltip as ChartTooltip,
  Legend as ChartLegend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ChartTitle,
  ChartTooltip,
  ChartLegend
);

const SerpSearchPage = () => {
  // State variables
  const [keywords, setKeywords] = useState([]);
  const [serpDataMap, setSerpDataMap] = useState({});
  const [loadingKeywords, setLoadingKeywords] = useState(false);
  const [loadingSerp, setLoadingSerp] = useState(false);
  const [fetchingKeywordId, setFetchingKeywordId] = useState(null);
  const [error, setError] = useState('');
  const [lastFetchTimes, setLastFetchTimes] = useState({});
  const [rankHistory, setRankHistory] = useState({});
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [currentSerpResults, setCurrentSerpResults] = useState([]);
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [currentKeywordId, setCurrentKeywordId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [rankHistoryData, setRankHistoryData] = useState({});
  const [topPositionsHistory, setTopPositionsHistory] = useState([]);
  const [top10PositionsHistory, setTop10PositionsHistory] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('All');
  const timestamp = new Date().getTime();
  // Retrieve token and project from localStorage
  const token = localStorage.getItem('token');
  const project = JSON.parse(localStorage.getItem('project'));
  const websiteId = project ? project.id : null;
  const projectUrl = project ? project.url : null;
  const url = projectUrl ? new URL(projectUrl).hostname : '';

  // Function to extract domain from URL
  const getDomain = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname.replace('www.', '').toLowerCase();
    } catch (error) {
      console.error(`Invalid URL encountered: "${url}"`);
      return '';
    }
  };

  const projectDomain = getDomain(projectUrl);

  // Simulate a loading delay (e.g., fetching data)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  // Fetch keywords and SERP data on component mount
  useEffect(() => {
    if (!token || !websiteId) {
      setError(
        'Token or Website ID is missing [ref : SR-08]'
      );
      return;
    }

    const fetchKeywordsAndSerp = async () => {
      try {
        setLoadingKeywords(true);
        // Fetch Keywords
        const keywordsResponse = await fetch(
          `https://www.pixelliongroup.com/seotic/get_keywords.php?_=${timestamp}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, website_id: websiteId }),
            credentials: 'include',
          }
        );

        if (!keywordsResponse.ok) {
          const errorText = await keywordsResponse.text();
          setError(
            `Failed to fetch keywords [ref : TR-09]`
          );
          setLoadingKeywords(false);
          return;
        }

        const keywordsResult = await keywordsResponse.json();

        if (keywordsResult && keywordsResult.data) {
          const formattedKeywords = keywordsResult.data.map((item) => ({
            id: item.id,
            keyword: item.keyword,
            website_hl: item.website_hl,
            website_gl: item.website_gl,
            search_volume: item.search_volume, // Assuming this field is available
          }));

          setKeywords(formattedKeywords);

          // Fetch SERP data for all keywords
          fetchAllSerpData(formattedKeywords);
          // Fetch rank history data
          fetchRankHistory();
        } else {
          setError(
            keywordsResult.message ||
              'Unexpected response format [ref : DN-10]'
          );
        }
      } catch (error) {
        setError(
          `An unexpected error [ref : DN-11]`
        );
      } finally {
        setLoadingKeywords(false);
      }
    };

    fetchKeywordsAndSerp();
  }, [websiteId, token]);

  // Fetch SERP data for all keywords
  const fetchAllSerpData = async (keywordsList) => {
    try {
      setLoadingSerp(true);
      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/get_serp.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token, website_id: websiteId }),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        setError(
          `Failed to fetch SERP results [ref : DN-12]`
        );
        setLoadingSerp(false);
        return;
      }
      
      // Handle specific status codes
    if (response.status === 201) {
       const data = 'Refresh your keywords';
      return; // Exit the function early since there's nothing to process
    } 


      const result = await response.json();

      if (result && result.status === 'success') {
        const serpDataArray = result.serp_data;

        const serpMap = {};
        const newRankHistory = {};

        serpDataArray.forEach((data) => {
          const {
            keyword_id,
            keyword_serp,
            current_rank,
            last_rank,
            last_week_rank,
            all_month_rank,
            created_at,
            matched_url_path,
          } = data;

          serpMap[keyword_id] = keyword_serp;

          newRankHistory[keyword_id] = {
            currentRank: current_rank,
            lastRank: last_rank,
            lastWeekRank: last_week_rank,
            allMonthRank: all_month_rank,
            lastFetched: created_at,
            matchedUrlPath: matched_url_path || '-', // Store the URL path
          };
        });

        setSerpDataMap(serpMap);
        setRankHistory(newRankHistory);
        setLastFetchTimes((prev) => {
          const updated = { ...prev };
          serpDataArray.forEach((data) => {
            updated[data.keyword_id] = data.created_at;
          });
          return updated;
        });

        setError('');
      } else {
        setSerpDataMap({});
        setRankHistory({});
        setError(result.message || 'No SERP data found for the keywords.');
      }
    } catch (error) {
      setError(
        `An unexpected error [ref : DN-13]`
      );
    } finally {
      setLoadingSerp(false);
    }
  };

  // Fetch rank history data
  const fetchRankHistory = async () => {
    try {
      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/get_rank_history.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token, website_id: websiteId }),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        setError(
          `An unexpected error [ref : DN-14]`
        );
        return;
      }

      const result = await response.json();

      if (result && result.status === 'success') {
        setRankHistoryData(result.data); // Assuming result.data is an object with keyword IDs as keys and arrays of rank data
      } else {
        setError(result.message || 'An unexpected error [ref : DN-15]');
      }
    } catch (error) {
      setError(
        `An unexpected error [ref : DN-16]`
      );
    }
  };

  // Fetch SERP data from external API for a specific keyword
  const fetchSerpFromExternalAPI = async (keywordId) => {
    if (!keywordId) {
      setError('Please select a keyword first.');
      return;
    }

    const selectedKeywordData = keywords.find((kw) => kw.id === keywordId);
    if (!selectedKeywordData) {
      setError(
        'The selected keyword is invalid. Please try selecting a different keyword.'
      );
      return;
    }

    try {
      setFetchingKeywordId(keywordId);
      const { keyword, website_hl, website_gl } = selectedKeywordData;

      // Map gl and hl to region (e.g., gl=us and hl=en => region=us-en)
      const region = `${website_gl}-${website_hl}`;

      const payload = {
        text: keyword,
        safesearch: 'off',
        timelimit: '',
        region: region,
        max_results: 20,
      };

      const externalResponse = await fetch(
        'https://google-api31.p.rapidapi.com/websearch',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-rapidapi-host': 'google-api31.p.rapidapi.com',
            'x-rapidapi-key':
              'f8ec203c2bmsh75cc2098376bc27p1aa3f6jsnc09e8ae6ec8a',
              },
          body: JSON.stringify(payload),
        }
      );

      if (!externalResponse.ok) {
        const errorText = await externalResponse.text();
        setError(
          `An unexpected error [ref : TR-17]`
        );
        setFetchingKeywordId(null);
        return;
      }

      const serpData = await externalResponse.json();

      if (serpData.result && serpData.result.length > 0) {
        // Find the first occurrence of the project domain in the SERP results
        const index = serpData.result.findIndex(
          (item) => getDomain(item.href) === projectDomain
        );
        let keywordRank = '-';
        let matchedUrlPath = '';

        if (index !== -1) {
          keywordRank = (index + 1).toString();

          // Extract the URL path
          const matchedUrl = serpData.result[index].href;
          const urlObj = new URL(matchedUrl);
          matchedUrlPath = urlObj.pathname; // Get the path, e.g., /seo, /about

          // Optional: Handle root path '/'
          if (matchedUrlPath === '') {
            matchedUrlPath = '';
          }
        }

        const serpResultsWithRank = serpData.result.map((item, index) => ({
          title: item.title,
          href: item.href,
          body: item.body,
          rank: index + 1,
        }));

        await saveSerpDataToBackend(
          keywordId,
          keywordRank,
          serpResultsWithRank,
          matchedUrlPath // Pass the URL path
        );
        fetchAllSerpData(keywords);
        fetchRankHistory(); // Fetch updated rank history
        setError('');
      } else {
        setError('An unexpected error [ref : TR-18]');
      }
    } catch (error) {
      setError(
        `An unexpected error [ref : TR-19]`
      );
    } finally {
      setFetchingKeywordId(null);
    }
  };

  // Save SERP data to the backend
  const saveSerpDataToBackend = async (
    keywordId,
    keywordRank,
    serpData,
    matchedUrlPath // Add parameter
  ) => {
    try {
      const payload = {
        token,
        keyword_id: keywordId,
        keyword_rank: keywordRank,
        keyword_serp: serpData,
        matched_url_path: matchedUrlPath, // Include in payload
      };

      const response = await fetch(
        `https://www.pixelliongroup.com/seotic/save_rank.php?_=${timestamp}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        setError(
          `Failed to save [ref : DN-20]`
        );
        return;
      }

      const result = await response.json();

      if (result.status === 'success') {
        setError('');
      } else {
        setError(result.message || 'Failed to save SERP data. [ref : DN-20]');
      }
    } catch (error) {
      setError(
        `Failed to save SERP data. [ref : DN-20]`
      );
    }
  };

  // Check if we can fetch new SERP data based on the last fetch time
  const canFetchNewSerpData = (keywordId) => {
    if (!lastFetchTimes[keywordId]) return true;
    const currentTime = new Date().getTime();
    const lastFetchTimestamp = new Date(lastFetchTimes[keywordId]).getTime();
    return currentTime - lastFetchTimestamp > 24 * 60 * 60 * 1000; // 24 hours
  };

  // Handle View SERP Results
  const handleViewSerp = (keywordId) => {
    const serp = serpDataMap[keywordId];
    const keywordObj = keywords.find((k) => k.id === keywordId);
    if (serp && keywordObj) {
      setCurrentSerpResults(serp);
      setCurrentKeyword(keywordObj.keyword); // Set the current keyword
      setCurrentKeywordId(keywordId); // Set the current keyword ID
      setShowOffCanvas(true);
    } else {
      setError('No SERP data available for this keyword.');
    }
  };

  // Simulate a count animation for scores
  const useCountAnimation = (endValue, duration = 2000) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
      // Convert endValue to a number
      const end = Number(endValue);
      if (isNaN(end)) {
        setValue(0);
        return;
      }

      let start = 0;
      const increment = end / (duration / 20); // Update every 20ms

      let timer;

      const animate = () => {
        start += increment;
        if (start >= end) {
          setValue(end.toFixed(0)); // Ensure integer
          clearInterval(timer);
        } else {
          setValue(start.toFixed(0));
        }
      };

      timer = setInterval(animate, 20);

      // Clean up the interval on unmount or when endValue changes
      return () => clearInterval(timer);
    }, [endValue, duration]);

    return value;
  };

  // Calculate the average rank
  const calculateAverageRank = () => {
    const ranks = Object.values(rankHistory)
      .map((history) => parseInt(history.currentRank))
      .filter((rank) => !isNaN(rank));
    if (ranks.length === 0) return 0;
    const sum = ranks.reduce((a, b) => a + b, 0);
    return (sum / ranks.length).toFixed(1);
  };

  const animatedAverageRank = useCountAnimation(calculateAverageRank());
  
  
  // Function to calculate average rank history
const calculateAverageRankHistory = () => {
  const dateRankMap = {}; // key: date, value: array of ranks

  Object.values(rankHistoryData).forEach((keywordHistory) => {
    keywordHistory.forEach((entry) => {
      const date = entry.date.split(' ')[0]; // Extract the date part
      const rank = parseInt(entry.rank);

      if (!isNaN(rank)) {
        if (!dateRankMap[date]) {
          dateRankMap[date] = [];
        }
        dateRankMap[date].push(rank);
      }
    });
  });

  // Calculate average rank for each date
  const averageRankHistory = [];
  Object.keys(dateRankMap)
    .sort() // Sort dates ascending
    .forEach((date) => {
      const ranks = dateRankMap[date];
      const sum = ranks.reduce((a, b) => a + b, 0);
      const avg = sum / ranks.length;
      averageRankHistory.push({ date, avgRank: avg });
    });

  return averageRankHistory;
};

const [averageRankHistoryData, setAverageRankHistoryData] = useState([]);

useEffect(() => {
  if (Object.keys(rankHistoryData).length > 0) {
    const data = calculateAverageRankHistory();
    setAverageRankHistoryData(data);
  }
}, [rankHistoryData]);

const chartData = React.useMemo(() => {
  if (averageRankHistoryData.length === 0) {
    return null;
  }
  return {
    labels: averageRankHistoryData.map((entry) => entry.date),
    datasets: [
      {
        label: 'Average Rank',
        data: averageRankHistoryData.map((entry) => entry.avgRank),
        fill: false,
        borderColor: '#3B82F6', // blue-500
        backgroundColor: '#3B82F6',
        borderWidth: 4,
        pointRadius: 3,
        tension: 0.5,
      },
      
    ],
  };
}, [averageRankHistoryData]);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      display: false,
      title: {
        display: false,
        text: 'Date',
      },
      grid: {
      display: false,
    },
    },
    y: {
      display: true,
      title: {
        display: false,
        text: 'Average Rank',
      },
      reverse: true, // Lower ranks are better
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
};

  

  // Function to render a small chart for a keyword in the table
  const renderSmallChart = (keywordId) => {
    const data = rankHistoryData[keywordId];

    if (!data || data.length === 0) {
      return <p className="text-gray-700">No data</p>;
    }

    // Get the last 3 rank entries
    const lastThreeEntries = data.slice(-3);

    // Determine the rank change between the last two entries
    let chartColor = '#D97706'; // Default to yellow-500

    if (lastThreeEntries.length >= 2) {
      const lastRank = parseInt(
        lastThreeEntries[lastThreeEntries.length - 1].rank
      );
      const previousRank = parseInt(
        lastThreeEntries[lastThreeEntries.length - 2].rank
      );

      if (lastRank < previousRank) {
        chartColor = '#10B981'; // green-500
      } else if (lastRank > previousRank) {
        chartColor = '#EF4444'; // red-500
      } else {
        chartColor = '#D97706'; // yellow-500
      }
    }

    // Prepare data for the small chart
    const chartData = {
      labels: lastThreeEntries.map((entry) => ''), // Empty labels for minimal chart
      datasets: [
        {
          data: lastThreeEntries.map((entry) => parseInt(entry.rank)),
          fill: false,
          borderColor: chartColor,
          backgroundColor: chartColor,
          borderWidth: 3,
          pointRadius: 0,
          tension: 0.5, // Adjust tension for rounded lines
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false, // Disable tooltip for minimal chart
        },
      },
      scales: {
        x: {
          display: false, // Hide X axis labels and gridlines
        },
        y: {
          display: false, // Hide Y axis labels and gridlines
          reverse: true, // Reverse Y axis for rank
        },
      },
    };

    return (
      <div style={{ width: 50, height: 20 }}>
        <Line data={chartData} options={options} />
      </div>
    );
  };
  
  
  const getFilteredKeywords = () => {
    return keywords.filter((keyword) => {
      const matchesSearchQuery = keyword.keyword
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      const matchesCountry =
        selectedCountry === 'All' ||
        keyword.website_gl.toLowerCase() === selectedCountry.toLowerCase();

      return matchesSearchQuery && matchesCountry;
    });
  };

  // Generate unique countries
  const uniqueCountries = React.useMemo(() => {
    const countries = keywords.map((keyword) =>
      keyword.website_gl.toUpperCase()
    );
    return Array.from(new Set(countries));
  }, [keywords]);

  // Now place your conditional return after all Hooks
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {/* Main Content */}
      <div className="min-h-screen p-10 mx-auto container dark:text-white">
        <div className="w-full py-10 flex items-center">
          <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
            Keyword Rankings
            <div className="text-sm text-slate-500">
              <i className="bx bx-folder bx-fw"></i> Project /{' '}
              {url.replace(/(www\.)?/, '')}
            </div>
          </div>
        </div>

        <div className="">
          {error && (
            <div className="flex items-center justify-center my-10 text-center ">
              <p className="w-96 mt-4 mb-10">
                <i className="bx bx-error-circle bx-rotate-180 bx-lg text-red-500"></i>
                <h3 className="text-4xl mb-2">Oh snap!</h3>
                Something went wrong, please try again later or tell us what was
                wrong{' '}
                <a
                  className="text-yellow-500"
                  href="https://tally.so/r/nrdakL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                <p className="text-sm text-slate-500 mt-5">{error}</p>
              </p>
            </div>
          )}

          {(loadingKeywords || loadingSerp) && (
            <div className="flex items-center justify-center my-10 text-center">
              <p className="w-96 mt-4 mb-10">
                <i className="bx bx-loader-alt bx-spin bx-rotate-180 bx-lg text-yellow-500"></i>
                <h3 className="text-4xl mb-2">Preparing your data</h3>
                Getting your keyword data and calculations...
              </p>
            </div>
          )}

          {/* Display Scores and Average Rank */}
          {keywords.length > 0 && (
            <div className="">
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5">
                <div className="bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-center">
                  {/* Average Rank */}
                  <div className="py-12 relative">
                    {/* Background Half-Circle with Gradient */}
                    <div className="relative flex justify-center items-center mt-12 z-10">
                      <div
                        className={`blur-md h-32 w-64 transform -translate-y-1/2
                              ${
                                animatedAverageRank <= 3
                                  ? 'bg-gradient-to-t from-white dark:from-slate-700 dark:to-green-500 to-green-300'
                                  : animatedAverageRank <= 7
                                  ? 'bg-gradient-to-t from-white to-yellow-300 dark:from-slate-700 dark:to-yellow-500'
                                  : 'bg-gradient-to-t from-white to-red-300 dark:from-slate-700 dark:to-red-500'
                              }
                                    rounded-tl-full rounded-tr-full absolute top-5 z-[-10]`}
                      ></div>
                      <p
                        id="average-rank"
                        className="text-xs uppercase text-white px-3 py-1 z-20 opacity-75 relative bg-slate-900 rounded-full"
                      >
                        <i className="bx bxs-layer bx-fw"></i>Average Rank
                      </p>
                    </div>

                    {/* Text Elements */}
                    <p className="text-8xl font-bold z-20 relative ">
                      {animatedAverageRank.toString().padStart(2, '0')}
                    </p>
                  </div>
                </div>
                
                
                <div className="col-span-2 bg-white rounded-lg dark:bg-slate-700 dark:border-slate-600 border border-slate-200">
    <div className="py-10 relative">
     <h3 className="text-xl px-10">
                <i className="bx bx-trending-up bx-fw"></i>Average Rank History <i className="bx bxs-help-circle bx-xs text-slate-300 hover:text-slate-500"></i>
              </h3>

      {/* Average Rank History Chart */}
      {chartData && (
        <div className="h-full mt-2 w-full px-10">
          <Line data={chartData} options={chartOptions} />
        </div>
      )}
    </div>
  </div>
                

                {/* Additional Metrics */}
                <div className="bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600">
                  <div className="flex px-10 py-5">
                    
                    <p className="text-lg text-slate-900 dark:text-white w-full"><i class='bx bx-text bx-fw'></i>Total Keywords</p>
                    <p className="text-lg flex justify-end w-fit">{keywords.length}</p>
                  </div>
                  
                  <div className="flex px-10 py-5 border-t border-slate-200 dark:border-slate-600">
                    <p className="text-lg text-slate-900 dark:text-white w-full"><i class='bx bx-star bx-fw' ></i>Top Positions</p>
                     <p className="text-lg flex justify-end w-fit">
                      {
                        Object.values(rankHistory).filter(
                          (h) => parseInt(h.currentRank) === 1
                        ).length
                      }
                    </p>

                  </div>

            
            
                  <div className="flex px-10 py-5 border-t border-slate-200 dark:border-slate-600">
                    <p className="text-lg text-slate-900 dark:text-white w-full"><i class='bx bx-chevrons-up bx-fw' ></i>Top 3 Positions</p>
                     <p className="text-lg flex justify-end w-fit">
                      {
                        Object.values(rankHistory).filter(
                          (h) => parseInt(h.currentRank) <= 3
                        ).length
                      }
                    </p>

                  </div>
                  
                                
              
                  <div className="flex px-10 py-5 border-t border-slate-200 dark:border-slate-600">
                   <p className="text-lg text-slate-900 dark:text-white w-full"><i class='bx bx-crown bx-fw' ></i>Top 10 Positions</p>
                    <p className="text-lg flex justify-end w-fit">
                      {
                        Object.values(rankHistory).filter(
                          (h) => parseInt(h.currentRank) <= 10
                        ).length
                      }
                    </p>

                  </div>
                </div>
              </div>
            </div>
          )}


          {keywords.length > 0 && (
            <>
               {/* Add Search Bar and Country Filter */}
          
            
              <div className="bg-white bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 rounded-lg mt-5">
                <div className="overflow-x-auto">
                <div className="flex p-10 items-center w-full">
                  <h3 className="text-xl w-full">
                    <i className="bx bx-link bx-fw"></i>Keywords{' '}
                    <i className="bx bxs-help-circle bx-xs text-slate-300 hover:text-slate-500"></i>
                  </h3>
                  
            
                  
                   <div className="flex justify-end space-x-4 w-full">
                   <div  className="grow border border-slate-100 dark:border-slate-500 rounded-lg px-4 py-2 dark:bg-slate-600">
                   <i class='bx bx-search-alt-2 bx-fw text-slate-400'></i>
                <input
                  type="text"
                  placeholder="Search Keywords..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                 className="focus:outline-none dark:bg-slate-600"
                />
                </div>
                <div className="grow-0">
               <CountryDropdown
    uniqueCountries={uniqueCountries}
    selectedCountry={selectedCountry}
    setSelectedCountry={setSelectedCountry}
  />                </div>
              </div>

                  
               
                  </div>

                  <table className="min-w-full ">
                    <thead>
                      <tr className="bg-slate-50 dark:bg-slate-600 text-sm border-b border-slate-100 dark:border-slate-600 text-slate-500 dark:text-slate-300">
                        <th className="py-5 px-10 text-xs font-medium uppercase tracking-wider">
                          Country
                        </th>
                        <th className="py-5 px-10 text-left text-xs font-medium uppercase tracking-wider">
                          Keyword
                        </th>
                        <th className="py-5 px-10 text-left text-xs font-medium uppercase tracking-wider">
                          History
                        </th>
                        <th className="py-5 px-10 text-left text-xs font-medium uppercase tracking-wider">
                          URL
                        </th>
                        <th className="py-5 px-5 text-center text-xs font-medium w-22 uppercase tracking-wider">
                          CR
                        </th>
                        <th className="py-5 px-5 text-center text-xs font-medium w-22 uppercase tracking-wider">
                          LR
                        </th>
                        <th className="py-5 px-5 text-center text-xs font-medium w-22  uppercase tracking-wider">
                          LW
                        </th>
                        <th className="py-5 px-5 text-center text-xs font-medium w-22  uppercase tracking-wider">
                          AM
                        </th>
                        <th className="py-5 px-10 text-center text-xs font-medium  uppercase tracking-wider">
                          Last Date
                        </th>
                        <th className="py-5 px-5 text-center text-xs font-medium  w-fit uppercase">
                          View
                        </th>
                        <th className="py-5 px-5 text-center text-xs font-medium  w-fit uppercase">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getFilteredKeywords().map((keyword)=> {
	                      
	                      const serpResults = serpDataMap[keyword.id] || [];

  // Find the matched URL in the current SERP results
  const matchedResult = serpResults.find(
    (result) => getDomain(result.href) === projectDomain
  );
  const matchedUrl = matchedResult ? matchedResult.href : '-';
	                      
                        const history = rankHistory[keyword.id] || {};
                        const rankChange =
                          history.lastRank && history.currentRank
                            ? parseInt(history.currentRank) <
                              parseInt(history.lastRank)
                              ? 'up'
                              : parseInt(history.currentRank) >
                                parseInt(history.lastRank)
                              ? 'down'
                              : 'same'
                            : 'same';

                        return (
                          <tr
                            key={keyword.id}
                            className="border-t text-slate-700 border-slate-100 dark:border-slate-600 dark:hover:bg-slate-600 dark:text-white first:border-none hover:bg-yellow-50"
                          >
                            {/* Country */}
                            <td className="py-5 px-10 pl-12 text-sm flex items-center">
                              <FlagIcon
                                code={keyword.website_gl.toUpperCase()}
                                size={24}
                                className="mr-2"
                              />
                              {keyword.website_gl.toUpperCase()}
                            </td>

                            {/* Keyword */}
                            <td className="py-5 px-10 text-sm">
                              {keyword.keyword}
                            </td>

                            {/* History */}
                            <td className="py-5 px-10 text-sm">
                              {renderSmallChart(keyword.id)}
                            </td>

                           {/* URL Path */}
                            <td className="py-5 px-10 text-sm">
                              {matchedUrl !== '-' ? (
	                              <>
                                <i class='bx bx-globe bx-fw'></i> <a
                                  href={matchedUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {new URL(matchedUrl).pathname || ' '}
                                </a></>
                              ) : (
                                '-'
                              )}
                            </td>

                            {/* Current Rank */}
                            <td className="py-5 px-5 text-sm text-center ">
                              {history.currentRank || '-'}
                              {rankChange === 'up' && (
                                <i className="bx bxs-up-arrow text-green-500 ml-2"></i>
                              )}
                              {rankChange === 'down' && (
                                <i className="bx bxs-down-arrow text-red-500 ml-2"></i>
                              )}
                            </td>

                            {/* Last Time Rank */}
                            <td className="py-5 px-5 text-sm text-center">
                              {history.lastRank || '-'}
                            </td>

                            {/* Last Week Rank */}
                            <td className="py-5 px-5 text-sm text-center">
                              {history.lastWeekRank || '-'}
                            </td>

                            {/* All Month Rank */}
                            <td className="py-5 px-5 text-sm text-center">
                              {history.allMonthRank || '-'}
                            </td>

                            {/* Last Fetched Date */}
                            <td className="py-5 px-10 text-sm text-center">
                              {history.lastFetched ? (
                               <TimeAgo
      // Convert "2025-01-01 00:00:00" -> "2025-01-01T00:00:00Z" on the fly
      date={
        new Date(
          history.lastFetched.replace(' ', 'T') + 'Z'
        )
      }
    />
                              ) : (
                                '-'
                              )}
                            </td>

                            {/* View Button */}
                            <td className="py-5 px-10 text-sm text-center">
                              <button
                                className="text-blue-500 hover:text-blue-700"
                                onClick={() => handleViewSerp(keyword.id)}
                              >
                                <i className="bx bx-chart bx-sm"></i>
                              </button>
                            </td>

                            {/* Fetch Button */}
                            <td className="py-5 px-10 text-sm text-center">
                              <button
                                className={`flex items-center justify-center text-slate-400 hover:text-slate-500 ${
                                  !canFetchNewSerpData(keyword.id) ||
                                  fetchingKeywordId === keyword.id
                                    ? 'opacity-50 cursor-not-allowed'
                                    : ''
                                }`}
                                onClick={() =>
                                  fetchSerpFromExternalAPI(keyword.id)
                                }
                                disabled={
                                  !canFetchNewSerpData(keyword.id) ||
                                  fetchingKeywordId === keyword.id
                                }
                              >
                                {fetchingKeywordId === keyword.id ? (
                                  <i className="bx bx-spin bx-refresh bx-sm"></i>
                                ) : (<i className="bx bx-refresh bx-sm"></i>)}
                                
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* Off-Canvas for SERP Results */}
                {showOffCanvas && (
                  <div className="fixed inset-0 flex z-50">
                    {/* Overlay */}
                    <div
                      className="fixed inset-0 bg-black opacity-70"
                      onClick={() => setShowOffCanvas(false)}
                    ></div>

                    {/* Off-Canvas Panel */}
                    <div className="relative bg-white border border-slate-200 dark:border-slate-700 dark:bg-slate-800 dark:border-slate-700 w-2/3 h-full shadow-xl overflow-y-auto">
                      <button
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        onClick={() => setShowOffCanvas(false)}
                      >
                        <i className="bx bx-x text-2xl"></i>
                      </button>
                      <div className="">
                        <h2 className="border rounded-lg border-slate-100 dark:border-slate-700 px-5 py-3 mt-10 mx-10 mb-5">
                          <i class='bx bx-search-alt-2 bx-fw'></i> {currentKeyword}
                        </h2>

                        {/* Removed the chart from the SERP result off-canvas */}

                        {currentSerpResults.length > 0 ? (
                          <div className="">
                            {currentSerpResults.map((result, index) => {
                              // Check if the result's domain matches the project domain
                              const resultDomain = getDomain(result.href);
                              const isProjectUrl =
                                resultDomain === projectDomain;

                              return (
                                <div
                                  key={index}
                                  className={`border-b last:border-none  border-slate-100 dark:border-slate-700 px-10 py-5 ${
                                    isProjectUrl ? 'bg-green-50 dark:bg-green-900' : ''
                                  }`}
                                >
                                  <a
                                    href={result.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm text-green-700 dark:text-green-300"
                                  >
                                    {result.href}
                                  </a>
                                  <h3 className="text-xl text-blue-800 dark:text-blue-300 hover:underline">
                                    <a
                                      href={result.href}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {result.title}
                                    </a>
                                  </h3>
                                  <p className="text-slate-700 dark:text-slate-300">
                                    {result.body}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <p>No SERP results available.</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {!keywords.length && !loadingKeywords && !loadingSerp && (
            <p className="text-gray-700 mt-4">
              No keywords found. Please add keywords to your project.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default SerpSearchPage;
