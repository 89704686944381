// src/ShortcutHandler.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ShortcutHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyPress = (event) => {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const modifierKey = isMac ? event.metaKey : event.ctrlKey;

      // Define shortcuts with unique, less common keys to avoid system shortcut conflicts
      if (modifierKey && event.key === '1') {
        event.preventDefault();
        navigate('/dashboard');
      } else if (modifierKey && event.key === '2') {
        event.preventDefault();
        navigate('/projects');
      } else if (modifierKey && event.key === '3') {
        event.preventDefault();
        navigate('/tools');
      } else if (modifierKey && event.key === '4') {
        event.preventDefault();
        navigate('/addproject');
      } else if (modifierKey && event.key === '5') {
        event.preventDefault();
        navigate('/audit');
      } else if (modifierKey && event.key === '6') {
        event.preventDefault();
        navigate('/backlinks');
      } else if (modifierKey && event.key === '7') {
        event.preventDefault();
        navigate('/serp');
      } else if (modifierKey && event.key === '8') {
        event.preventDefault();
        navigate('/competitor');
      } else if (modifierKey && event.key === '9') {
        event.preventDefault();
        navigate('/settings');
      }
    };

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyPress);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [navigate]);

  return null; // This component does not render anything
};

export default ShortcutHandler;
