// src/layouts/PublicLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const PublicLayout = () => {
  return (
    <div className="public-layout bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-md w-full">
        <Outlet /> {/* Renders the nested route (Login/Register) */}
      </div>
    </div>
  );
};

export default PublicLayout;
