// src/ProtectedRoute.js

import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import LoadingScreen from './components/LoadingScreen'; // Assuming you have a loading component

const ProtectedRoute = () => {
  const { plan, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingScreen />; // Show a loading indicator while checking auth
  }

  return plan && !plan.is_expired ? <Outlet /> : <Navigate to="/renew" replace />;
};

export default ProtectedRoute;
