// LoadingScreen.js
import React from 'react';

const LoadingScreen = () => {
  return (
    <div className=" flex items-center justify-center w-screen h-screen bg-slate-100 dark:bg-slate-800 z-50 ml-22">
      {/* You can replace this with your own loading spinner or animation */}
      <h2 className="text-center text-slate-500 text-sm"><i class='bx bx-circle bx-burst bx-rotate-270 bx-fw'></i>Teleporting to your destination...</h2>
    </div>
  );
};

export default LoadingScreen;
