import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const UTMBuilder = () => {
  const [baseUrl, setBaseUrl] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [utmTerm, setUtmTerm] = useState('');
  const [utmContent, setUtmContent] = useState('');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [copied, setCopied] = useState(false);

  // Generate URL with UTM parameters
  const handleGenerateUrl = (e) => {
    e.preventDefault();
    setCopied(false);

    const params = new URLSearchParams();
    if (utmSource) params.append('utm_source', utmSource);
    if (utmMedium) params.append('utm_medium', utmMedium);
    if (utmCampaign) params.append('utm_campaign', utmCampaign);
    if (utmTerm) params.append('utm_term', utmTerm);
    if (utmContent) params.append('utm_content', utmContent);

    const utmUrl = `${baseUrl}?${params.toString()}`;
    setGeneratedUrl(utmUrl);
  };

  return (
    <div className="min-h-screen mx-auto container p-10 bg-slate-100 dark:bg-slate-800 dark:text-white">
      <div className="w-full py-10 flex items-center relative">
        <div className="w-full text-4xl text-left text-slate-800 dark:text-white uppercase">
          UTM Builder
          <div className="text-sm text-slate-500">Generate UTM-tagged URLs for campaigns</div>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-slate-200 dark:bg-slate-700 dark:border-slate-600 p-10">
        <form onSubmit={handleGenerateUrl}>
          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Base URL</label>
            <input
              type="url"
              value={baseUrl}
              onChange={(e) => setBaseUrl(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter the base URL (e.g., https://example.com)"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">UTM Source</label>
            <input
              type="text"
              value={utmSource}
              onChange={(e) => setUtmSource(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter the source (e.g., google, newsletter)"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">UTM Medium</label>
            <input
              type="text"
              value={utmMedium}
              onChange={(e) => setUtmMedium(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter the medium (e.g., cpc, email)"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">UTM Campaign</label>
            <input
              type="text"
              value={utmCampaign}
              onChange={(e) => setUtmCampaign(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter the campaign name (e.g., spring_sale)"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">UTM Term (Optional)</label>
            <input
              type="text"
              value={utmTerm}
              onChange={(e) => setUtmTerm(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter the keyword term (e.g., running shoes)"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">UTM Content (Optional)</label>
            <input
              type="text"
              value={utmContent}
              onChange={(e) => setUtmContent(e.target.value)}
              className="w-full p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter content description (e.g., banner_ad)"
            />
          </div>

          <button
            type="submit"
            className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600"
          >
            Generate URL
          </button>
        </form>

        {generatedUrl && (
          <div className="mt-6 bg-slate-50 dark:bg-slate-600 p-10 rounded-lg border-slate-100 dark:border-slate-500 border relative">
            <h3 className="text-sm text-slate-500 uppercase mb-4">Generated URL</h3>
            <pre className="whitespace-pre-wrap text-sm">{generatedUrl}</pre>
            <CopyToClipboard text={generatedUrl} onCopy={() => setCopied(true)}>
             <button className="absolute top-5 right-5 text-slate-500 dark:text-slate-400 hover:text-slate-900 dark:hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
                  <path d="M16.9637 8.98209C16.9613 6.03194 16.9167 4.50384 16.0578 3.45753C15.892 3.25546 15.7067 3.07019 15.5047 2.90436C14.4008 1.99854 12.7609 1.99854 9.48087 1.99854C6.20089 1.99854 4.5609 1.99854 3.45708 2.90436C3.255 3.07018 3.06971 3.25546 2.90387 3.45753C1.99799 4.56128 1.99799 6.20116 1.99799 9.48091C1.99799 12.7607 1.99799 14.4005 2.90387 15.5043C3.0697 15.7063 3.255 15.8916 3.45708 16.0574C4.50346 16.9162 6.03167 16.9608 8.98201 16.9632" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.0283 9.02455L16.994 8.98193M14.0143 22.0013L16.9799 21.9586M21.9716 14.0221L21.9436 16.9818M9.01033 14.0357L8.98236 16.9953M11.4873 9.02455C10.6545 9.17371 9.31781 9.32713 9.01033 11.0488M19.4946 21.9586C20.3296 21.8223 21.6685 21.6894 22.0025 19.9726M19.4946 9.02455C20.3274 9.17371 21.6641 9.32713 21.9716 11.0488M11.5 21.9573C10.6672 21.8086 9.33039 21.6559 9.02197 19.9344" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>

            </CopyToClipboard>
            {copied && <p className="text-green-500 mt-2">Copied to clipboard!</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default UTMBuilder;
