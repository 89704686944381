// src/components/SettingModal.js
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const SettingModal = ({ onClose, title, children }) => {
  // Create a div that will act as the modal's container
  const modalRoot = document.getElementById('modal-root');
  const el = document.createElement('div');

  useEffect(() => {
    // Append the modal container to the modal root when the component mounts
    modalRoot.appendChild(el);
    // Clean up by removing the modal container when the component unmounts
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el, modalRoot]);

  // Close the modal when the Escape key is pressed
  useEffect(() => {
    const onEsc = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', onEsc);
    return () => {
      window.removeEventListener('keydown', onEsc);
    };
  }, [onClose]);

  // Prevent scrolling when the modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Modal Content
  const modalContent = (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 dark:bg-opacity-90 flex items-center justify-center z-50"
      onClick={onClose} // Close when clicking on the backdrop
      aria-modal="true"
      role="dialog"
      aria-labelledby="modal-title"
    >
      <div
        className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 rounded-lg w-full max-w-md mx-4 p-10 relative"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-slate-500 hover:text-slate-700 dark:hover:text-slate-400 focus:outline-none"
          aria-label="Close modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* Modal Title */}
        {title && (
          <h2 id="modal-title" className="text-2xl dark:text-white mb-4">
            {title}
          </h2>
        )}

        {/* Modal Content */}
        <div className="dark:text-slate-300 text-slate-800">{children}</div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, el);
};

// PropTypes for type checking
SettingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

// Default props
SettingModal.defaultProps = {
  title: '',
};

export default SettingModal;