// RankedKeywords.js

import React, { useState, useEffect, useRef } from 'react';
import LoadingScreen from './components/LoadingScreen';
import Modal from './components/ComModal'; // The modal component, possibly unchanged

const RankedKeywords = () => {
  const [projectUrl, setProjectUrl] = useState('');
  const [projectDomain, setProjectDomain] = useState('');
  const [keywordsData, setKeywordsData] = useState([]);
  const [loading, setLoading] = useState(false); // Actual data loading state
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', keywords: [] });

  const token = localStorage.getItem('token');

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  // Store timestamp in a ref to keep it stable across renders
  const timestampRef = useRef(new Date().getTime());

  // Helper function to extract domain from URL
  const getDomain = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname.replace('www.', '').toLowerCase();
    } catch (error) {
      console.error(`Invalid URL encountered: "${url}"`);
      return '';
    }
  };

  // Fetch project URL from localStorage
  useEffect(() => {
    const project = JSON.parse(localStorage.getItem('project'));
    if (project && project.url) {
      const fullUrl = project.url;
      const domainName = getDomain(fullUrl);
      if (fullUrl !== projectUrl) {
        setProjectUrl(fullUrl);
        setProjectDomain(domainName);
      }
    } else {
      setError('No project found in local storage.');
      setProjectUrl('');
    }
  }, [projectUrl]);

  // Fetch data for the project URL
  useEffect(() => {
    if (!projectUrl) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://www.pixelliongroup.com/seotic/get_competitors_data.php?_=${timestampRef.current}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, url: projectUrl }),
          }
        );

        if (!response.ok) {
          const text = await response.text();
          throw new Error(`HTTP error! status: ${response.status} - ${text}`);
        }
        const data = await response.json();

        if (data.status !== 'success') {
          throw new Error(data.message || `Failed to fetch data for URL ${projectUrl}.`);
        }

        setKeywordsData(data.data.com_data.keywords || []);
        setCurrentPage(1); // Reset to first page when new data is loaded
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectUrl, token]);

  // Check if loading is in progress
  if (loading && keywordsData.length === 0) {
    return <LoadingScreen />;
  }

  // Function to format dates in the "09 Nov 24" format
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'N/A';
    // Use Intl.DateTimeFormat to format the date
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    }).format(date);
  };

  const handleShowModal = (title, keywords) => {
    setModalContent({ title, keywords: keywords || [] });
    setShowModal(true);
  };

  // Pagination logic
  const totalPages = Math.ceil(keywordsData.length / itemsPerPage);
  const indexOfLastKeyword = currentPage * itemsPerPage;
  const indexOfFirstKeyword = indexOfLastKeyword - itemsPerPage;
  const currentKeywords = keywordsData.slice(indexOfFirstKeyword, indexOfLastKeyword);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Render pagination controls
  const renderPagination = () => {
    if (totalPages <= 1) return null; // No pagination needed if only one page

    const pageNumbers = [];
    const maxPagesToShow = 5; // Maximum number of page buttons to display
    const halfPageToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfPageToShow);
    let endPage = Math.min(totalPages, currentPage + halfPageToShow);

    if (currentPage <= halfPageToShow) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfPageToShow >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex space-x-1">
        {currentPage > 1 && (
          <button
            onClick={() => paginate(currentPage - 1)}
            className="px-3 py-2 text-sm text-slate-600 dark:bg-slate-700 dark:text-slate-300 bg-slate-100 rounded-lg hover:bg-slate-200 dark:hover:bg-slate-600"
          >
            <i className="bx bx-chevron-left"></i>
          </button>
        )}

        {startPage > 1 && (
          <>
            <button
              onClick={() => paginate(1)}
              className={`px-3 py-2 rounded-lg text-sm ${
                currentPage === 1
                  ? 'bg-yellow-500 text-white'
                  : 'bg-slate-100 dark:bg-slate-700 dark:text-slate-300 text-slate-600 hover:bg-slate-200 dark:hover:bg-slate-600'
              }`}
            >
              1
            </button>
            {startPage > 2 && (
              <span className="px-3 py-2 text-sm text-slate-600 dark:text-slate-300">...</span>
            )}
          </>
        )}

        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-3 py-2 rounded-lg text-sm ${
              currentPage === number
                ? 'bg-yellow-500 text-white'
                : 'bg-slate-100 dark:bg-slate-700 dark:text-slate-300 text-slate-600 hover:bg-slate-200 dark:hover:bg-slate-600'
            }`}
          >
            {number}
          </button>
        ))}

        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && (
              <span className="px-3 py-2 text-sm text-slate-600 dark:text-slate-300">...</span>
            )}
            <button
              onClick={() => paginate(totalPages)}
              className={`px-3 py-2 rounded-lg text-sm ${
                currentPage === totalPages
                  ? 'bg-yellow-500 text-white'
                  : 'bg-slate-100 dark:bg-slate-700 dark:text-slate-300 text-slate-600 hover:bg-slate-200 dark:hover:bg-slate-600'
              }`}
            >
              {totalPages}
            </button>
          </>
        )}

        {currentPage < totalPages && (
          <button
            onClick={() => paginate(currentPage + 1)}
            className="px-3 py-2 text-sm text-slate-600 dark:bg-slate-700 dark:text-slate-300 bg-slate-100 rounded-lg hover:bg-slate-200 dark:hover:bg-slate-600"
          >
            <i className="bx bx-chevron-right"></i>
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="min-h-screen p-10 mx-auto container dark:text-white">
        <div className="w-full py-10 flex items-center">
          <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
            Ranked Monitor
            <div className="text-sm text-slate-500">
              <i className="bx bx-folder bx-fw"></i> Project / {projectDomain}
            </div>
          </div>
        </div>

        {error && (
          <div className="flex items-center justify-center my-10 text-center">
            <div className="w-96 mt-4 mb-10">
              <i className="bx bx-error-circle bx-rotate-180 bx-lg text-red-500"></i>
              <h3 className="text-4xl mb-2">Oh snap!</h3>
              <p>
                Something went wrong, please try again later or tell us what was wrong{' '}
                <a
                  className="text-yellow-500"
                  href="https://tally.so/r/nrdakL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </p>
              <p className="text-sm text-slate-500 mt-5">{error}</p>
            </div>
          </div>
        )}

        {/* Content Section */}
        {!error && (
          <div className="bg-white dark:bg-slate-700 rounded-lg border-slate-200 dark:border-slate-600 border dark:text-white">
            {loading && keywordsData.length === 0 ? (
              // Show a loading indicator while data is being fetched
              <div className="flex flex-col items-center p-10">
                <i className="bx bx-loader-alt bx-spin text-4xl text-yellow-500"></i>
                <p className="mt-2 text-slate-500 dark:text-slate-300">
                  Loading Keywords Data...
                </p>
              </div>
            ) : keywordsData.length === 0 ? (
              <p className="text-slate-500 dark:text-slate-400 p-10">
                No keyword data found for {projectDomain}.
              </p>
            ) : (
              <>
                <div className="overflow-x-auto">
                  <div className="flex justify-between items-center px-10 py-10">
                    <div>
                      <h2 className="text-xl flex items-center">
                        <svg
                          className="mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="none"
                        >
                         <path d="M11 6L21 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M11 12L21 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M11 18L21 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M3 7.39286C3 7.39286 4 8.04466 4.5 9C4.5 9 6 5.25 8 4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3 18.3929C3 18.3929 4 19.0447 4.5 20C4.5 20 6 16.25 8 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Indexed
                      </h2>
                    </div>
                  </div>
                  <table className="min-w-full text-sm text-slate-700 dark:text-slate-300">
                    <thead>
                      <tr className="bg-slate-50 dark:bg-slate-600 text-sm">
                        <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">
                          Keyword
                        </th>
                        <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-center">
                          Rank
                        </th>
                        <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-center">
                          Volume
                        </th>
                        <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-center">
                          Competition
                        </th>
                        <th className="py-5 px-10 font-normal dark:text-slate-400 text-slate-500 text-left">
                          Last Updated
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentKeywords.map((keyword, index) => (
                        <tr
                          key={index}
                          className={`border-b last:border-0 border-slate-100 dark:border-slate-600 hover:bg-yellow-50 dark:hover:bg-slate-600 ${
                            index % 2 === 0
                              ? 'bg-white dark:bg-slate-700'
                              : 'bg-slate-50 dark:bg-slate-600'
                          }`}
                        >
                          <td className="py-5 px-10 text-lg dark:text-slate-400 text-slate-700">
                            {keyword.keyword || 'N/A'}
                            {keyword.url && (
                              <div className="text-sm text-blue-500 hover:underline break-all mt-1">
                                <a href={keyword.url} target="_blank" rel="noopener noreferrer">
                                  {keyword.url}
                                </a>
                              </div>
                            )}
                          </td>
                          <td className="py-5 px-10 text-sm text-center dark:text-slate-400 text-slate-700">
                            {keyword.rank !== undefined ? keyword.rank : 'N/A'}
                          </td>
                          <td className="py-5 px-10 text-sm text-center dark:text-slate-400 text-slate-700">
                            {keyword.avg_search_volume !== undefined
                              ? keyword.avg_search_volume.toLocaleString()
                              : 'N/A'}
                          </td>
                          <td className="py-5 px-10 text-sm text-center dark:text-slate-400 text-slate-700">
                            {keyword.competition_level || 'N/A'}
                          </td>
                          <td className="py-5 px-10 text-sm dark:text-slate-400 text-slate-700">
                            {formatDate(keyword.last_updated_time)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Pagination Controls */}
                {totalPages > 1 && (
                  <div className="px-10 py-5 flex justify-between items-center border-t border-slate-100 dark:border-slate-600">
                    <div className="text-sm text-gray-700 dark:text-gray-300">
                      Showing {indexOfFirstKeyword + 1} to{' '}
                      {Math.min(indexOfLastKeyword, keywordsData.length)} of{' '}
                      {keywordsData.length} keywords
                    </div>
                    <div>{renderPagination()}</div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {/* Modal for showing more details if needed */}
        {showModal && (
          <Modal
            title={modalContent.title}
            keywords={modalContent.keywords}
            onClose={() => setShowModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default RankedKeywords;
