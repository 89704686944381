// components/InputField.js
import React from 'react';

const InputField = ({ label, type, value, onChange, icon, placeholder }) => {
  return (
    <div className="flex flex-col mb-4">
      <label className="mb-1 text-sm dark:text-slate-400 text-gray-500">{label} </label>
      <div className="relative">
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}  // Add placeholder here
          className="w-full px-5 py-3 pl-10 text-gray-700 dark:text-white bg-white dark:bg-slate-800 border border-gray-300 dark:border-slate-600 rounded focus:outline-none"
          required
        />
        {icon && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
            <i className={`bx bx-${icon} text-xl`}></i>  {/* Using Boxicons */}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputField;
