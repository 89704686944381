// src/components/TrafficSection.js

import React from 'react';
import Tooltip from '../Tooltip';
import PropTypes from 'prop-types';

const TrafficSection = ({ trafficData, selectedTab, setSelectedTab }) => {
  const handleHeight = (rate, multiplier = 10) => `${multiplier * rate}%`;
  function formatTraffic(number) {
    if (number >= 1_000_000_000) {
        return (number / 1_000_000_000).toFixed(1) + 'B';
    } else if (number >= 1_000_000) {
        return (number / 1_000_000).toFixed(1) + 'M';
    } else if (number >= 100_000) {
        return (number / 1_000).toFixed(1) + 'K';
    } else {
        return number.toLocaleString();
    }
}
const trafficDisplay = formatTraffic(trafficData.currentTraffic);

  return (
    <div className="bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 dark:text-white col-span-2 rounded-lg border p-10">
      <div className="grid grid-cols-2 gap-10">
        {/* Tabs */}
        <div className="h-full ">
          <div className="flex mb-4 text-xs w-full justify-start hidden">
            <button
              className={`py-2 px-3 rounded ${
                selectedTab === 30 ? 'bg-yellow-500 text-white' : 'text-slate-500'
              }`}
              onClick={() => setSelectedTab(30)}
            >
              30 Ds
            </button>
            <button
              className={`py-2 px-4 rounded ${
                selectedTab === 60 ? 'bg-yellow-500 text-white' : 'text-slate-500'
              }`}
              onClick={() => setSelectedTab(60)}
            >
              60 Ds
            </button>
            <button
              className={`py-2 px-4 rounded ${
                selectedTab === 90 ? 'bg-yellow-500 text-white' : 'text-slate-500'
              }`}
              onClick={() => setSelectedTab(90)}
            >
              90 Ds
            </button>
          </div>

          <div>
            <h3 className="text-2xl mb-2 flex items-center"><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M11.5379 8.32172C11.6966 7.89276 12.3034 7.89276 12.4621 8.32172L13.1735 10.2443C13.2733 10.514 13.486 10.7267 13.7557 10.8265L15.6783 11.5379C16.1072 11.6966 16.1072 12.3034 15.6783 12.4621L13.7557 13.1735C13.486 13.2733 13.2733 13.486 13.1735 13.7557L12.4621 15.6783C12.3034 16.1072 11.6966 16.1072 11.5379 15.6783L10.8265 13.7557C10.7267 13.486 10.514 13.2733 10.2443 13.1735L8.32172 12.4621C7.89276 12.3034 7.89276 11.6966 8.32172 11.5379L10.2443 10.8265C10.514 10.7267 10.7267 10.514 10.8265 10.2443L11.5379 8.32172Z" stroke="currentColor" stroke-width="1.5" />
    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
    <path d="M8 8C8.67327 4.46819 10.2109 2 12 2C13.7891 2 15.3267 4.46819 16 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 16C8.67327 19.5318 10.2109 22 12 22C13.7891 22 15.3267 19.5318 16 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2 12H4.5M22 12H19.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>Estimated Traffic</h3>
            <div className="text-xs uppercase text-slate-500">Bounce Rate {trafficData.bounceRate}%</div>

            {/* Display additional traffic data based on selectedTab */}
            {selectedTab === 30 && (
              <p className="flex items-center text-4xl">
                {trafficDisplay}
                {trafficData.currentTraffic > trafficData.oneTraffic ? (
                  <i className="bx bx-up-arrow-alt text-green-500 ml-2"></i>
                ) : trafficData.currentTraffic < trafficData.oneTraffic ? (
                  <i className="bx bx-down-arrow-alt text-red-500 ml-2"></i>
                ) : null}
              </p>
            )}

            {selectedTab === 60 && (
              <p className="flex items-center text-4xl">
                {(trafficData.oneTraffic + trafficData.currentTraffic).toLocaleString()}
              </p>
            )}

            {selectedTab === 90 && (
              <p className="flex items-center text-4xl">
                {(trafficData.twoTraffic + trafficData.oneTraffic + trafficData.currentTraffic).toLocaleString()}
              </p>
            )}

            <div className="flex items-end w-full space-x-2 h-16 mt-12">
              {/* Social Rate */}
              <div className="grid grid-rows-2 flex items-end max-h-5">
                <div
                  className="w-full bg-blue-500 mb-2 rounded cursor-pointer"
                  id="social"
                  style={{ height: handleHeight(trafficData.socialRate)}}
                ></div>
                <p className="text-slate-500 text-xs">{trafficData.socialRate}%</p>
                <Tooltip forid="social" message="Traffic from social medias" position="top" />
              </div>

              {/* Mail Rate */}
              <div className="grid grid-rows-2 flex items-end max-h-5">
                <div
                  className="w-full bg-yellow-500 mb-2 rounded cursor-pointer"
                  id="email"
                  style={{ height: handleHeight(trafficData.mailRate) }}
                ></div>
                <p className="text-slate-500 text-xs mt-2">{trafficData.mailRate}%</p>
                <Tooltip forid="email" message="Traffic from emails" position="top" />
              </div>

              {/* Paid Rate */}
              <div className="grid grid-rows-2 flex items-end max-h-5">
                <div
                  className="w-full bg-green-500 mb-2 rounded cursor-pointer"
                  id="paid"
                  style={{ height: handleHeight(trafficData.paidRate) }}
                ></div>
                <p className="text-slate-500 text-xs mt-2">{trafficData.paidRate}%</p>
                <Tooltip forid="paid" message="Traffic from your paid media" position="top" />
              </div>

              {/* Referral Rate */}
              <div className="grid grid-rows-2 flex items-end max-h-5">
                <div
                  className="w-full bg-orange-500 mb-2 rounded cursor-pointer"
                  id="referral"
                  style={{ height: handleHeight(trafficData.reffRate) }}
                ></div>
                <p className="text-slate-500 text-xs mt-2">{trafficData.reffRate}%</p>
                <Tooltip forid="referral" message="Traffic from Referral" position="top" />
              </div>

              {/* Direct Rate */}
              <div className="grid grid-rows-2 flex items-end max-h-5">
                <div
                  className="w-full bg-purple-500 mb-2 rounded cursor-pointer"
                  id="direct"
                  style={{ height: handleHeight(trafficData.directRate) }}
                ></div>
                <p className="text-slate-500 text-xs mt-2">{trafficData.directRate}%</p>
                <Tooltip forid="direct" message="Your direct visitors" position="top" />
              </div>
            </div>
          </div>
        </div>

        {/* Traffic Rate Legend */}
        <div className="">
          <h3 className="mb-5 text-2xl">Traffic From</h3>
          <div className="w-full space-y-3 text-sm">
            <div className="flex items-center w-full">
              <i className="bx bx-fw bxs-circle text-blue-500"></i>
              <span className="ml-2">Social Medias: {trafficData.socialRate}%</span>
            </div>
            <div className="flex items-center w-full">
              <i className="bx bx-fw bxs-circle text-yellow-500"></i>
              <span className="ml-2">From eMail: {trafficData.mailRate}%</span>
            </div>
            <div className="flex items-center w-full">
              <i className="bx bx-fw bxs-circle text-green-500"></i>
              <span className="ml-2">Paid Traffic: {trafficData.paidRate}%</span>
            </div>
            <div className="flex items-center w-full">
              <i className="bx bx-fw bxs-circle text-orange-500"></i>
              <span className="ml-2">Referral Traffic: {trafficData.reffRate}%</span>
            </div>
            <div className="flex items-center w-full">
              <i className="bx bx-fw bxs-circle text-purple-500"></i>
              <span className="ml-2">Direct: {trafficData.directRate}%</span>
            </div>
                     </div>
        </div>
      </div>
    </div>
  );
};

TrafficSection.propTypes = {
  trafficData: PropTypes.shape({
    currentTraffic: PropTypes.number,
    oneTraffic: PropTypes.number,
    twoTraffic: PropTypes.number,
    bounceRate: PropTypes.number,
    socialRate: PropTypes.number,
    paidRate: PropTypes.number,
    reffRate: PropTypes.number,
    mailRate: PropTypes.number,
    directRate: PropTypes.number,
    otherRate: PropTypes.number,
  }).isRequired,
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default TrafficSection;
