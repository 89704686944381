import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from './components/TimeAgo';
import Tooltip from './components/Tooltip'; 
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from 'chart.js';
import Slider from './components/Slider';
import LoadingScreen from './components/LoadingScreen';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

//
// 1. HELPER FUNCTIONS TO TRANSFORM BACKLINKS
//
function transformNewBacklinks(backlinks) {
  // Example: unify property names for table display
  return backlinks.map((b) => ({
    "Page Title": b.title || "",
    "Page URL": b.url_from || "",
    "Anchor Text": b.anchor || "",
    "Destination": b.url_to || "",
    "Found Date": b.first_seen || b.new_lost_date || "",
    "Domain Authority": b.domain_inlink_rank || 0,
    "Page Authority": b.inlink_rank || 0,
    nofollow: b.nofollow || false,
  }));
}

function transformHighQualityBacklinks(backlinks) {
  return backlinks.map((b) => ({
    "Page Title": b.title || "",
    "Page URL": b.url_from || "",
    "Anchor Text": b.anchor || "",
    "Destination": b.url_to || "",
    "Found Date": b.first_seen || b.last_visited || "",
    // Note the space: b["domain authority"] in the API
    "Domain Authority": b["domain authority"] || 0,
    "Page Authority": b["page authority"] || 0,
    // The API doesn't provide "nofollow" here—assume false
    nofollow: false,
  }));
}

const BacklinkChecker = () => {
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [backlinks, setBacklinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const backlinksPerPage = 10; 
  const [totalDoFollow, setTotalDoFollow] = useState(0);
  const [domainSummary, setDomainSummary] = useState([]);
  const [backlinkDateData, setBacklinkDateData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [daData, setDaData] = useState(null);
  const [TotalBackLinks, setTotalBackLinks] = useState(0); 
  const [lastCrawl, setLastCrawl] = useState('');
  const [urlStatuses, setUrlStatuses] = useState({});
  const [selectedStatusCode, setSelectedStatusCode] = useState('All');
  const [sortDirection, setSortDirection] = useState('asc'); 

  const apiKey = 'f8ec203c2bmsh75cc2098376bc27p1aa3f6jsnc09e8ae6ec8a';
  const timestamp = new Date().getTime();

  // Date options (for tooltips, etc.)
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  // Example slider items
  const sliderItems = [
    <div key="1">
      <h2 className="text-lg mb-2">Guest Blogging</h2>
      <p>Write valuable articles for relevant blogs in your niche. Include a natural link back to your website.</p>
    </div>,
    <div key="2">Slide 2</div>,
    <div key="3">Slide 3</div>,
    <div key="4">Slide 4</div>,
  ];

  //
  // 2. CLEAN UP STATUS CACHE WHEN BACKLINKS CHANGE
  //
  useEffect(() => {
    localStorage.removeItem('urlStatuses');
    setUrlStatuses({});
  }, [backlinks]);

  //
  // 3. ON MOUNT: FETCH FROM DB FIRST
  //
  useEffect(() => {
    const projectData = JSON.parse(localStorage.getItem('project'));
    if (projectData && projectData.url) {
      const domain = new URL(projectData.url).hostname;
      setUrl(domain);
      setProject(projectData);
      fetchBacklinksFromDB(projectData.id, domain);
    } else {
      setError('No project selected. Please choose a website.');
      setIsLoading(false);
    }
  }, []);

  //
  // 4. FAKE LOADING DELAY (SPINNER)
  //
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  //
  // 5. FETCH URL STATUSES ON PAGINATION/CHANGE
  //
  useEffect(() => {
    const currentBacklinks = getCurrentBacklinks();
    const currentPageUrls = currentBacklinks.map((b) => b.Destination);
    fetchUrlStatuses(currentPageUrls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, backlinks]);

  //
  // GET FAVICON
  //
  const getFaviconUrl = (websiteUrl) => {
    try {
      const urlObj = new URL(websiteUrl);
      return `https://icon.horse/icon/${urlObj.hostname}`;
    } catch (e) {
      return 'https://cdn-icons-png.flaticon.com/512/5339/5339181.png';
    }
  };

  //
  // 6. FETCH FROM DB
  //
  const fetchBacklinksFromDB = async (websiteId, domain) => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://www.pixelliongroup.com/seotic/get_backlinks.php?_=${timestamp}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ website_id: websiteId, token }),
      });
      const data = await response.json();
      if (response.ok && data.backlinks) {
        // Send full response to process
        processBacklinkData(data);
      } else {
        setError(data.message || 'No backlinks found in the database.');
      }
    } catch (error) {
      setError('Failed to fetch backlinks from the database.');
    } finally {
      setLoading(false);
    }
  };

  //
  // 7. FETCH VIA API & SAVE
  //
  const fetchBacklinksAndDA = async (domain) => {
  setLoading(true);
  setError('');
  setSuccess('');

  const apiUrl = `https://domain-authority-page-authority.p.rapidapi.com/id?url=https://${domain}&id=HighQualityBacklinks`;

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'x-rapidapi-key': apiKey,
        'x-rapidapi-host': 'domain-authority-page-authority.p.rapidapi.com',
      },
    });
    const data = await response.json();

    if (response.ok && data.status === 'success') {
      // 1) Extract both arrays
      const highQualityBacklinks =
        data.data?.HighQualityBacklink?.HighQualityBacklinks || [];
      const newBacklinks =
        data.data?.NewBacklinks_data?.NewBacklinks || [];

      // 2) Pass both arrays to our "saveBacklinksToDB"
      await saveBacklinksToDB({ highQualityBacklinks, newBacklinks }, domain);
    } else {
      setError(data.message || 'No backlinks found or invalid response.');
    }
  } catch (err) {
    setError('Failed to fetch backlinks. ' + err.message);
  } finally {
    setLoading(false);
  }
};


  //
  // 8. SAVE BACKLINKS TO DB
  //
  const saveBacklinksToDB = async (backlinkData, domain) => {
  const token = localStorage.getItem('token');
  let currentProject = project;

  // Fallback in case "project" is not set in this component state
  if (!currentProject) {
    const projectData = JSON.parse(localStorage.getItem('project'));
    if (projectData?.id && projectData?.url) {
      currentProject = projectData;
    } else {
      setError('Project data is missing. Please select a project.');
      return;
    }
  }

  // Example: Use current timestamp for "lastCrawl"
  const lastCrawl = new Date().toISOString();

  // 1) Construct payload for your API
  const payload = {
    token: token,
    website_id: currentProject.id,
    website_url: currentProject.url,
    // "backlinks" includes both "newBacklinks" and "highQualityBacklinks"
    backlinks: backlinkData,
    last_crawl: lastCrawl,
  };

  try {
    // 2) POST to your backend script
    const response = await fetch(
      `https://www.pixelliongroup.com/seotic/save_backlinks_v2.php?_=${Date.now()}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      }
    );
    const resData = await response.json();

    // 3) Check if saving was successful
    if (response.ok && resData.success) {
      setSuccess('Backlinks successfully saved to the database.');
      setLastCrawl(lastCrawl);

      // Optionally re-fetch the updated data from DB
      await fetchBacklinksFromDB(currentProject.id, domain);
      setError('');
    } else {
      setError('Failed to save backlinks to the database.');
    }
  } catch (err) {
    setError('Failed to save backlinks to the database: ' + err.message);
  }
};


  //
  // 9. PROCESS & MERGE BACKLINKS
  //
  const processBacklinkData = (apiData) => {
    // Transform newBacklinks + highQualityBacklinks
    const newBl = transformNewBacklinks(apiData.backlinks.newBacklinks || []);
    const highBl = transformHighQualityBacklinks(apiData.backlinks.highQualityBacklinks || []);
    const allBacklinks = [...newBl, ...highBl];

    // Set to state
    setBacklinks(allBacklinks);
    setLastCrawl(apiData.last_crawl || new Date().toISOString());

    // Summaries
    const domainSum = allBacklinks.reduce((acc, b) => {
      const domain = new URL(b["Page URL"]).hostname;
      const existing = acc.find((x) => x.Domain === domain);
      if (existing) {
        existing.Backlinks += 1;
        existing.DA = Math.max(existing.DA, b["Domain Authority"]);
      } else {
        acc.push({
          Domain: domain,
          Backlinks: 1,
          DA: b["Domain Authority"],
        });
      }
      return acc;
    }, []);
    setDomainSummary(domainSum);

    // Totals
    setTotalBackLinks(allBacklinks.length);
    setTotalDoFollow(allBacklinks.filter((b) => !b.nofollow).length);

    // Chart data
    const dateData = allBacklinks.reduce((acc, b) => {
      const dateObj = new Date(b["Found Date"]);
      const yearMonth = getYearMonth(dateObj);
      if (!acc[yearMonth]) acc[yearMonth] = 0;
      acc[yearMonth] += 1;
      return acc;
    }, {});
    setBacklinkDateData(dateData);
  };

  /**
   * Convert a Date to e.g. "25 M01" or "25 M12"
   */
  const getYearMonth = (date) => {
    const twoDigitYear = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${twoDigitYear} M${month}`;
  };

  //
  // 10. FILTERING BY STATUS CODE + SORT
  //
  const uniqueStatusCodes = useMemo(() => {
    const codes = Object.values(urlStatuses);
    const uniqueCodes = Array.from(new Set(codes));
    return ['All', ...uniqueCodes.sort((a, b) => a - b)];
  }, [urlStatuses]);

  const getFilteredBacklinks = () => {
    // Sort by Found Date
    const sortedBacklinks = [...backlinks].sort((a, b) => {
      const dateA = new Date(a["Found Date"]);
      const dateB = new Date(b["Found Date"]);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });

    // Filter by selected status
    if (selectedStatusCode === 'All') return sortedBacklinks;

    return sortedBacklinks.filter((b) => {
      const status = urlStatuses[b.Destination];
      return status && status.toString() === selectedStatusCode;
    });
  };

  // Memoize the final filtered list
  const filteredBacklinks = useMemo(
    () => getFilteredBacklinks(),
    [backlinks, selectedStatusCode, urlStatuses, sortDirection]
  );

  //
  // 11. PAGINATION
  //
  const totalBacklinks = filteredBacklinks.length;
  const indexOfLastBacklink = currentPage * backlinksPerPage;
  const indexOfFirstBacklink = indexOfLastBacklink - backlinksPerPage;

  const currentBacklinks = useMemo(
    () => filteredBacklinks.slice(indexOfFirstBacklink, indexOfLastBacklink),
    [filteredBacklinks, indexOfFirstBacklink, indexOfLastBacklink]
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalBacklinks / backlinksPerPage);
    const startPage = Math.max(1, currentPage - 3);
    const endPage = Math.min(totalPages, currentPage + 3);

    if (startPage > 1) pageNumbers.push(1);
    if (startPage > 2) pageNumbers.push('...');

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages - 1) pageNumbers.push('...');
    if (endPage < totalPages) pageNumbers.push(totalPages);

    return (
      <div className="flex flex-wrap space-x-2 mt-4 pl-10 mb-5">
        {pageNumbers.map((number, index) =>
          typeof number === 'number' ? (
            <button
              key={index}
              onClick={() => paginate(number)}
              className={`px-4 py-2 rounded-lg text-sm focus:outline-none ${
                currentPage === number
                  ? 'bg-yellow-500 text-white'
                  : 'bg-slate-100 dark:bg-slate-600 dark:text-slate-400 text-slate-600'
              }`}
            >
              {number}
            </button>
          ) : (
            <span
              key={index}
              className="px-4 py-2 text-sm text-slate-600 dark:bg-slate-600 dark:text-slate-400 bg-slate-100 rounded-lg"
            >
              ...
            </span>
          )
        )}
      </div>
    );
  };

  const getCurrentBacklinks = () => currentBacklinks;

  //
  // 12. URL STATUS CHECKS (BATCHED)
  //
  const fetchUrlStatuses = async (currentPageUrls) => {
    const token = localStorage.getItem('token');
    const batchSize = 20;
    let allResults = [];
    let cachedStatuses = JSON.parse(localStorage.getItem('urlStatuses')) || {};

    const uniqueUrls = [...new Set(currentPageUrls)];
    const urlsToFetch = uniqueUrls.filter((u) => !cachedStatuses[u]);

    if (urlsToFetch.length === 0) {
      setUrlStatuses(cachedStatuses);
      return;
    }

    const chunkArray = (array, size) => {
      const result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };

    const urlBatches = chunkArray(urlsToFetch, batchSize);

    for (const batch of urlBatches) {
      try {
        const response = await fetch(
          `https://www.pixelliongroup.com/seotic/check_url_status.php?_=${timestamp}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, urls: batch }),
          }
        );
        const result = await response.json();
        if (result.status === 'success') {
          allResults = allResults.concat(result.data);
        }
      } catch (error) {
        // ignore partial errors
      }
    }

    // Merge new statuses
    allResults.forEach((item) => {
      cachedStatuses[item.url] = item.status_code;
    });

    localStorage.setItem('urlStatuses', JSON.stringify(cachedStatuses));
    setUrlStatuses(cachedStatuses);
  };

  //
  // 13. "RECRAWL" COOL-DOWN => 24 HOURS
  //
  const isRecrawlAllowed = () => {
    if (!lastCrawl) return true;
    const lastCrawlDate = new Date(lastCrawl);
    const currentDate = new Date();
    const hoursDiff = Math.abs(currentDate - lastCrawlDate) / 36e5;
    return hoursDiff > 24;
  };

  //
  // 14. CHART CONFIG
  //
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: { grid: { display: false }, ticks: { font: { size: 12 }, color: '#999' } },
      y: { grid: { display: false }, ticks: { beginAtZero: true, font: { size: 12 }, color: '#999' } },
    },
  };

  const chartData = {
    labels: Object.keys(backlinkDateData).sort(),
    datasets: [
      {
        label: 'Backlink Counts by Month',
        data: Object.keys(backlinkDateData).map((month) => backlinkDateData[month]),
        backgroundColor: 'rgba(234, 179, 8, 1)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 0,
        borderRadius: 10,
        barThickness: 5,
      },
    ],
  };

  //
  // 15. LOADING STATE
  //
  if (isLoading) {
    return <LoadingScreen />;
  }

  //
  // 16. MAIN RENDER
  //
  return (
    <div className="flex flex-col items-center min-h-screen bg-slate-100 dark:bg-slate-800 p-10 mx-auto container dark:text-white">
      {/* PAGE HEADER */}
      <div className="w-full py-10 flex items-center relative">
        <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
          Backlinks
          <div className="text-sm text-slate-500 mt-2">
            <i className="bx bx-folder bx-fw"></i> project / {url ? url.replace(/^(www\.)?/,'') : 'N/A'}
          </div>
        </div>

        {url && (
          <div className="absolute right-0 space-x-2">
            {success && <p className="text-green-500 my-2 text-xs text-center">{success}</p>}

            {lastCrawl && (
              <p className="text-slate-500 mb-4 text-right text-sm">
                <span id="last-audit">Last Audited <TimeAgo date={lastCrawl} /></span>
                <Tooltip forid="last-audit" message={new Date(lastCrawl).toLocaleDateString('en-US', options)} position="top" />
              </p>
            )}

            {/* Refresh */}
            <button
              onClick={() => fetchBacklinksFromDB(project.id, url)}
              className="uppercase text-sm py-2 px-4 rounded-lg dark:hover:bg-slate-600 hover:bg-slate-200 bg-white dark:bg-slate-700 dark:border-slate-600 dark:text-white border border-slate-100"
              disabled={loading}
            >
              <i className="bx bx-rotate-left bx-fw"></i> {loading ? 'Refreshing...' : 'Refresh'}
            </button>

            {/* Recrawl */}
            <button
              onClick={() => fetchBacklinksAndDA(url)}
              className={`uppercase text-sm py-2 px-4 rounded-lg ${
                isRecrawlAllowed()
                  ? 'bg-yellow-500 text-white hover:bg-yellow-400 border border-yellow-500'
                  : 'bg-slate-200 dark:bg-slate-700 dark:text-slate-500 text-slate-500 cursor-not-allowed'
              }`}
              disabled={loading || !isRecrawlAllowed()}
            >
              <i className="bx bx-rotate-left bx-fw"></i> {loading ? 'Recrawling...' : 'Recrawl'}
            </button>
          </div>
        )}
      </div>

      {/* ERROR MESSAGE */}
      {error && (
        <div className="flex items-center justify-center my-10 text-center">
          <div className="w-96 mt-4 mb-10 text-center">
            <i className="bx bx-error-circle bx-rotate-180 bx-lg text-red-500"></i>
            <h3 className="text-4xl mb-2">Oh snap!</h3>
            <p>
              Something went wrong, please try again later or tell us what was wrong{' '}
              <a
                className="text-yellow-500"
                href="https://tally.so/r/nrdakL"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </p>
            <p className="text-sm text-slate-500 mt-5">{error}</p>
          </div>
        </div>
      )}

      {/* LOADING SPINNER */}
      {loading && (
        <div className="flex items-center justify-center my-10 text-center">
          <div className="w-96 mt-4 mb-10 text-center">
            <i className="bx bx-loader-alt bx-spin bx-rotate-180 bx-lg text-yellow-500"></i>
            <h3 className="text-4xl mb-2">Preparing your data</h3>
            Fetching your backlink data and calculations...
          </div>
        </div>
      )}

      {/* NO BACKLINKS FOUND */}
      {!loading && backlinks.length === 0 && !error && (
        <div className="flex items-center justify-center my-10 text-center">
          <div className="w-96 mt-4 mb-10 text-center">
            <i className="bx bx-error-circle bx-rotate-180 bx-lg text-red-500"></i>
            <h3 className="text-4xl mb-2">Oh snap!</h3>
            Your domain is new or no backlink yet. Try again later or{' '}
            <Link className="text-yellow-500" to="/guest-post">check guest post opportunity</Link>
          </div>
        </div>
      )}

      {/* BACKLINK DATA SECTION */}
      {backlinks.length > 0 && (
        <div className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5 mb-5">
          {/* EXAMPLE: Hidden slider area */}
          <div className="p-10 col-span-2 bg-white rounded-xl hidden">
            <h2 className="text-xl mb-2">Tips for Link Building</h2>
            <Slider items={sliderItems} />
          </div>

          {/* CHART / STATS */}
          <div className="col-span-2 w-full">
            <div className="w-full h-full bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 rounded-lg p-10">
              <h3 className="text-xl mb-4">
                <i className="bx bx-calendar bx-fw"></i> Backlink Counts by Month
              </h3>
              <div className="grid grid-cols-3 my-12">
                <p className="uppercase text-xs text-slate-500 dark:text-slate-400">
                  Backlinks
                  <p className="text-6xl font-semibold text-slate-900 dark:text-white">
                    {TotalBackLinks}
                  </p>
                </p>
                <p className="uppercase text-xs text-slate-500 dark:text-slate-400">
                  Do Follow
                  <p className="text-6xl font-semibold text-slate-900 dark:text-white">
                    {totalDoFollow}
                  </p>
                </p>
                {daData && (
                  <p className="uppercase text-xs text-slate-500">
                    Domain Authority
                    <p className="text-6xl font-semibold text-slate-900">
                      {daData.domainAuthority}
                    </p>
                  </p>
                )}
              </div>
              <Bar data={chartData} options={chartOptions} />
            </div>
          </div>

          {/* TOP REFERRING DOMAINS */}
          <div className="bg-white rounded-lg border border-slate-200 dark:border-slate-600 dark:bg-slate-700 col-span-2">
            <div className="flex relative">
              <h3 className="text-xl p-10">
                <i className="bx bx-link bx-fw"></i> Top Referring Domains{' '}
                <i className="bx bxs-help-circle bx-xs text-slate-300 hover:text-slate-500"></i>
              </h3>
              <button
                className="px-3 py-2 absolute right-10 top-10 text-xs text-slate-500 border border-slate-200 dark:border-slate-500 rounded-lg dark:hover:text-slate-300 hover:text-slate-900"
                onClick={() => setShowModal(true)}
              >
                View All
              </button>
            </div>
            <div className="w-full overflow-x-auto">
              <table className="min-w-full rounded-xl">
                <thead>
                  <tr className="bg-slate-50 dark:bg-slate-600 text-sm border-b border-slate-100 dark:border-slate-600 text-slate-500 dark:text-slate-300">
                    <th className="py-5 px-10 font-normal text-left">Domain</th>
                    <th className="py-5 px-10 font-normal text-center">Backlinks</th>
                    <th className="py-5 px-10 font-normal text-center">DA</th>
                  </tr>
                </thead>
                <tbody>
                  {domainSummary.slice(0, 7).map((domain, index) => (
                    <tr
                      key={index}
                      className="border-t border-slate-100 dark:border-slate-600 first:border-none dark:hover:bg-slate-600 hover:bg-yellow-50"
                    >
                      <td className="py-5 px-10">{domain.Domain}</td>
                      <td className="py-5 px-10 text-center">{domain.Backlinks}</td>
                      <td className="py-5 px-10 text-center">{domain.DA}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* BACKLINK LIST TABLE */}
      {backlinks.length > 0 && (
        <div className="w-full">
          <div className="bg-white rounded-lg dark:bg-slate-700 border border-slate-200 dark:border-slate-600">
            <div className="flex items-center">
              <h3 className="text-xl w-full px-10 py-10">
                <i className="bx bx-list-ol bx-fw"></i> Back Link Lists{' '}
                <i id="backlink" className="bx bxs-help-circle bx-xs text-slate-300 hover:text-slate-500"></i>
                <Tooltip
                  forid="backlink"
                  message="Backlinks are external links pointing to your website, helping improve domain authority and search rankings by signaling trust and relevance to search engines."
                  position="top"
                />
              </h3>
              {/* STATUS FILTER */}
              <div className="flex justify-end px-10">
                <div className="border border-slate-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg px-4 py-2 focus:outline-none">
                  <select
                    value={selectedStatusCode}
                    onChange={(e) => setSelectedStatusCode(e.target.value)}
                    className="focus:outline-none dark:bg-slate-700"
                  >
                    {uniqueStatusCodes.map((code) => (
                      <option key={code} value={code}>
                        {code === 'All' ? 'All Status Codes' : `Status ${code}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="w-full overflow-x-auto mb-10">
              <table className="min-w-full">
                <thead>
                  <tr className="bg-slate-50 dark:bg-slate-600 text-sm border-b border-slate-100 dark:border-slate-600 text-slate-500 dark:text-slate-300">
                    <th className="py-5 px-10 font-normal text-left">Source</th>
                    <th className="py-5 px-10 font-normal text-left">Target</th>
                    <th className="py-5 px-10 font-normal text-left">DA</th>
                    <th className="py-5 px-10 font-normal text-left">PA</th>
                    <th className="py-5 px-10 font-normal text-left">Status</th>
                    <th
                      className="py-5 px-10 font-normal text-left cursor-pointer"
                      onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
                    >
                      Found Date{' '}
                      <i className={`bx ${sortDirection === 'asc' ? 'bx-chevron-up' : 'bx-chevron-down'}`}></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentBacklinks.length > 0 ? (
                    currentBacklinks.map((backlink, index) => (
                      <tr
                        key={index}
                        className="border-y border-slate-100 first:border-none dark:border-slate-600 dark:hover:bg-slate-600 hover:bg-yellow-50"
                      >
                        {/* Source (Page URL / Title) */}
                        <td className="py-5 px-10">
                          <div className="flex items-center">
                            <img
                              src={getFaviconUrl(backlink["Page URL"])}
                              alt="Favicon"
                              className="w-5 h-5 mr-2 rounded"
                              onError={(e) => {
                                e.target.src = 'https://cdn-icons-png.flaticon.com/512/5339/5339181.png';
                              }}
                            />
                            <p>
                              {backlink["Page Title"].length > 30
                                ? `${backlink["Page Title"].substring(0, 30)}...`
                                : backlink["Page Title"]}
                            </p>
                          </div>
                          <a
                            href={backlink["Page URL"]}
                            className="text-slate-500 hover:text-yellow-500 text-sm"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {backlink["Page URL"].length > 30
                              ? `${backlink["Page URL"].substring(0, 30)}...`
                              : backlink["Page URL"]}
                            <i className="bx bx-link-external ml-1"></i>
                          </a>
                        </td>

                        {/* Target (Anchor / Destination) */}
                        <td className="py-5 px-10">
                          <p>
                            {backlink["Anchor Text"].length > 50
                              ? `${backlink["Anchor Text"].substring(0, 50)}...`
                              : backlink["Anchor Text"]}
                          </p>
                          <a
                            href={backlink.Destination}
                            className="text-slate-500 text-sm hover:text-yellow-500"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {backlink.Destination.length > 30
                              ? `${backlink.Destination.substring(0, 30)}...`
                              : backlink.Destination}
                            <i className="bx bx-link-external ml-1"></i>
                          </a>
                        </td>

                        {/* Domain Authority / Page Authority */}
                        <td className="py-5 px-10">{backlink["Domain Authority"]}</td>
                        <td className="py-5 px-10">{backlink["Page Authority"]}</td>

                        {/* Status Code */}
                        <td className="py-5 px-10">{urlStatuses[backlink.Destination] || 'Loading...'}</td>

                        {/* Found Date */}
                        <td className="py-5 px-10 text-sm">
                          {new Date(backlink["Found Date"]).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: 'short',
                            year: '2-digit',
                          })}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="py-5 px-10 text-center text-slate-500">
                        No backlinks match the selected status.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {currentBacklinks.length > 0 && renderPagination()}
            </div>
          </div>
        </div>
      )}

      {/* MODAL: VIEW ALL DOMAIN SUMMARY */}
      {showModal && (
        <div
          onClick={() => setShowModal(false)}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black dark:bg-opacity-80 bg-opacity-70"
        >
          <div className="bg-white dark:bg-slate-700 rounded-lg dark:border-slate-600 border max-w-xl w-3/4 h-3/4 overflow-y-auto relative">
            <h3 className="text-2xl p-10">
              <i className="bx bx-spreadsheet bx-fw"></i> All Domain Summary
            </h3>
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-slate-700 dark:hover:text-slate-400"
              onClick={() => setShowModal(false)}
            >
              <i className="bx bx-x text-2xl"></i>
            </button>
            <div className="w-full overflow-x-auto">
              <table className="min-w-full bg-white dark:bg-slate-700 rounded-lg">
                <thead>
                  <tr className="bg-slate-50 dark:bg-slate-600 text-sm border-b border-slate-100 dark:border-slate-600 text-slate-500 dark:text-slate-300">
                    <th className="py-5 pl-10 font-normal text-left">Domain</th>
                    <th className="py-5 pl-10 font-normal text-center">DA</th>
                    <th className="py-5 pl-10 font-normal text-center">Backlinks</th>
                  </tr>
                </thead>
                <tbody>
                  {domainSummary.map((domain, index) => (
                    <tr
                      key={index}
                      className="border-y border-slate-100 first:border-none last:border-none dark:border-slate-600 dark:hover:bg-slate-500 hover:bg-yellow-50"
                    >
                      <td className="py-5 pl-10">{domain.Domain}</td>
                      <td className="py-5 pl-10 text-center">{domain.DA}</td>
                      <td className="py-5 pl-10 text-center">{domain.Backlinks}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BacklinkChecker;
