import React, { useState } from 'react';
import parse from 'html-react-parser';
import LoadingScreen from './components/LoadingScreen';

const BlogAuditPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [keywords, setKeywords] = useState('');
  const [auditData, setAuditData] = useState(null);
  const [error, setError] = useState('');

  const fetchBlogAuditData = async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('https://www.pixelliongroup.com/seotic/blog_audit.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url, keywords }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch blog audit data.');
      }

      const data = await response.json();
      setAuditData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (url.trim() === '' || keywords.trim() === '') {
      setError('Please provide both URL and keywords.');
      return;
    }
    fetchBlogAuditData();
  };

  return (
    <div className="min-h-screen bg-slate-100 dark:bg-slate-800 text-slate-800 dark:text-white p-10">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container mx-auto">
          <h1 className="text-3xl font-bold mb-5">Blog Audit</h1>

          <form onSubmit={handleFormSubmit} className="mb-10">
            <div className="mb-4">
              <label htmlFor="url" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                Blog URL
              </label>
              <input
                type="text"
                id="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border rounded-md dark:bg-slate-700 dark:text-white"
                placeholder="Enter blog URL"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="keywords" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                Focus Keywords
              </label>
              <input
                type="text"
                id="keywords"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border rounded-md dark:bg-slate-700 dark:text-white"
                placeholder="Enter focus keywords"
              />
            </div>
            <button
              type="submit"
              className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Analyze Blog
            </button>
          </form>

          {error && <div className="text-red-500 mb-5">{error}</div>}

          {auditData && (
            <div className="grid grid-cols-2 gap-10">
              {/* Blog Body */}
              <div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 rounded-lg p-5">
                <h2 className="text-xl font-bold mb-3 dark:text-white text-slate-800">Rendered Blog Content</h2>
                <div className="prose dark:prose-dark max-w-none">
                  {auditData.blog_body ? parse(auditData.blog_body) : 'No content available.'}
                </div>
              </div>

              {/* Audit Results */}
              <div>
                {/* Audit Scores */}
                <div className="mb-10">
                  <h2 className="text-xl font-bold mb-3 dark:text-white text-slate-800">Audit Scores</h2>
                  <div className="grid grid-cols-2 gap-5">
                    {auditData.scores &&
                      Object.entries(auditData.scores).map(([key, value]) => (
                        <div
                          key={key}
                          className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 rounded-lg text-center p-5"
                        >
                          <p className="uppercase text-sm text-slate-500 dark:text-slate-400">{key.replace('_', ' ')}</p>
                          <p className="text-4xl font-bold text-slate-800 dark:text-white">{value}</p>
                        </div>
                      ))}
                  </div>
                </div>

                {/* Header Counts */}
                <div className="mb-10">
                  <h2 className="text-xl font-bold mb-3 dark:text-white text-slate-800">Header Tags Count</h2>
                  <ul className="list-disc ml-5 text-slate-500 dark:text-slate-400">
                    {auditData.headers &&
                      Object.entries(auditData.headers.counts).map(([header, count]) => (
                        <li key={header} className="mb-2">
                          <span className="font-medium text-slate-800 dark:text-white">{header.toUpperCase()}:</span>{' '}
                          {count}
                        </li>
                      ))}
                  </ul>
                </div>

                {/* Feedback */}
                <div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600 rounded-lg p-10">
                  <h2 className="text-xl font-bold mb-3 dark:text-white text-slate-800">Feedback</h2>
                  {auditData.feedback &&
                    Object.entries(auditData.feedback).map(([key, item], index) => (
                      <div key={index} className="mb-6">
                        <h3 className="text-lg uppercase mb-2 dark:text-white text-slate-800">{key.replace('_', ' ')}</h3>
                        <div className="text-slate-500 dark:text-slate-400">
                          {typeof item === 'object' ? (
                            Object.entries(item).map(([innerKey, value], idx) => (
                              <div key={idx} className="mb-2">
                                <span className="font-medium capitalize">{innerKey.replace('_', ' ')}: </span>
                                {Array.isArray(value) ? value.join(', ') : value}
                              </div>
                            ))
                          ) : (
                            <p>{item}</p>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BlogAuditPage;
