// src/components/FeedbackSection.js

import React from 'react';
import Feedback from '../Feedback';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Slider from '../Slider';

const sliderItems = [
    <div key="1">
      <h2 className="text-xl mb-2">Keywords</h2>
      <p>
        Keyword rankings help your website get found. Better rankings mean more visitors and more business!
      </p>
    </div>,
    <div key="2">
      <h2 className="text-xl mb-2">Guest Blogging</h2>
      <p>
        Write valuable articles for relevant blogs in your niche. Include a natural link back to your website.
      </p>
    </div>,
  ];


const FeedbackSection = () => {
  return (
	  <>
 
    




<div className="bg-white border-slate-200 rounded-lg p-5 bg-white rounded-lg border p-5 dark:bg-slate-700 dark:border-slate-600">
      <Feedback />
    </div>
    
        <div className="bg-white border-slate-200 col-span-2 rounded-lg border p-10 bg-white rounded-lg border dark:text-white dark:bg-slate-700 dark:border-slate-600">
     <div>
     <h3 className="text-2xl flex items-center font-light"><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M17.0235 3.03358L16.0689 2.77924C13.369 2.05986 12.019 1.70018 10.9555 2.31074C9.89196 2.9213 9.53023 4.26367 8.80678 6.94841L7.78366 10.7452C7.0602 13.4299 6.69848 14.7723 7.3125 15.8298C7.92652 16.8874 9.27651 17.247 11.9765 17.9664L12.9311 18.2208C15.631 18.9401 16.981 19.2998 18.0445 18.6893C19.108 18.0787 19.4698 16.7363 20.1932 14.0516L21.2163 10.2548C21.9398 7.57005 22.3015 6.22768 21.6875 5.17016C21.0735 4.11264 19.7235 3.75295 17.0235 3.03358Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M16.8538 7.43306C16.8538 8.24714 16.1901 8.90709 15.3714 8.90709C14.5527 8.90709 13.889 8.24714 13.889 7.43306C13.889 6.61898 14.5527 5.95904 15.3714 5.95904C16.1901 5.95904 16.8538 6.61898 16.8538 7.43306Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M12 20.9463L11.0477 21.2056C8.35403 21.9391 7.00722 22.3059 5.94619 21.6833C4.88517 21.0608 4.52429 19.6921 3.80253 16.9547L2.78182 13.0834C2.06006 10.346 1.69918 8.97731 2.31177 7.89904C2.84167 6.96631 4 7.00027 5.5 7.00015" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>Quick Tools</h3>

<div className="text-xs text-center mt-5 grid grid-cols-4 gap-4">
<Link to="/keywords">
	<div className="p-5 bg-slate-50 dark:bg-slate-600 dark:text-white rounded-xl hover:dark:bg-slate-500 hover:bg-yellow-50 flex flex-col w-full items-center justify-center h-full">
	<svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="none">
    <path d="M4 10C3.51076 9.96288 3.16925 9.87932 2.88886 9.69117C2.67048 9.54463 2.48298 9.35634 2.33706 9.13703C2 8.63045 2 7.92524 2 6.51483C2 5.10442 2 4.39921 2.33706 3.89263C2.48298 3.67332 2.67048 3.48502 2.88886 3.33849C3.39331 3 4.09554 3 5.5 3H18.5C19.9045 3 20.6067 3 21.1111 3.33849C21.3295 3.48502 21.517 3.67332 21.6629 3.89263C22 4.39921 22 5.10442 22 6.51483C22 7.80256 22 8.50243 21.7435 9" stroke="currentColor" stroke-width="1" stroke-linecap="round" />
    <path d="M18.2 18.2L21 21M19.6 13.3C19.6 9.82057 16.7794 7 13.3 7C9.82057 7 7 9.82057 7 13.3C7 16.7794 9.82057 19.6 13.3 19.6C16.7794 19.6 19.6 16.7794 19.6 13.3Z" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
</svg>		   Keywords Research
	</div>
	</Link>
	
	<Link to="/utm">
	<div className="h-full p-5 bg-slate-50 dark:bg-slate-600 dark:text-white rounded-xl hover:dark:bg-slate-500 hover:bg-yellow-50 flex flex-col w-full items-center justify-center ">
		<svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="none">
    <path d="M12 22C12.2443 22 12.4714 21.8869 12.9256 21.6608L16.5766 19.8432C18.1922 19.039 19 18.6368 19 18V10M12 22C11.7557 22 11.5286 21.8869 11.0744 21.6608L7.42337 19.8432C5.80779 19.039 5 18.6368 5 18V10M12 22V14M19 10C19 9.36317 18.1922 8.96103 16.5766 8.15675L12.9256 6.33919C12.4714 6.11306 12.2443 6 12 6C11.7557 6 11.5286 6.11306 11.0744 6.33919L7.42337 8.15675C5.80779 8.96103 5 9.36317 5 10M19 10C19 10.6368 18.1922 11.039 16.5766 11.8432L12.9256 13.6608C12.4714 13.8869 12.2443 14 12 14M5 10C5 10.6368 5.80779 11.039 7.42337 11.8432L11.0744 13.6608C11.5286 13.8869 11.7557 14 12 14" stroke="currentColor" stroke-width="1" stroke-linejoin="round" />
    <path d="M22 21L19 18.5" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 2V6" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2 21L5 18.5" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
</svg>		  	UTM Link Builder
	</div>
		</Link>
	<Link to="/robot">
	
	<div className="h-full p-5 bg-slate-50 dark:bg-slate-600 dark:text-white rounded-xl hover:dark:bg-slate-500 hover:bg-yellow-50 flex flex-col w-full items-center justify-center ">
	<svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"  fill="none">
    <path d="M19 16V14C19 11.1716 19 9.75736 18.1213 8.87868C17.2426 8 15.8284 8 13 8H11C8.17157 8 6.75736 8 5.87868 8.87868C5 9.75736 5 11.1716 5 14V16C5 18.8284 5 20.2426 5.87868 21.1213C6.75736 22 8.17157 22 11 22H13C15.8284 22 17.2426 22 18.1213 21.1213C19 20.2426 19 18.8284 19 16Z" stroke="currentColor" stroke-width="1" stroke-linejoin="round" />
    <path d="M19 18C20.4142 18 21.1213 18 21.5607 17.5607C22 17.1213 22 16.4142 22 15C22 13.5858 22 12.8787 21.5607 12.4393C21.1213 12 20.4142 12 19 12" stroke="currentColor" stroke-width="1" stroke-linejoin="round" />
    <path d="M5 18C3.58579 18 2.87868 18 2.43934 17.5607C2 17.1213 2 16.4142 2 15C2 13.5858 2 12.8787 2.43934 12.4393C2.87868 12 3.58579 12 5 12" stroke="currentColor" stroke-width="1" stroke-linejoin="round" />
    <path d="M13.5 3.5C13.5 4.32843 12.8284 5 12 5C11.1716 5 10.5 4.32843 10.5 3.5C10.5 2.67157 11.1716 2 12 2C12.8284 2 13.5 2.67157 13.5 3.5Z" stroke="currentColor" stroke-width="1" />
    <path d="M12 5V8" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 13V14" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 13V14" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 17.5C10 17.5 10.6667 18 12 18C13.3333 18 14 17.5 14 17.5" stroke="currentColor" stroke-width="1" stroke-linecap="round" />
</svg> Robot.txt Generator
	</div>
		</Link>
	<Link to="/schema">
	
	<div className="h-full p-5 bg-slate-50 dark:bg-slate-600 dark:text-white rounded-xl hover:dark:bg-slate-500 hover:bg-yellow-50 flex flex-col w-full items-center justify-center ">
	<svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"  fill="none">
    <path d="M17 4C17 3.05719 17 2.58579 17.2929 2.29289C17.5858 2 18.0572 2 19 2H20C20.9428 2 21.4142 2 21.7071 2.29289C22 2.58579 22 3.05719 22 4V5C22 5.94281 22 6.41421 21.7071 6.70711C21.4142 7 20.9428 7 20 7H19C18.0572 7 17.5858 7 17.2929 6.70711C17 6.41421 17 5.94281 17 5V4Z" stroke="currentColor" stroke-width="1" />
    <path d="M9.5 11.5C9.5 10.5572 9.5 10.0858 9.79289 9.79289C10.0858 9.5 10.5572 9.5 11.5 9.5H12.5C13.4428 9.5 13.9142 9.5 14.2071 9.79289C14.5 10.0858 14.5 10.5572 14.5 11.5V12.5C14.5 13.4428 14.5 13.9142 14.2071 14.2071C13.9142 14.5 13.4428 14.5 12.5 14.5H11.5C10.5572 14.5 10.0858 14.5 9.79289 14.2071C9.5 13.9142 9.5 13.4428 9.5 12.5V11.5Z" stroke="currentColor" stroke-width="1" />
    <path d="M17 19C17 18.0572 17 17.5858 17.2929 17.2929C17.5858 17 18.0572 17 19 17H20C20.9428 17 21.4142 17 21.7071 17.2929C22 17.5858 22 18.0572 22 19V20C22 20.9428 22 21.4142 21.7071 21.7071C21.4142 22 20.9428 22 20 22H19C18.0572 22 17.5858 22 17.2929 21.7071C17 21.4142 17 20.9428 17 20V19Z" stroke="currentColor" stroke-width="1" />
    <path d="M2 19C2 18.0572 2 17.5858 2.29289 17.2929C2.58579 17 3.05719 17 4 17H5C5.94281 17 6.41421 17 6.70711 17.2929C7 17.5858 7 18.0572 7 19V20C7 20.9428 7 21.4142 6.70711 21.7071C6.41421 22 5.94281 22 5 22H4C3.05719 22 2.58579 22 2.29289 21.7071C2 21.4142 2 20.9428 2 20V19Z" stroke="currentColor" stroke-width="1" />
    <path d="M2 4C2 3.05719 2 2.58579 2.29289 2.29289C2.58579 2 3.05719 2 4 2H5C5.94281 2 6.41421 2 6.70711 2.29289C7 2.58579 7 3.05719 7 4V5C7 5.94281 7 6.41421 6.70711 6.70711C6.41421 7 5.94281 7 5 7H4C3.05719 7 2.58579 7 2.29289 6.70711C2 6.41421 2 5.94281 2 5V4Z" stroke="currentColor" stroke-width="1" />
    <path d="M4.5 13V7.04545M11 4.5H17M19.5 11V17M15.5 15.5L14.5 14.5" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
</svg> Schema Generator
	</div>
	</Link>
	<div className="hidden p-5 bg-slate-50 dark:bg-slate-600 dark:text-white rounded-xl hover:dark:bg-slate-500 hover:bg-yellow-50 flex flex-col w-full items-center justify-center ">
	<svg className="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"  fill="none">
    <path d="M15.5 6.5C15.5 8.433 13.933 10 12 10C10.067 10 8.5 8.433 8.5 6.5C8.5 4.567 10.067 3 12 3C13.933 3 15.5 4.567 15.5 6.5Z" stroke="currentColor" stroke-width="1" />
    <path d="M22 17.5C22 19.433 20.433 21 18.5 21C16.567 21 15 19.433 15 17.5C15 15.567 16.567 14 18.5 14C20.433 14 22 15.567 22 17.5Z" stroke="currentColor" stroke-width="1" />
    <path d="M9 17.5C9 19.433 7.433 21 5.5 21C3.567 21 2 19.433 2 17.5C2 15.567 3.567 14 5.5 14C7.433 14 9 15.567 9 17.5Z" stroke="currentColor" stroke-width="1" />
</svg>	Soon
	</div>

</div>
 </div>
    </div>

  {/* Last Audit Date and Slider */}
                    <div className="bg-white border-slate-200 dark:bg-slate-700 dark:border-slate-600 dark:text-white rounded-lg border p-5 relative pt-10 z-0">
                                         
                      <Slider items={sliderItems} />
                    </div>

    
 

    
    </>
  );
};

export default FeedbackSection;
