import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Tools = () => {
	  return (
		  
		  <div className="bg-slate-100 dark:bg-slate-800 h-screen mx-auto container p-10 dark:text-white">
		  <div className="w-full py-10 flex items-center relative">
        <div className="w-full text-2xl text-left dark:text-white text-slate-800 uppercase">
         Tools
          <div className="text-sm text-slate-500">Mini tools for your seo ranks</div>
        </div>
      </div>

		  	<div className="w-full grid grid-cols-4 gap-5">
		  	
		  	
		  	<Link
          to="/keywords" >
		  		<div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600  dark:text-white rounded-lg p-10 w-full h-full">
		  			<div className="bg-slate-200 dark:bg-slate-600 rounded-lg  p-4 w-fit ">
		  			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M4 10C3.51076 9.96288 3.16925 9.87932 2.88886 9.69117C2.67048 9.54463 2.48298 9.35634 2.33706 9.13703C2 8.63045 2 7.92524 2 6.51483C2 5.10442 2 4.39921 2.33706 3.89263C2.48298 3.67332 2.67048 3.48502 2.88886 3.33849C3.39331 3 4.09554 3 5.5 3H18.5C19.9045 3 20.6067 3 21.1111 3.33849C21.3295 3.48502 21.517 3.67332 21.6629 3.89263C22 4.39921 22 5.10442 22 6.51483C22 7.80256 22 8.50243 21.7435 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M18.2 18.2L21 21M19.6 13.3C19.6 9.82057 16.7794 7 13.3 7C9.82057 7 7 9.82057 7 13.3C7 16.7794 9.82057 19.6 13.3 19.6C16.7794 19.6 19.6 16.7794 19.6 13.3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>		  			</div>
		  			<h3 className="pt-5 text-xl">
		  			Keyword Research
		  			</h3>
		  			<p className="pt-2 pb-5 text-sm text-slate-500 dark:text-slate-400">Keyword reasearch tool for your seo journy.</p>
		  			
		  		</div>
		  		</Link>
		  		
		  		<Link
          to="/writer" >
		  		<div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600  dark:text-white rounded-lg p-10 w-full h-full">
		  			<div className="bg-slate-200 dark:bg-slate-600 rounded-lg  p-4 w-fit ">
		  			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill={"none"}>
    <path d="M6.53792 2.32172C6.69664 1.89276 7.30336 1.89276 7.46208 2.32172L8.1735 4.2443C8.27331 4.51403 8.48597 4.72669 8.7557 4.8265L10.6783 5.53792C11.1072 5.69664 11.1072 6.30336 10.6783 6.46208L8.7557 7.1735C8.48597 7.27331 8.27331 7.48597 8.1735 7.7557L7.46208 9.67828C7.30336 10.1072 6.69665 10.1072 6.53792 9.67828L5.8265 7.7557C5.72669 7.48597 5.51403 7.27331 5.2443 7.1735L3.32172 6.46208C2.89276 6.30336 2.89276 5.69665 3.32172 5.53792L5.2443 4.8265C5.51403 4.72669 5.72669 4.51403 5.8265 4.2443L6.53792 2.32172Z" stroke="currentColor" strokeWidth="1.5" />
    <path d="M14.4039 9.64136L15.8869 11.1244M6 22H7.49759C8.70997 22 9.31617 22 9.86124 21.7742C10.4063 21.5484 10.835 21.1198 11.6923 20.2625L19.8417 12.1131C20.3808 11.574 20.6503 11.3045 20.7944 11.0137C21.0685 10.4605 21.0685 9.81094 20.7944 9.25772C20.6503 8.96695 20.3808 8.69741 19.8417 8.15832C19.3026 7.61924 19.0331 7.3497 18.7423 7.20561C18.1891 6.93146 17.5395 6.93146 16.9863 7.20561C16.6955 7.3497 16.426 7.61924 15.8869 8.15832L7.73749 16.3077C6.8802 17.165 6.45156 17.5937 6.22578 18.1388C6 18.6838 6 19.29 6 20.5024V22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg></div>
		  			<h3 className="pt-5 text-xl">
		  			AI Blog Writer
		  			</h3>
		  			<p className="pt-2 pb-5 text-sm text-slate-500 dark:text-slate-400">Keyword reasearch tool for your seo journy.</p>
		  			
		  		</div>
		  		</Link>

		  		
		  		
		  		
		  			<Link
          to="/ranked" >
		  		<div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600  dark:text-white rounded-lg p-10 w-full h-full">
		  			<div className="bg-slate-200 dark:bg-slate-600 rounded-lg  p-4 w-fit ">
		  			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
  <path d="M11 6L21 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M11 12L21 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M11 18L21 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M3 7.39286C3 7.39286 4 8.04466 4.5 9C4.5 9 6 5.25 8 4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3 18.3929C3 18.3929 4 19.0447 4.5 20C4.5 20 6 16.25 8 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />

    
</svg>		  			</div>
		  			<h3 className="pt-5 text-xl">
		  			Ranked Monitor
		  			</h3>
		  			<p className="pt-2 pb-5 text-sm text-slate-500 dark:text-slate-400">Your ranked keywords and pages.</p>
		  			
		  		</div>
		  		</Link>
		  		
		  		<Link
          to="/guest-post" >
		  		<div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600  dark:text-white rounded-lg p-10 w-full h-full">
		  			<div className="bg-slate-200 dark:bg-slate-600 rounded-lg  p-4 w-fit ">
		  			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">


    <path d="M14.5563 13.2183C13.514 14.2606 11.8241 14.2606 10.7817 13.2183C9.73942 12.1759 9.73942 10.486 10.7817 9.44364L13.1409 7.0845C14.1357 6.08961 15.7206 6.04433 16.7692 6.94866M16.4437 3.78175C17.486 2.73942 19.1759 2.73942 20.2183 3.78175C21.2606 4.82408 21.2606 6.51403 20.2183 7.55636L17.8591 9.9155C16.8643 10.9104 15.2794 10.9557 14.2308 10.0513" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.4999 3C7.21257 3 5.56889 3 4.46256 3.9079C4.25998 4.07414 4.07423 4.25989 3.90798 4.46247C3.00007 5.56879 3.00006 7.21247 3.00002 10.4998L3 12.9999C2.99996 16.7712 2.99995 18.6568 4.17152 19.8284C5.3431 21 7.22873 21 11 21H13.4999C16.7874 21 18.4311 21 19.5375 20.092C19.74 19.9258 19.9257 19.7401 20.092 19.5376C20.9999 18.4312 20.9999 16.7875 20.9999 13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />

</svg>		  			</div>
		  			<h3 className="pt-5 text-xl">
		  			Guest Post Sites
		  			</h3>
		  			<p className="pt-2 pb-5 text-sm text-slate-500 dark:text-slate-400">Check and pitch for your backlinks.</p>
		  			
		  		</div>
		  		</Link>
		  		
		  		
		  		
		  		<Link
          to="/schema" >
		  		<div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600  dark:text-white rounded-lg p-10 h-full w-full">
		  			<div className="bg-slate-200 dark:bg-slate-600 rounded-lg  p-4 w-fit ">
		  			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M17 4C17 3.05719 17 2.58579 17.2929 2.29289C17.5858 2 18.0572 2 19 2H20C20.9428 2 21.4142 2 21.7071 2.29289C22 2.58579 22 3.05719 22 4V5C22 5.94281 22 6.41421 21.7071 6.70711C21.4142 7 20.9428 7 20 7H19C18.0572 7 17.5858 7 17.2929 6.70711C17 6.41421 17 5.94281 17 5V4Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M9.5 11.5C9.5 10.5572 9.5 10.0858 9.79289 9.79289C10.0858 9.5 10.5572 9.5 11.5 9.5H12.5C13.4428 9.5 13.9142 9.5 14.2071 9.79289C14.5 10.0858 14.5 10.5572 14.5 11.5V12.5C14.5 13.4428 14.5 13.9142 14.2071 14.2071C13.9142 14.5 13.4428 14.5 12.5 14.5H11.5C10.5572 14.5 10.0858 14.5 9.79289 14.2071C9.5 13.9142 9.5 13.4428 9.5 12.5V11.5Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M17 19C17 18.0572 17 17.5858 17.2929 17.2929C17.5858 17 18.0572 17 19 17H20C20.9428 17 21.4142 17 21.7071 17.2929C22 17.5858 22 18.0572 22 19V20C22 20.9428 22 21.4142 21.7071 21.7071C21.4142 22 20.9428 22 20 22H19C18.0572 22 17.5858 22 17.2929 21.7071C17 21.4142 17 20.9428 17 20V19Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M2 19C2 18.0572 2 17.5858 2.29289 17.2929C2.58579 17 3.05719 17 4 17H5C5.94281 17 6.41421 17 6.70711 17.2929C7 17.5858 7 18.0572 7 19V20C7 20.9428 7 21.4142 6.70711 21.7071C6.41421 22 5.94281 22 5 22H4C3.05719 22 2.58579 22 2.29289 21.7071C2 21.4142 2 20.9428 2 20V19Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M2 4C2 3.05719 2 2.58579 2.29289 2.29289C2.58579 2 3.05719 2 4 2H5C5.94281 2 6.41421 2 6.70711 2.29289C7 2.58579 7 3.05719 7 4V5C7 5.94281 7 6.41421 6.70711 6.70711C6.41421 7 5.94281 7 5 7H4C3.05719 7 2.58579 7 2.29289 6.70711C2 6.41421 2 5.94281 2 5V4Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M4.5 13V7.04545M11 4.5H17M19.5 11V17M15.5 15.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>	  			</div>
		  			<h3 className="pt-5 text-xl">
		  			Schema Markup Generator
		  			</h3>
		  			<p className="pt-2 pb-5 text-sm text-slate-500 dark:text-slate-400">Keyword reasearch tool for your seo journy.</p>
		  			
		  		</div>
		  		</Link>
		  		
		  		
		  		
		  			<Link
          to="/robot" >
		  		<div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600  dark:text-white rounded-lg p-10 h-full w-full">
		  			<div className="bg-slate-200 dark:bg-slate-600 rounded-lg  p-4 w-fit ">
		  			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M19 16V14C19 11.1716 19 9.75736 18.1213 8.87868C17.2426 8 15.8284 8 13 8H11C8.17157 8 6.75736 8 5.87868 8.87868C5 9.75736 5 11.1716 5 14V16C5 18.8284 5 20.2426 5.87868 21.1213C6.75736 22 8.17157 22 11 22H13C15.8284 22 17.2426 22 18.1213 21.1213C19 20.2426 19 18.8284 19 16Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M19 18C20.4142 18 21.1213 18 21.5607 17.5607C22 17.1213 22 16.4142 22 15C22 13.5858 22 12.8787 21.5607 12.4393C21.1213 12 20.4142 12 19 12" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M5 18C3.58579 18 2.87868 18 2.43934 17.5607C2 17.1213 2 16.4142 2 15C2 13.5858 2 12.8787 2.43934 12.4393C2.87868 12 3.58579 12 5 12" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M13.5 3.5C13.5 4.32843 12.8284 5 12 5C11.1716 5 10.5 4.32843 10.5 3.5C10.5 2.67157 11.1716 2 12 2C12.8284 2 13.5 2.67157 13.5 3.5Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M12 5V8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 13V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15 13V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 17.5C10 17.5 10.6667 18 12 18C13.3333 18 14 17.5 14 17.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>		  			</div>
		  			<h3 className="pt-5 text-xl">
		  			Robots.txt Generator
		  			</h3>
		  			<p className="pt-2 pb-5 text-sm text-slate-500 dark:text-slate-400">Keyword reasearch tool for your seo journy.</p>
		  			
		  		</div>
		  		</Link>
		  		
		  		<Link
          to="/utm" >
		  		<div className="bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600  dark:text-white rounded-lg p-10 h-full w-full">
		  			<div className="bg-slate-200 dark:bg-slate-600 rounded-lg  p-4 w-fit ">
		  			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path d="M12 22C12.2443 22 12.4714 21.8869 12.9256 21.6608L16.5766 19.8432C18.1922 19.039 19 18.6368 19 18V10M12 22C11.7557 22 11.5286 21.8869 11.0744 21.6608L7.42337 19.8432C5.80779 19.039 5 18.6368 5 18V10M12 22V14M19 10C19 9.36317 18.1922 8.96103 16.5766 8.15675L12.9256 6.33919C12.4714 6.11306 12.2443 6 12 6C11.7557 6 11.5286 6.11306 11.0744 6.33919L7.42337 8.15675C5.80779 8.96103 5 9.36317 5 10M19 10C19 10.6368 18.1922 11.039 16.5766 11.8432L12.9256 13.6608C12.4714 13.8869 12.2443 14 12 14M5 10C5 10.6368 5.80779 11.039 7.42337 11.8432L11.0744 13.6608C11.5286 13.8869 11.7557 14 12 14" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M22 21L19 18.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 2V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2 21L5 18.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>			</div>
		  			<h3 className="pt-5 text-xl">
		  			UTM Link Builder
		  			</h3>
		  			<p className="pt-2 pb-5 text-sm text-slate-500 dark:text-slate-400">Keyword reasearch tool for your seo journy.</p>
		  			
		  		</div>
		  		</Link>
		  		
		  		

		  		
		  		
		  	</div>
		  	
		  </div>
		  )
	
	};

export default Tools;
