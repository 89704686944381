import React, { useState } from 'react';

const KeywordSuggestionTool = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasSearched, setHasSearched] = useState(false); // Track if a search has been made

  const fetchSuggestions = async () => {
    setLoading(true);
    setError(null);
    setHasSearched(true); // Set to true once a search is made

    try {
      const response = await fetch(`https://keyword-expander.p.rapidapi.com/suggest?query=${encodeURIComponent(query)}`, {
        method: 'GET',
        headers: {
          'x-rapidapi-host': 'keyword-expander.p.rapidapi.com',
          'x-rapidapi-key': 'f8ec203c2bmsh75cc2098376bc27p1aa3f6jsnc09e8ae6ec8a'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch suggestions');
      }

      const data = await response.json();
      setSuggestions(data.suggestions || []);
    } catch (err) {
      setError('Failed to fetch suggestions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen mx-auto container p-10 bg-slate-100 dark:bg-slate-800 dark:text-white">
      <div className="w-full py-10 flex items-center relative">
        <div className="w-full text-4xl text-left text-slate-800 dark:text-white uppercase">
          Keyword Suggestion Tool
          <div className="text-sm text-slate-500">Get keyword ideas for your campaigns</div>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-slate-200 dark:bg-slate-700 dark:border-slate-600 p-10">
        <div className="mb-4">
          <label className="block text-sm text-slate-500 dark:text-slate-400 mb-2">Enter Keyword</label>
          <div className="flex items-center">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="flex-grow p-3 border border-slate-100 dark:bg-slate-600 dark:border-slate-500 rounded-lg focus:outline-none"
              placeholder="Enter a keyword (e.g., SEO tools for small business)"
            />
            <button
              onClick={fetchSuggestions}
              disabled={!query || loading}
              className={`ml-2 bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {loading ? 'Finding' : 'Get Suggestions'}
            </button>
          </div>
        </div>

        {error && <p className="text-red-500 mt-4">{error}</p>}

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2 text-slate-700 dark:text-slate-300">Keyword Suggestions</h3>
          
          {suggestions.length > 0 ? (
            <div className="grid grid-cols-2 gap-5 text-slate-700 dark:text-slate-300 w-full">
              {suggestions.map((suggestion, index) => (
                <div className="flex items-center space-x-2 border rounded px-3 py-1" key={index}>
                  <span className="font-semibold">{index + 1}.</span>
                  <span>{suggestion}</span>
                </div>
              ))}
            </div>
          ) : (
            hasSearched && // Only show this message if a search has been made
            <p className="text-slate-500 dark:text-slate-400 mt-2">No suggestions available. Try a different keyword.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeywordSuggestionTool;
